import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { setupListeners } from "@reduxjs/toolkit/query";
import authSlice from "./auth/auth-slice";
import candidateSlice from "./candidate/candidate-slice";
import vacancySlice from "./vacancy/vacancy-slice";
import applicationSlice from "./application/application-slice";
import monthlyVideoStatsSlice from "./monthly-video-stats/monthly-video-stats-slice";
import monthlyViewerStatsSlice from "./monthly-viewer-stats/monthly-viewer-stats-slice";
import errorSlice from "./error/error-slice";
import userSettingsSlice from "./user-settings/user-settings-slice";
import videoViewSlice from "./video-view/video-view-slice";
import applicationViewSlice from "./application-view/application-view-slice";
import videoMinutesSlice from "./video-minutes/video-minutes-slice";
import companyUserSlice from "./company-user/company-user-slice";

const reducers = combineReducers({
    auth: authSlice,
    vacancy: vacancySlice,
    application: applicationSlice,
    candidate: candidateSlice,
    monthlyVideoStats: monthlyVideoStatsSlice,
    monthlyViewerStats: monthlyViewerStatsSlice,
    userSettings: userSettingsSlice,
    videoView: videoViewSlice,
    applicationView: applicationViewSlice,
    videoMinutes: videoMinutesSlice,
    companyUser: companyUserSlice,
    error: errorSlice,
});

// Wrap all reducers in a root so we can kill them all in a single action
const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === "CLEAR_STORES") {
        localStorage.removeItem("persist:root");
        return reducers(undefined, action);
    }
    return reducers(state, action);
};

// Clear all stores action
export const CLEAR_STORE_ACTION = {
    type: "CLEAR_STORES",
};

// Ensures loading and error states are cleared on app init - we don't want that state persisted
const resetBlacklist = createTransform(
    null,
    (state: any) => {
        const newState = { ...state };
        newState.isLoading = false;
        newState.error = "";
        return newState;
    },
    {
        whitelist: [
            "auth",
            "vacancy",
            "application",
            "candidate",
            "monthlyVideoStats",
            "monthlyViewerStats",
            "videoView",
            "applicationView",
            "videoMinutes",
            "companyUser",
        ],
    }
);

const persistConfig = {
    key: "root",
    storage,
    transforms: [resetBlacklist],
};

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
) as typeof rootReducer;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }), //.concat(userAPI.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
