import React from "react";
import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    color?: "green" | "grey" | "red" | "primary" | "primary-inverted";
}

/**
 * Basic chip component, for use with:
 * - Status indicator
 * - Tags
 */
export const Chip = React.memo<Props>(
    ({ label, color = "primary", ...props }) => (
        <Container color={color} {...props}>
            {label}
        </Container>
    )
);

const Container = styled.div<{
    color: "green" | "grey" | "red" | "primary" | "primary-inverted";
}>(({ color }) => {
    let textColor, backgroundColor;
    switch (color) {
        case "green":
            textColor = `var(--color-success-700)`;
            backgroundColor = `var(--color-success-50)`;
            break;
        case "red":
            textColor = `var(--color-danger-700)`;
            backgroundColor = `var(--color-danger-50)`;
            break;
        case "primary":
            textColor = `var(--color-primary-700)`;
            backgroundColor = `var(--color-primary-50)`;
            break;
        case "primary-inverted":
            textColor = `var(--color-primary-50)`;
            backgroundColor = `var(--color-primary-700)`;
            break;
        case "grey":
        default:
            textColor = `var(--color-grey-700)`;
            backgroundColor = `var(--color-grey-100)`;
            break;
    }

    return `
    display: inline-block;
    border-radius: 100px;
    padding: calc(var(--base-spacing) / 4) var(--base-spacing);
    color: ${textColor};
    background-color: ${backgroundColor};
    text-transform: capitalize;
    `;
});
