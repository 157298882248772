/**
 * Interface for MonthlyViewerStatsID from the Database
 * The DB saves date, company, and cohort in the _id key to maintain unique keys
 * We want this to be flattened out in the app for friendlier access
 */
export interface IDBMonthlyViewerStatsId {
    month: number;
    year: number;
    companyId: string;

    age: string;
    ethnicity: string;
    gender: string;

    viewerId: string;
    viewerName: string;
    viewerAvatar: string;
}

/** Interface that comes back from the server - we flatted out the ID in to props for local classes */
export interface IDBMonthlyViewerStats {
    _id: IDBMonthlyViewerStatsId;
    playTime: number;
}

export interface IMonthlyViewerStats {
    year: number;
    month: number;
    companyId: string;

    age: string;
    ethnicity: string;
    gender: string;

    viewerId: string;
    viewerName: string;
    viewerAvatar: string;

    playTime: number;

    // String index access
    [key: string]: any;
}

export class MonthlyViewerStats implements IMonthlyViewerStats {
    year: number;
    month: number;
    companyId: string;

    age: string;
    ethnicity: string;
    gender: string;

    viewerId: string;
    viewerName: string;
    viewerAvatar: string;

    playTime: number;

    constructor(
        year: number,
        month: number,
        companyId: string,
        age: string,
        ethnicity: string,
        gender: string,
        viewerId: string,
        viewerName: string,
        viewerAvatar: string,
        playTime: number
    ) {
        this.year = year;
        this.month = month;
        this.companyId = companyId;
        this.age = age;
        this.ethnicity = ethnicity;
        this.gender = gender;
        this.viewerId = viewerId;
        this.viewerName = viewerName;
        this.viewerAvatar = viewerAvatar;
        this.playTime = playTime;
    }
}
