import { ResponsiveBullet, Datum } from "@nivo/bullet";
import React from "react";
import { ViewerViewTimeTotal } from "../../models/chart";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";

type Props = {
    data: ViewerViewTimeTotal[];
};

/**
 * Bullet chart for cohort view time vs available (hiring managers page)
 */
export const ChartViewTimeCohort = React.memo<Props>(({ data }) => {
    // Available colours, selected sequentially based on index of data
    // If more entries are present than colours, it loops around to 0
    const colors = ["var(--color-primary-400)", "var(--color-primary-800)"];

    const order = (data: Datum[]): Datum[] => {
        return data.sort((a, b) => {
            const t1 = a.title?.toString() ?? "a";
            const t2 = b.title?.toString() ?? "z";
            return t1.localeCompare(t2);
        });
    };

    return (
        <ChartResponsiveWrapper>
            {!data || (Array.isArray(data) && !data.length) ? (
                <ChartNoData />
            ) : (
                <ResponsiveBullet
                    data={order(data)}
                    layout="vertical"
                    margin={{ top: 40, right: 40, bottom: 24, left: 0 }}
                    markerSize={0.6}
                    measureBorderColor={{ from: "color", modifiers: [] }}
                    measureColors={colors[0]}
                    markerColors={colors[1]}
                    rangeColors={"transparent"}
                    spacing={46}
                    titleAlign="start"
                    titleOffsetX={-15}
                    titleOffsetY={-20}
                />
            )}
        </ChartResponsiveWrapper>
    );
});
