export const Breakpoints = {
    sm: 576,
    md: 900,
    lg: 1200,
};

export const MediaQueries = {
    smUp: `only screen and (min-width: ${Breakpoints.sm}px)`,
    mdUp: `only screen and (min-width: ${Breakpoints.md}px)`,
    lgUp: `only screen and (min-width: ${Breakpoints.lg}px)`,
};
