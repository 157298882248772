import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine, Serie } from "@nivo/line";
import React from "react";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";

type Props = {
    data: Serie[];
    period: "year" | "month" | "day";
};

/**
 * Line chart number of applicants over a given period of time (company overview)
 */
export const ChartTotalApplicantsOverTime = React.memo<Props>(
    ({ data, period = "year" }) => {
        // Available colours, selected sequentially based on index of data
        // If more entries are present than colours, it loops around to 0
        const colors = ["var(--color-chart-1)"];

        const getColor = (d: Serie) => {
            // Map the ID to an index, and select a colour
            const baseIndex = data.findIndex((x) => x.id === d.id);

            // Index could exceed the amount of colour entries, ensure this doesn't happen
            // Indexes > colours.length will reset to 0
            const index = baseIndex % colors.length;
            return colors[index];
        };

        /**
         * Checks whether the data provided has something to plot
         * @param data Series
         * @returns boolean - whether at least 1 series in the data has data to plot
         */
        const hasData = (data: Serie[]) => {
            if (!data || !data[0]) return false;
            const seriesCountWithData = data[0].data.filter(
                (d) => d.y && Number(d.y) > 0
            ).length;
            return seriesCountWithData > 0;
        };

        return (
            <ChartResponsiveWrapper>
                {!hasData(data) ? (
                    <ChartNoData />
                ) : (
                    <ResponsiveLine
                        areaOpacity={0.1}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            format:
                                period === "year"
                                    ? "%b"
                                    : period === "month"
                                    ? "%d"
                                    : (value) => `${value}:00`,
                            tickValues:
                                period === "year"
                                    ? "every month"
                                    : period === "month"
                                    ? "every 2 days"
                                    : [
                                          0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20,
                                          22,
                                      ],
                        }}
                        colors={(d) => getColor(d)}
                        curve="monotoneX"
                        data={data}
                        defs={[
                            linearGradientDef(
                                "gradient-cohort-total-over-year",
                                [
                                    {
                                        offset: 50,
                                        color: "var(--color-primary-800)",
                                    },
                                    {
                                        offset: 100,
                                        color: "var(--color-primary-800)",
                                        opacity: 0,
                                    },
                                ]
                            ),
                        ]}
                        enableArea={true}
                        enableGridX={false}
                        enablePoints={false}
                        fill={[
                            {
                                match: "*",
                                id: "gradient-cohort-total-over-year",
                            },
                        ]}
                        margin={{ top: 40, left: 40, bottom: 24, right: 24 }}
                        pointBorderColor={{ from: "serieColor" }}
                        pointBorderWidth={2}
                        pointColor={{ theme: "background" }}
                        pointLabelYOffset={-12}
                        pointSize={10}
                        useMesh={true}
                        xFormat={
                            period === "year"
                                ? "time:%b %Y"
                                : period === "month"
                                ? "time:%d %b %Y"
                                : (value) => `${value.toString()}:00`
                        }
                        xScale={{
                            type: period === "day" ? "linear" : "time",
                            format: period === "day" ? "1" : "%Y-%m-%d",
                            useUTC: false,
                            precision: period === "day" ? undefined : "day",
                        }}
                        yScale={{
                            type: "linear",
                            min: 0,
                            max: "auto",
                            stacked: false,
                            reverse: false,
                        }}
                    />
                )}
            </ChartResponsiveWrapper>
        );
    }
);
