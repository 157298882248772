import React, { useRef } from "react";
import { Icon } from "../icon";
import { v4 as uuidv4 } from "uuid";

interface Props extends React.InputHTMLAttributes<HTMLButtonElement> {
    label?: string;
    icon?: any;
    color?:
        | "primary"
        | "primary-alt"
        | "primary-light"
        | "white"
        | "transparent";
    isOutline?: boolean;
    isSquare?: boolean;
    isIconTrailing?: boolean;
}

/**
 * A styled button component with various display variants
 * - *If a simple 'primary' button is required, consider using a vanilla `<input type="button" />` or `<button>` element as they are styled the same*
 * - Accepts an icon
 * - Icon can be set before or after the label text with `isIconTrailing`
 * - `color: 'primary'` = Primary colour, white label
 * - `color: 'primary-alt'` = Alt primary colour, white label (sidebar)
 * - `color: 'primary-light'` = Light primary colour, primary label
 * - `color: 'white'` = White with grey outline
 * - `color: 'transparent'` = No background, grey label
 * - `isOutline: true` = Borders only
 * - `isSquare: true` = Enforces 48px 1/1 aspect ratio (sidebar)
 * - Generates a unique ID if not provided
 */
export const Button = React.memo<Props>(
    ({
        label,
        icon,
        color,
        isOutline,
        isSquare,
        isIconTrailing,
        onClick,
        className,
        style,
        id,
        disabled,
        title,
    }) => {
        const guid = useRef(uuidv4());

        if (!className) className = "";
        // Append additional classes depending on props
        if (isOutline) className += ` is-outlined`;
        if (isSquare) className += ` is-square`;
        if (icon && label) className += ` has-label`;
        className += ` ${color ?? "primary"}`;

        return (
            <button
                id={id ?? guid.current}
                onClick={onClick}
                className={className}
                style={style}
                disabled={disabled}
                title={title}
            >
                {!isIconTrailing && icon && <Icon icon={icon}></Icon>}
                {label && <span>{label}</span>}
                {isIconTrailing && icon && <Icon icon={icon}></Icon>}
            </button>
        );
    }
);
