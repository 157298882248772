import { BarDatum, ResponsiveBar } from "@nivo/bar";
import React from "react";
import { arrayRange, formatTime } from "../../utils/utils";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";
import { ViewerViewTime } from "../../models/chart";

type Props = {
    data: ViewerViewTime[];
    total?: number;
};

/**
 * Bar chart for time spent by viewer, viewing a specific cohort with a "available" horizontal tag (company overview)
 */
export const ChartViewerTotal = React.memo<Props>(({ data, total }) => {
    // Available colours, selected sequentially based on index of data
    // If more entries are present than colours, it loops around to 0
    const colors = [
        "var(--color-primary-400)",
        "var(--color-primary-600)",
        "var(--color-primary-800)",
        "var(--color-primary-600)",
    ];

    const getColor = (d: any) => {
        // Index could exceed the amount of colour entries, ensure this doesn't happen
        // Indexes > colours.length will reset to 0
        const index = d.index % colors.length;
        return colors[index];
    };

    const order = (data: ViewerViewTime[]): BarDatum[] => {
        return data.sort((a, b) => b.watchTime - a.watchTime);
    };

    /**
     * Helper function to check whether the total has been exceeded by a series
     */
    const isTotalExceeded = () => {
        // If no total, return true so the series values are used as a max
        if (!total) return true;
        let maxValue = 0;
        data.forEach((d) => {
            if (d.watchTime > maxValue) maxValue = d.watchTime;
        });
        return maxValue > total;
    };

    return (
        <ChartResponsiveWrapper>
            {!data || (Array.isArray(data) && !data.length) ? (
                <ChartNoData />
            ) : (
                <ResponsiveBar
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 30,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -40,
                        format: (value) => formatTime(value),
                        // If total is provided and is larger than ALL data points, use 10 ticks from 0 total
                        tickValues:
                            total && !isTotalExceeded()
                                ? arrayRange(0, total, total / 10).map((v) =>
                                      Math.round(v)
                                  )
                                : undefined,
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={(d) => getColor(d)}
                    data={order(data)}
                    enableGridX={false}
                    enableLabel={false}
                    indexBy="viewerName"
                    indexScale={{ type: "band", round: true }}
                    keys={["watchTime"]}
                    labelFormat={(d) => formatTime(Number(d))}
                    labelSkipHeight={12}
                    labelSkipWidth={12}
                    labelTextColor="var(--color-white)"
                    margin={{ top: 40, left: 55, bottom: 24 }}
                    markers={
                        total
                            ? [
                                  {
                                      axis: "y",
                                      value: total,
                                      lineStyle: {
                                          stroke: "var(--color-chart-marker)",
                                          strokeWidth: 2,
                                          outline:
                                              "1px solid var(--color-chart-marker-shadow)",
                                      },
                                      legend: "Available",
                                      legendOrientation: isTotalExceeded()
                                          ? "vertical"
                                          : "horizontal",
                                      textStyle: {
                                          fill: "var(--color-chart-marker)",
                                          fontWeight: 800,
                                          textShadow: `
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow),
                                            0 0 1px var(--color-chart-marker-shadow)`,
                                      },
                                  },
                              ]
                            : []
                    }
                    maxValue={total && !isTotalExceeded() ? total : undefined}
                    tooltip={(bar) => {
                        return (
                            <div
                                style={{
                                    // Identical style to default tooltips
                                    background: "white",
                                    color: "inherit",
                                    fontSize: "inherit",
                                    borderRadius: "2px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                                    padding: "5px 9px",
                                }}
                            >
                                {bar.data.viewerName}: {bar.formattedValue}
                            </div>
                        );
                    }}
                    valueFormat={(value) => formatTime(value)}
                    valueScale={{ type: "linear" }}
                />
            )}
        </ChartResponsiveWrapper>
    );
});
