import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { SizeProp, IconProp } from "@fortawesome/fontawesome-svg-core";

import React from "react";

interface Props extends FontAwesomeIconProps {
    icon: IconProp;
    size?: SizeProp;
}

/**
 * FontAwesome icon component
 */
export const Icon = React.memo<Props>(
    ({ icon, size, style, className, ...props }) => (
        <FontAwesomeIcon
            {...props}
            icon={icon}
            className={className}
            style={{ ...style, fontSize: size }}
            size={size ? size : "lg"}
        />
    )
);
