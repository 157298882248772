import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICandidate } from "../../models/candidate";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";

interface ICandidateState extends IRemoteState {
    candidates: ICandidate[];
    candidateLoads: { [key: string]: number };
}

const initialState: ICandidateState = {
    candidates: [],
    candidateLoads: {},
    isLoading: false,
};

/**
 * Candidate store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const candidateSlice = createSlice({
    name: "candidate",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<ICandidate>) => {
            const targetIndex = state.candidates.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1)
                state.candidates[targetIndex] = action.payload;
            const candidateLoads = { ...state.candidateLoads };
            candidateLoads[action.payload.id] = new Date().valueOf();
            state.candidateLoads = candidateLoads;
        },
        add: (state, action: PayloadAction<ICandidate[]>) => {
            state.candidates = state.candidates
                ? [...mergeArrays(state.candidates, action.payload)]
                : action.payload;
            const candidateLoads = { ...state.candidateLoads };
            action.payload.forEach((application) => {
                candidateLoads[application.id] = new Date().valueOf();
            });
            state.candidateLoads = candidateLoads;
        },
        remove: (state, action: PayloadAction<ICandidate>) => {
            state.candidates.splice(
                state.candidates.findIndex((i) => i.id === action.payload.id),
                1
            );
        },
    },
});

export const candidateActions = candidateSlice.actions;
export default candidateSlice.reducer;
