import styled from "styled-components";

/**
 * Bug in Nivo charts responsive - requires min-width: 0 and a wrap container to prevent auto-grow
 * https://github.com/plouc/nivo/issues/411
 */
export const ChartResponsiveWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 280px;
    min-width: 0;
    text-transform: capitalize;
    margin-bottom: 30px;

    svg {
        overflow: visible;
    }
`;
