import styled from "styled-components";

/**
 * Simple container for main page heading with right-aligned actions
 */
export const PageHeaderRow = styled.header<{ isMdUp: boolean }>(
    ({ isMdUp }) => `
  display: grid;
  gap: var(--base-spacing);
  align-items: flex-start;
    ${
        isMdUp
            ? "grid-template-columns: 1fr auto;"
            : "grid-template-columns: 1fr;"
    }
    *:nth-child(2) {
      justify-content: flex-start;
    }
    `
);
