import styled from "styled-components";
import React from "react";
import { Icon } from "./icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props extends React.HTMLAttributes<HTMLElement> {
    image?: string;
    label?: string;
    icon?: IconProp;
    size?: string;
    animateOnHover?: boolean;
    shadow?: boolean;
}

/**
 * A circular user avatar
 * - Defaults to 32px
 */
export const UserAvatar = React.memo<Props>(
    ({
        image,
        label,
        icon,
        size = "32px",
        animateOnHover = false,
        shadow = false,
        title,
        onClick,
        style,
    }) => {
        return (
            <Container
                onClick={onClick}
                image={image}
                size={size}
                style={{ ...style }}
                title={`${title || ""}`}
                animateOnHover={animateOnHover}
                shadow={shadow}
            >
                {icon ? (
                    <Icon icon={icon} size={"lg"}></Icon>
                ) : (
                    label && <p className="text-xs-medium">{label}</p>
                )}
            </Container>
        );
    }
);

const Container = styled.button<{
    image?: string;
    size: string;
    animateOnHover: boolean;
    shadow: boolean;
}>(
    ({ image, size, animateOnHover, shadow }) => `
    display: grid;
    place-items: center;
    width: ${size};
    height: ${size};
    color: var(--color-primary-600);
    ${
        image
            ? `
          background-image: url('${image}');
          background-position: center center;
          background-repeat: no-repeat
        `
            : ""
    };
    background-size: cover;
    background-color: var(--color-primary-50);
    border-radius: 100vw;
    aspect-ratio: 1/1;
    transition: all 200ms ease-out;
    padding: 0;
    ${shadow ? `box-shadow: 0 2px 12px var(--color-shadow);` : ""}
    &:hover, &:focus {
      ${
          animateOnHover
              ? `
            transform: scale(1.5);
            z-index: 999;
          `
              : ""
      }
      ${
          image
              ? `
            background-image: url('${image}');
            background-position: center center;
            background-repeat: no-repeat
          `
              : ""
      };
      background-size: cover;
    }
    border-color: transparent;
    border-width: 0;
  `
);
