import styled from "styled-components";

/**
 * Styled form error
 * - Red text in a padded container
 */
export const FormError = styled.div(
    () => `
    padding: calc(var(--base-spacing) * 1.5) calc(var(--base-spacing) * 2);
    background: var(--color-danger-50);
    border-radius: var(--border-radius);
    color: var(--color-danger-700);
    font-weight: 600;
  `
);
