import React, { useRef } from "react";
import { Icon } from "../icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { v4 as uuidv4 } from "uuid";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    label?: string;
    icon?: IconProp;
    checked?: boolean;
}

/**
 * Styled checkbox component
 * - Can have a label
 * - `icon` will replace the "checked" icon, defaults to a simple check mark
 * - `forceChecked` will  override internal state and control from props instead. Useful for programmatically changing checked state from another component
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 */
export const Checkbox = React.memo<Props>(
    ({ name, label, icon, style, checked, onChange, id, ...rest }) => {
        const guid = useRef(uuidv4());

        return (
            <div>
                <input
                    type="checkbox"
                    style={style}
                    onChange={onChange}
                    id={id ?? guid.current}
                    name={name ?? guid.current}
                    checked={checked}
                    {...rest}
                />
                <label htmlFor={name ?? guid.current}>
                    {label && label}
                    {checked && <Icon icon={icon ? icon : faCheck}></Icon>}
                </label>
            </div>
        );
    }
);
