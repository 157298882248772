import styled from "styled-components";

/**
 * Table toolbar
 * - 2-column grid layout in desktop, set to space-between
 * - Single column layour in mobile
 */
export const TableToolbar = styled.div<{ isMdUp: boolean }>(
    ({ isMdUp }) => `
    ${
        isMdUp
            ? "grid-template-columns: 1fr auto"
            : "grid-template-columns: 1fr"
    };
    align-items: space-between;
    & :first-child {
      justify-content: flex-start;
    }
    `
);
