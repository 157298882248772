import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    label?: string;
    checked: boolean;
}

/**
 * Styled toggle component
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 */
export const Toggle = React.memo<Props>(
    ({ name, style, checked, onChange, id, ...rest }) => {
        const guid = useRef(uuidv4());

        return (
            <div>
                <input
                    type="checkbox"
                    className="toggle"
                    style={style}
                    onChange={onChange}
                    id={id ?? guid.current}
                    name={name ?? guid.current}
                    checked={checked}
                    {...rest}
                />
                <label htmlFor={name ?? guid.current}></label>
            </div>
        );
    }
);
