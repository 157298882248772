import {
    faClose,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../stores/hooks";
import { Button } from "../form/button";
import { Icon } from "../icon";

interface Props extends React.PropsWithChildren {}

/**
 * Provides a single place for all errors to be accumulated and displayed.
 * Useful as it provides a history log in storage, so we can get client side errors - assuming local storage wasn't cleared
 */
export const ErrorSink = React.memo<any>((props: Props) => {
    // How long alerts should be displayed for
    const TIMEOUT = 5000;

    // Stores
    const errorState = useAppSelector((state) => state.error);

    // Local state
    const [dismiss, setDismiss] = useState<number>(new Date().valueOf());

    // Local timer for timed display of errors
    const [lastTick, setLastTick] = useState<number>(new Date().valueOf());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setLastTick(new Date().valueOf());
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    /**
     * When the error state changes, find the ones that need to be displayed
     * These errors will have been logged in the last `TIMEOUT` ms.
     */
    const renderElements = useCallback(() => {
        const displayErrors = errorState.errors.filter(
            (e) =>
                e.timestamp > lastTick - TIMEOUT &&
                e.timestamp > dismiss &&
                e.message
        );
        const elements: JSX.Element[] = [];
        displayErrors.forEach((error, i) => {
            elements.push(
                <div key={`${i}_${error.timestamp}`}>
                    <Icon icon={faExclamationCircle}></Icon>
                    <span>{error.message}</span>
                </div>
            );
        });

        return [
            ...elements,
            // Add in a dismiss button if we've at least one to display
            elements.length > 0 && (
                <Button
                    key={"clear"}
                    color={"primary-light"}
                    icon={faClose}
                    onClick={() => setDismiss(lastTick)}
                    style={{ justifySelf: "flex-end" }}
                    label={"Dismiss"}
                />
            ),
        ];
    }, [errorState.errors, dismiss, lastTick]);

    return (
        <>
            <div className="alerts-container">{renderElements()}</div>
            {props.children}
        </>
    );
});
