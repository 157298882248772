import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { ApplicantCohortCount } from "../../models/chart";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";

type Props = {
    data: ApplicantCohortCount[];
    dataType: string;
};

/**
 * Bar chart for views vs a specific cohort (applicant statistics pages)
 */
export const ChartApplicantCohortTotal = React.memo<Props>(
    ({ data, dataType }) => {
        // Available colours, selected sequentially based on index of data
        // If more entries are present than colours, it loops around to 0
        const colors = [
            "var(--color-primary-400)",
            "var(--color-primary-600)",
            "var(--color-primary-800)",
            "var(--color-primary-600)",
        ];

        const getColor = (d: any) => {
            // Index could exceed the amount of colour entries, ensure this doesn't happen
            // Indexes > colours.length will reset to 0
            const index = d.index % colors.length;
            return colors[index];
        };

        /**
         * Checks whether the data provided has something to plot
         * @param data Series
         * @returns boolean - whether at least 1 series in the data has data to plot
         */
        const hasData = (data: ApplicantCohortCount[]) => {
            // Check for null or an empty array and return false immediately
            if (!data || (Array.isArray(data) && !data.length)) return false;

            const seriesCountWithData = data.filter((x) => x.total > 0).length;
            return seriesCountWithData > 0;
        };

        return (
            <ChartResponsiveWrapper>
                {!hasData(data) ? (
                    <ChartNoData />
                ) : (
                    <ResponsiveBar
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: -40,
                            format: (e) => Math.floor(e) === e && e,
                        }}
                        axisRight={null}
                        axisTop={null}
                        enableGridX={false}
                        colors={(d) => getColor(d)}
                        enableLabel={false}
                        data={data}
                        keys={[dataType]}
                        indexBy="cohort"
                        margin={{ top: 5, left: 55, bottom: 24 }}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        valueFormat={(value) =>
                            dataType === "percentage"
                                ? `${value.toFixed(2)}%`
                                : `${value}`
                        }
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                    />
                )}
            </ChartResponsiveWrapper>
        );
    }
);
