import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVideoViewFullData } from "../../models/video-view";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";

interface IVideoViewState extends IRemoteState {
    videoViews: IVideoViewFullData[];
    companyLoads: { [key: string]: number };
    vacancyLoads: { [key: string]: number };
    applicationLoads: { [key: string]: number };
}

const initialState: IVideoViewState = {
    videoViews: [],
    isLoading: false,
    companyLoads: {},
    vacancyLoads: {},
    applicationLoads: {},
};

/**
 * Video view store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const videoViewSlice = createSlice({
    name: "video-view",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<IVideoViewFullData>) => {
            const targetIndex = state.videoViews.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1)
                state.videoViews[targetIndex] = action.payload;
        },
        add: (state, action: PayloadAction<IVideoViewFullData[]>) => {
            state.videoViews = state.videoViews
                ? [...mergeArrays(state.videoViews, action.payload)]
                : action.payload;
        },
        remove: (state, action: PayloadAction<IVideoViewFullData>) => {
            state.videoViews.splice(
                state.videoViews.findIndex((i) => i.id === action.payload.id),
                1
            );
        },
        setCompanyLoaded: (state, action: PayloadAction<string>) => {
            state.companyLoads[action.payload] = new Date().valueOf();
        },
        setVacanciesLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.vacancyLoads[id] = new Date().valueOf();
            });
            state.vacancyLoads = _state.vacancyLoads;
        },
        setApplicationsLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.applicationLoads[id] = new Date().valueOf();
            });
            state.applicationLoads = _state.applicationLoads;
        },
    },
});

export const videoViewActions = videoViewSlice.actions;
export default videoViewSlice.reducer;
