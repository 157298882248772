import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useRef } from "react";
import { Icon } from "../icon";
import { v4 as uuidv4 } from "uuid";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    icon?: IconProp;
}

/**
 * Form input component
 * - Can be prefixed with an icon
 * - *If an icon is not required, consider using a vanilla `<input />` element* as these are styled the same
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 * - Fires onChange when input changes
 */
export const Input = React.forwardRef<HTMLInputElement, Props>(
    (
        { icon, id, label, name, onChange, required = false, style, ...rest },
        ref
    ) => {
        const guid = useRef(uuidv4());

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(event);
        };

        return (
            <div className="grid column start">
                {label && <label htmlFor={id}>{label}</label>}
                <Container>
                    {icon && (
                        <IconWrapper>
                            <Icon icon={icon} size={"lg"}></Icon>
                        </IconWrapper>
                    )}
                    <input
                        ref={ref}
                        id={id ?? guid.current}
                        name={name ?? guid.current}
                        style={{
                            paddingLeft: icon
                                ? "calc(var(--base-spacing) * 5)"
                                : "0px",
                            ...style,
                        }}
                        onChange={handleChange}
                        required={required}
                        {...rest}
                    />
                </Container>
            </div>
        );
    }
);

const Container = styled.div(() => `position: relative`);
const IconWrapper = styled.div(
    () =>
        `position: absolute;
    top: 0;
    left: 0;
    display: flex;
    place-items: center;
    height: 100%;
    padding-left: calc(var(--base-spacing) * 2)`
);
