import React, { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormError } from "../components/form/formError";
import { resetPassword } from "../stores/auth/auth-actions";
import { authActions } from "../stores/auth/auth-slice";
import { useAppDispatch, useAppSelector } from "../stores/hooks";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Forgot password page
 */
export const ForgotPassword = React.memo<Props>((props) => {
    // Navigate
    const navigate = useNavigate();

    // Store
    const authState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    // Form inputs
    const [email, setEmail] = useState<string>();

    // Local state
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

    /**
     * Reset error state on leave
     */
    useEffect(() => {
        return () => {
            dispatch(authActions.clearErrors());
        };
    }, [dispatch]);

    /**
     * Redirect if already logged in
     */
    useEffect(() => {
        if (authState.user) navigate("/");
    }, [authState.user, navigate]);

    const onResetPassword = (e: FormEvent) => {
        e.preventDefault();
        setHasSubmitted(true);
        if (email) dispatch(resetPassword({ email }));
    };

    return (
        <section className="grid gap-lg">
            <header className="grid column">
                <h1 className="text-lg-bold text-uppercase text-primary">
                    Forgot password
                </h1>
                <h2 className="text-sm-medium">
                    You can reset your password here. Enter your email, and
                    we'll email you instructions.
                </h2>
            </header>
            <form action="#" onSubmit={onResetPassword} className="grid gap-md">
                {authState.error && <FormError>{authState.error}</FormError>}
                <input
                    type="email"
                    name="username"
                    id="username"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="large"
                    disabled={hasSubmitted && !authState.error}
                />
                <input
                    type="submit"
                    value={
                        hasSubmitted && !authState.error
                            ? "Check your inbox"
                            : "Reset password"
                    }
                    className="grid column centered large"
                    disabled={hasSubmitted && !authState.error}
                    data-is-success={hasSubmitted && !authState.error}
                />
                <Link to={`/auth/login`}>Go back</Link>
            </form>
        </section>
    );
});
