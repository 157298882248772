export type IdWithLabel = {
    id: string;
    label: string;
};

export const COHORT_OPTIONS: IdWithLabel[] = [
    { id: "age", label: "Age" },
    { id: "ethnicity", label: "Ethnicity" },
    { id: "gender", label: "Gender" },
];

export const TIME_PERIOD_OPTIONS: IdWithLabel[] = [
    { id: "year", label: "Year" },
    { id: "month", label: "Month" },
    { id: "day", label: "Day" },
];

export const DATA_TYPES: IdWithLabel[] = [
    { id: "total", label: "Total" },
    { id: "percentage", label: "Percentage" },
];

export const ETHNICITY_TYPES: IdWithLabel[] = [
    { id: "asian", label: "Asian" },
    { id: "black", label: "Black" },
    { id: "hispanic", label: "Hispanic" },
    { id: "indian", label: "Indian" },
    { id: "mideast", label: "Middle East" },
    { id: "white", label: "White" },
    { id: "other", label: "Other" },
    { id: "unknown", label: "Unknown" },
];

export const GENDER_TYPES: IdWithLabel[] = [
    { id: "female", label: "Female" },
    { id: "male", label: "Male" },
    { id: "unknown", label: "Unknown" },
];

export const AGE_TYPES: IdWithLabel[] = [
    { id: "18-24", label: "18-24" },
    { id: "25-29", label: "25-29" },
    { id: "30-39", label: "30-39" },
    { id: "40-49", label: "40-49" },
    { id: "50-59", label: "50-59" },
    { id: "60+", label: "60+" },
    { id: "unknown", label: "Unknown" },
];
