import styled from "styled-components";

/**
 * Chart toolbar component
 * - 2-column grid display
 * - Intended to have a title and button
 */
export const ChartToolbar = styled.div(
    () => `
      position: relative;
      grid-template-columns: 1fr auto;
      border-bottom: 1px solid var(--color-grey-200);
      padding-bottom: calc(var(--base-spacing) * 2);
      min-height: 54px;
      h2 {
        white-space: nowrap;
        position: relative;
        small {
          position: absolute;
          left: 0;
          bottom: -12px;
        }
      }
      `
);
