export interface IAppError {
    error: any;
    message: string | null;
    timestamp: number;
}

export class AppError implements IAppError {
    error: any;
    message: string | null;
    timestamp: number;

    constructor(error: any, message?: string) {
        this.error = error;
        this.message = message ?? null;
        this.timestamp = new Date().valueOf();
    }
}
