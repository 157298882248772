import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { BasicUser, IBasicUser, IMembershipResponse } from "../../models/user";
import { errorActions } from "../error/error-slice";

import { requestOptions, ROOT_URL } from "../utils";
import { companyUserActions } from "./company-user-slice";

/**
 * Loads a companyUsers for this company from the database and adds to store (if not already present)
 * @param id (string) - Target company ID (not used for API)
 */
export const loadCompanyUsers =
    (companyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(companyUserActions.loading());
            const response = await fetch(`${ROOT_URL}/company/members`, {
                ...requestOptions(),
            });
            const data: IMembershipResponse[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const users = (data as IMembershipResponse[]).map(
                    (membership) => {
                        return new BasicUser(
                            membership.user.id,
                            membership.user.name,
                            membership.user.avatar
                        );
                    }
                );
                dispatch(companyUserActions.add(users));
                dispatch(companyUserActions.setCompanyLoaded(companyId));
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading company users";
            console.error(error);
            dispatch(companyUserActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(companyUserActions.loaded());
        }
    };

/**
 * Loads a specific companyUser from the database and adds to store (if not already present)
 * @param id (string) - Target companyUser ID
 */
export const loadCompanyUser = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(companyUserActions.loading());
        const response = await fetch(`${ROOT_URL}/user/byId?id=${id}`, {
            ...requestOptions(),
        });
        const data: IBasicUser & IApiError = await response.json();
        if (!data.statusCode) {
            dispatch(companyUserActions.add([data as IBasicUser]));
        } else {
            throw new Error((data as IApiError).message);
        }
    } catch (error) {
        const message =
            typeof error === "string"
                ? error
                : "Something went wrong when loading company user";
        console.error(error);
        dispatch(companyUserActions.error(message));
        dispatch(
            errorActions.add([
                {
                    error,
                    message,
                },
            ])
        );
    } finally {
        dispatch(companyUserActions.loaded());
    }
};
