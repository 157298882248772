import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { videoViewActions } from "./video-view-slice";
import { requestOptions, ROOT_URL } from "../utils";
import { errorActions } from "../error/error-slice";
import { IVideoViewFullData, VideoView } from "../../models/video-view";

/**
 * Loads video views by vacancy ID and updates state
 * @param vacancyId - the vacancy ID to query
 * @returns Sets state - IVideoViewFullData object */
export const loadVideoViewsByVacancyId =
    (vacancyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(videoViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/video-view/byVacancyId?id=${vacancyId}`,
                requestOptions()
            );
            const data: IVideoViewFullData[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const videoViews = (data as IVideoViewFullData[]).map(
                    (view) => {
                        return new VideoView(
                            view.id,
                            view.companyId,
                            view.candidateId,
                            view.vacancyId,
                            view.videoId,
                            view.applicationId,
                            view.userId,
                            view.createdAt,
                            view.updatedAt,
                            view.watchNumber,
                            view.duration,
                            view.activeTime,
                            view.playTime,
                            view.blurTime,
                            view.volumeTime,
                            view.skippedSeconds,
                            view.skips,
                            view.focusChanges,
                            view.age,
                            view.gender,
                            view.ethnicity,
                            view.viewerId,
                            view.viewerName,
                            view.viewerAvatar
                        );
                    }
                );
                dispatch(videoViewActions.add(videoViews));
                dispatch(videoViewActions.setVacanciesLoaded([vacancyId]));
                // Loading vacancy views also gives us all applications for this vacancy. Set these as loaded too
                const applicationViews = videoViews.map(
                    (view) => view.applicationId
                );
                dispatch(
                    videoViewActions.setApplicationsLoaded(applicationViews)
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading video views by vacancy ID";
            dispatch(videoViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(videoViewActions.loaded());
        }
    };

/**
 * Loads video views by application ID and updates state
 * @param applicationId - the vacapplicationancy ID to query
 * @returns Sets state, uses IVideoViewCohort[] - entire VideoView object from the database
 * Does not include any cohort information - proabbly won't be used
 */
export const loadVideoViewsByApplicationId =
    (applicationId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(videoViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/video-view/byApplicationId?id=${applicationId}`,
                requestOptions()
            );
            const data: IVideoViewFullData[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const videoViews = (data as IVideoViewFullData[]).map(
                    (view) => {
                        return new VideoView(
                            view.id,
                            view.companyId,
                            view.candidateId,
                            view.vacancyId,
                            view.videoId,
                            view.applicationId,
                            view.userId,
                            view.createdAt,
                            view.updatedAt,
                            view.watchNumber,
                            view.duration,
                            view.activeTime,
                            view.playTime,
                            view.blurTime,
                            view.volumeTime,
                            view.skippedSeconds,
                            view.skips,
                            view.focusChanges,
                            view.age,
                            view.gender,
                            view.ethnicity,
                            view.viewerId,
                            view.viewerName,
                            view.viewerAvatar
                        );
                    }
                );
                dispatch(videoViewActions.add(videoViews));
                dispatch(
                    videoViewActions.setApplicationsLoaded([applicationId])
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading video views by application ID";
            dispatch(videoViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(videoViewActions.loaded());
        }
    };
