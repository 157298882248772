import styled from "styled-components";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    progress: number;
    height?: number;
    isColored?: boolean;
    showNumber?: boolean;
}

/**
 * A linear, animated progress indicator
 * - Defaults to 8px in height
 * - `isColored` will transition between red/orange/green depending on % completion
 */
export const ProgressIndicator = React.memo<Props>(
    ({ progress, height = 10, isColored = false, showNumber = false }) => (
        <Background height={height} title={`${progress}%`}>
            <ProgressWrapper height={height} progress={progress}>
                <Foreground
                    height={height}
                    progress={progress}
                    isColored={isColored}
                    className="animated load"
                ></Foreground>
            </ProgressWrapper>
            {showNumber && <Label>{progress.toFixed(0)}%</Label>}
        </Background>
    )
);

const Background = styled.div<{ height: number }>(
    ({ height }) => `
  position: relative;
  width: 100%;
  height: ${height}px;
  border-radius: ${height}px;
  background: var(--color-primary-50);
  `
);

// Using a wrapper like this allows us to use a simple 0-100% in the CSS animation of the foreground bar itself, rather than relying on the attribute/width or using a Styled component
const ProgressWrapper = styled.div<{ progress: number; height: number }>(
    ({ progress, height }) => `
    display: block;
    position: absolute;
    width: ${progress}%;
    height: ${height}px;
    `
);
const Foreground = styled.div<{
    progress: number;
    height: number;
    isColored: boolean;
}>(
    ({ height, progress, isColored }) => `
    display: block;
    height: 100%;
    border-radius: ${height}px;
    background: var(--color-primary-600);
    background: ${
        isColored
            ? progress < 33
                ? "var(--color-danger-500)"
                : progress > 66
                ? "var(--color-success-500)"
                : "var(--color-warning-500)"
            : "var(--color-primary-600)"
    };
    `
);

const Label = styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 2px var(--color-primary-600),
        0px 0px 2px var(--color-primary-600),
        0px 0px 2px var(--color-primary-600),
        0px 0px 2px var(--color-primary-600);
    transform: translate(0, -50%);
    color: var(--color-white);
    font-weight: bold;
    font-size: 10px;
    transition: all 200ms ease;
`;
