import { Button } from "../components/form/button";
import { UserCircles } from "../components/user-circles";
import styled from "styled-components";
import { Size, useWindowSize } from "../hooks/window-size";
import {
    faEllipsisVertical,
    faFilter,
    faMultiply,
    faSearch,
    faMinus,
    faCheck,
    faArrowLeft,
    faArrowRight,
    faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Chip } from "../components/chip";
import { Input } from "../components/form/input";
import { Checkbox } from "../components/form/checkbox";
import { BodyWrapper } from "../components/layout/body-wrapper";
import { Link, useParams } from "react-router-dom";
import { TableToolbar } from "../components/table/table-toolbar";
import { ChartToolbar } from "../components/chart/chart-toolbar";
import { ChartStatusByCohort } from "../components/chart/chart-status-by-cohort";
import { UserAvatar } from "../components/user-avatar";
import { ChartViewerTimeCohort } from "../components/chart/chart-viewer-time-cohort";
import { PageHeaderRow } from "../components/layout/page-header-row";
import { IVacancy } from "../models/vacancy";
import { Dropdown } from "../components/dropdown";
import { useAppSelector, useAppDispatch } from "../stores/hooks";
import { Loader } from "../components/loader";
import { loadVacancy } from "../stores/vacancy/vacancy-actions";
import { IApplication } from "../models/application";
import { COHORT_OPTIONS, IdWithLabel } from "../models/utils";
import { logError } from "../stores/error/error-actions";
import { userSettingsActions } from "../stores/user-settings/user-settings-slice";
import { loadVideoViewsByVacancyId } from "../stores/video-view/video-view-actions";
import { IVideoViewFullData } from "../models/video-view";
import { StackedChartData, ViewerViewTime } from "../models/chart";
import { BasicUser } from "../models/user";
import { ChartViewerCountCohort } from "../components/chart/chart-viewer-count-cohort";
import { ChartViewerTime } from "../components/chart/chart-viewer-time";
import { loadMonthlyVideoStats } from "../stores/monthly-video-stats/monthly-video-stats-actions";
interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Vacancy overview page
 * - Shows a list of candidates for the specified vacancy
 */
export const VacancyOverview = React.memo<Props>((_) => {
    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh
    const PER_PAGE = 10;

    const size = useWindowSize();
    const { vacancyId } = useParams();

    // Stores
    const userSettingsState = useAppSelector((state) => state.userSettings);
    const vacancyState = useAppSelector((state) => state.vacancy);
    const videoViewState = useAppSelector((state) => state.videoView);

    const dispatch = useAppDispatch();
    const [vacancy, setVacancy] = useState<IVacancy>();
    const [videoViews, setVideoViews] = useState<IVideoViewFullData[]>([]);

    // Selections
    const initialSelection: string[] = [];
    const [selections, setSelections] = useState(initialSelection);

    // Pagination and data
    const [pageBounds, setPageBounds] = useState({ start: 0, end: PER_PAGE });
    // All view data is dupled from the Store. This can be filtered to alter the table
    const [allData, setAllData] = useState<IApplication[]>([]);
    // This is the actual viewable data on the current page
    const [viewData, setViewData] = useState<IApplication[]>([]);

    // Filters and search
    const [searchWords, setSearchWords] = useState<string[]>([]);
    const [activeFilters, setActiveFilters] = useState<IdWithLabel[]>([]);

    const AVAILABLE_FILTERS: IdWithLabel[] = useMemo(
        () => [
            { id: "status:shortlisted", label: "Shortlisted" },
            { id: "status:withdrawn", label: "Withdrawn" },
            { id: "status:rejected", label: "Rejected" },
            { id: "status:in-review", label: "In review" },
            { id: "with-viewers", label: "With viewers" },
            { id: "applied-today", label: "Applied today" },
            { id: "applied-this-week", label: "Applied this week" },
            { id: "applied-this-month", label: "Applied this month" },
        ],
        []
    );

    // Chart options
    const [applicationStatusTargetCohort, setApplicationStatusTargetCohort] =
        useState<string>("ethnicity");

    const [viewerTimeTargetCohort, setViewerTimeTargetCohort] =
        useState<string>("ethnicity");

    const [viewerCountTargetCohort, setViewerCountTargetCohort] =
        useState<string>("ethnicity");

    // Chart data
    const [viewerTimeVsAvailableChartData, setViewerTimeVsAvailableChartData] =
        useState<ViewerViewTime[]>([]);

    const [
        viewerTimeVsAvailableChartData_Total,
        setViewerTimeVsAvailableChartData_Total,
    ] = useState<number>(0);

    const [viewerTimeChartData, setViewerTimeChartData] = useState<
        StackedChartData[]
    >([]);

    const [viewerCountChartData, setViewerCountChartData] = useState<
        StackedChartData[]
    >([]);

    const [applicationStatusChartData, setApplicationStatusChartData] =
        useState<StackedChartData[]>([]);

    /**
     * Go to a specific start index
     */
    const goToPageIndex = useCallback(
        (startIndex: number) => {
            setPageBounds({
                start: startIndex,
                end: startIndex + userSettingsState.perPage,
            });
        },
        [userSettingsState.perPage]
    );

    /**
     * Filters the view data based on search field input and active filters
     * @param searchWords - list of search words from the search input
     * @param filters - list of active filters
     */
    useEffect(() => {
        if (!vacancy) return;
        let filteredViewData = [...vacancy?.applications];

        try {
            // Start by filtering based on search input.
            searchWords.forEach((word) => {
                // Convert to lower case and trim whitespace
                const _word = word.toLowerCase().trim();

                // Find anything that matches across various props
                // This is much easier to read if we don't allow prettier to mess up the indentation. Remove if you disagree
                /* prettier-ignore */ const matches = vacancy.applications.filter(
                /* prettier-ignore */   (a) =>
                /* prettier-ignore */     a.user.name.toLowerCase().indexOf(_word) > -1 ||
                /* prettier-ignore */     (a.user.candidate &&
                /* prettier-ignore */       (
                /* prettier-ignore */         (a.user.candidate.description && a.user.candidate.description.toLowerCase().indexOf(_word) > -1) ||
                /* prettier-ignore */         (a.user.candidate.educationLevel && a.user.candidate.educationLevel.level.toLowerCase().indexOf(_word) > -1) ||
                /* prettier-ignore */         (a.user.candidate.educationLevel && a.user.candidate.educationLevel.name.toLowerCase().indexOf(_word) > -1) ||
                /* prettier-ignore */         (a.user.candidate.industries && a.user.candidate.industries.map((i) => i.toLowerCase()).indexOf(_word) > -1) ||
                /* prettier-ignore */         (a.user.candidate.skills && a.user.candidate.skills.map((s) => s.toLowerCase()).indexOf(_word) > -1)
                /* prettier-ignore */       )
                /* prettier-ignore */    ),
                /* prettier-ignore */ )

                // If the current resultset is 0 length, push in matches
                // Else we should start to remove the filtered dataset if they don't match the next word iteration
                // This is effectively an "AND" operation. Each word MUST be found to show in the filtered results.
                // I.e. "full time, remote" will show all those results that contain "Full time" AND "remote"
                filteredViewData =
                    filteredViewData.length === 0
                        ? [...matches]
                        : [
                              ...filteredViewData.filter(
                                  (x) =>
                                      matches.map((m) => m.id).indexOf(x.id) >
                                      -1
                              ),
                          ];
            });
        } catch (ex) {
            console.error("Failed to perform search filter operation");
            dispatch(logError(ex));
        }

        // Now perform filters
        try {
            // Perform the actual filtering operation
            activeFilters.forEach((filter) => {
                switch (filter.id) {
                    case "status:shortlisted":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "shortlisted"
                        );
                        break;
                    case "status:rejected":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "rejected"
                        );
                        break;
                    case "status:withdrawn":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "withdrawn"
                        );
                        break;
                    case "status:in-review":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "in-review"
                        );
                        break;
                    case "with-viewers":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.viewers.length > 0
                        );
                        break;
                    case "applied-today":
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).toDateString() ===
                                new Date().toDateString()
                        );
                        break;
                    case "applied-this-week":
                        const now = new Date();
                        const startOfWeek = new Date(
                            new Date(
                                now.setDate(now.getDate() - now.getDay() + 1)
                            ).toDateString()
                        );
                        const endOfWeek = new Date(
                            new Date(
                                new Date(
                                    now.setDate(
                                        now.getDate() - now.getDay() + 8
                                    )
                                ).toDateString()
                            ).valueOf() - 1
                        );
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).valueOf() >=
                                    startOfWeek.valueOf() &&
                                new Date(x.createdAt).valueOf() <=
                                    endOfWeek.valueOf()
                        );
                        break;
                    case "applied-this-month":
                        const thisMonth = new Date().valueOf();
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).getMonth() === thisMonth
                        );
                        break;
                    default:
                        throw new Error("Unknown filter");
                }
            });
        } catch (ex) {
            console.error("Failed to perform filter operation");
            dispatch(logError(ex));
        }

        // Set view data and repaginate
        setAllData(filteredViewData);
        goToPageIndex(0);
        // View data and selections will update automatically due to the useEffect hook
    }, [dispatch, vacancy, searchWords, activeFilters, goToPageIndex]);

    /**
     * Gets new data from all data sources on the page
     * Used by the refresh button
     */
    const getAllData = useCallback(async () => {
        if (!vacancyId) return;
        // Load vacancies and stats at the same time, don't wait for one before the other
        // Any failures are handled internally, just use allSettled
        await Promise.allSettled([
            dispatch(loadVacancy(vacancyId)),
            dispatch(loadVideoViewsByVacancyId(vacancyId)),
            dispatch(loadMonthlyVideoStats()),
        ]);
    }, [dispatch, vacancyId]);

    /**
     * Calculates the Viewer time vs total available (bullet chart) data
     */

    useEffect(() => {
        const res: ViewerViewTime[] = [];

        videoViews.forEach((view) => {
            const index = res.findIndex((x) => x.viewerId === view.viewerId);
            if (index > -1) {
                // Already have this viewer, aggregate view time
                res[index].watchTime += view.playTime;
            } else {
                // New viewer, add them in
                res.push(
                    new ViewerViewTime(
                        view.viewerId,
                        view.viewerName,
                        view.playTime
                    )
                );
            }
        });

        const total = vacancy?.applications.reduce((a, c) => {
            const applicationTotal = c.answers.reduce((_a, _c) => {
                return _a + (_c.videoDuration ?? 0);
            }, 0);
            return a + applicationTotal;
        }, 0);
        setViewerTimeVsAvailableChartData(res);
        setViewerTimeVsAvailableChartData_Total(total ?? 0);
    }, [vacancy?.applications, videoViews]);

    /**
     * Calculates the "Statuses by" (stacked) chart data
     */
    useEffect(() => {
        const res: StackedChartData[] = [];
        switch (applicationStatusTargetCohort?.toLowerCase()) {
            case "ethnicity":
                allData.forEach((application) => {
                    const candidate = application.user.candidate;
                    const status = application.status.toLowerCase();
                    let index;

                    if (typeof candidate.ethnicity !== "undefined") {
                        index = res.findIndex(
                            (x) => x.key === candidate.ethnicity?.toLowerCase()
                        );
                    } else if (
                        typeof candidate.estimatedEthnicity !== "undefined"
                    ) {
                        index = res.findIndex(
                            (x) =>
                                x.key ===
                                candidate.estimatedEthnicity?.val?.toLowerCase()
                        );
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        (res[index]["applied"] as number) +=
                            status === "applied" ? 1 : 0;
                        (res[index]["incomplete"] as number) +=
                            status === "incompleted" ? 1 : 0;
                        (res[index]["in-review"] as number) +=
                            status === "in-review" ? 1 : 0;
                        (res[index]["rejected"] as number) +=
                            status === "rejected" ? 1 : 0;
                        (res[index]["shortlisted"] as number) +=
                            status === "shortlisted" ? 1 : 0;
                        (res[index]["withdrawn"] as number) +=
                            status === "withdrawn" ? 1 : 0;
                    } else {
                        const newEntry = new StackedChartData(
                            candidate.ethnicity ||
                                candidate.estimatedEthnicity?.val ||
                                "unknown"
                        );
                        newEntry["applied"] = status === "applied" ? 1 : 0;
                        newEntry["in-review"] = status === "in-review" ? 1 : 0;
                        newEntry["incomplete"] =
                            status === "incompleted" ? 1 : 0;
                        newEntry["rejected"] = status === "rejected" ? 1 : 0;
                        newEntry["shortlisted"] =
                            status === "shortlisted" ? 1 : 0;
                        newEntry["withdrawn"] = status === "withdrawn" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "age":
                allData.forEach((application) => {
                    const candidate = application.user.candidate;
                    const age =
                        candidate.age || candidate.estimatedAge?.val || 0;
                    const status = application.status.toLowerCase();
                    let ageBracket = "";

                    if (age >= 18 && age <= 24) {
                        ageBracket = "18-24";
                    } else if (age >= 25 && age <= 29) {
                        ageBracket = "25-29";
                    } else if (age >= 30 && age <= 39) {
                        ageBracket = "30-39";
                    } else if (age >= 40 && age <= 49) {
                        ageBracket = "40-49";
                    } else if (age >= 50 && age <= 59) {
                        ageBracket = "50-59";
                    } else if (age >= 60) {
                        ageBracket = "60+";
                    } else {
                        ageBracket = "unknown";
                    }

                    const index = res.findIndex((x) => x.key === ageBracket);

                    if (index > -1) {
                        (res[index]["applied"] as number) +=
                            status === "applied" ? 1 : 0;
                        (res[index]["in-review"] as number) +=
                            status === "in-review" ? 1 : 0;
                        (res[index]["incomplete"] as number) +=
                            status === "incompleted" ? 1 : 0;
                        (res[index]["rejected"] as number) +=
                            status === "rejected" ? 1 : 0;
                        (res[index]["shortlisted"] as number) +=
                            status === "shortlisted" ? 1 : 0;
                        (res[index]["withdrawn"] as number) +=
                            status === "withdrawn" ? 1 : 0;
                    } else {
                        const newEntry = new StackedChartData(
                            ageBracket || "unknown"
                        );
                        newEntry["applied"] = status === "applied" ? 1 : 0;
                        newEntry["in-review"] = status === "in-review" ? 1 : 0;
                        newEntry["incomplete"] =
                            status === "incompleted" ? 1 : 0;
                        newEntry["rejected"] = status === "rejected" ? 1 : 0;
                        newEntry["shortlisted"] =
                            status === "shortlisted" ? 1 : 0;
                        newEntry["withdrawn"] = status === "withdrawn" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "gender":
                allData.forEach((application) => {
                    const candidate = application.user.candidate;
                    const status = application.status.toLowerCase();
                    let index;

                    if (typeof candidate.gender !== "undefined") {
                        index = res.findIndex(
                            (x) => x.key === candidate.gender?.toLowerCase()
                        );
                    } else if (
                        typeof candidate.estimatedGender !== "undefined"
                    ) {
                        index = res.findIndex(
                            (x) =>
                                x.key ===
                                candidate.estimatedGender?.val.toLowerCase()
                        );
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        (res[index]["applied"] as number) +=
                            status === "applied" ? 1 : 0;
                        (res[index]["in-review"] as number) +=
                            status === "in-review" ? 1 : 0;
                        (res[index]["incomplete"] as number) +=
                            status === "incompleted" ? 1 : 0;
                        (res[index]["rejected"] as number) +=
                            status === "rejected" ? 1 : 0;
                        (res[index]["shortlisted"] as number) +=
                            status === "shortlisted" ? 1 : 0;
                        (res[index]["withdrawn"] as number) +=
                            status === "withdrawn" ? 1 : 0;
                    } else {
                        const newEntry = new StackedChartData(
                            candidate.gender ||
                                candidate.estimatedGender?.val ||
                                "unknown"
                        );
                        newEntry["applied"] = status === "applied" ? 1 : 0;
                        newEntry["in-review"] = status === "in-review" ? 1 : 0;
                        newEntry["incomplete"] =
                            status === "incompleted" ? 1 : 0;
                        newEntry["rejected"] = status === "rejected" ? 1 : 0;
                        newEntry["shortlisted"] =
                            status === "shortlisted" ? 1 : 0;
                        newEntry["withdrawn"] = status === "withdrawn" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
        }

        setApplicationStatusChartData(
            res.sort((a, b) => {
                const cohortA = a.key.toLowerCase();
                const cohortB = b.key.toLowerCase();

                if (cohortA < cohortB) return -1;
                if (cohortA > cohortB) return 1;
                return 0;
            })
        );
    }, [allData, applicationStatusTargetCohort]);

    /**
     * Calculates the "Top Team Viewers (Time)" (stacked) chart data
     */
    useEffect(() => {
        const res: StackedChartData[] = [];
        switch (viewerTimeTargetCohort?.toLowerCase()) {
            case "age":
                videoViews.forEach((view) => {
                    const cohort = view.age;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["18-24"] as number) +=
                            cohort === "18-24" ? view.playTime : 0;
                        (res[index]["25-29"] as number) +=
                            cohort === "25-29" ? view.playTime : 0;
                        (res[index]["30-39"] as number) +=
                            cohort === "30-39" ? view.playTime : 0;
                        (res[index]["40-49"] as number) +=
                            cohort === "40-49" ? view.playTime : 0;
                        (res[index]["50-59"] as number) +=
                            cohort === "50-59" ? view.playTime : 0;
                        (res[index]["60+"] as number) +=
                            cohort === "60+" ? view.playTime : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? view.playTime : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["18-24"] =
                            cohort === "18-24" ? view.playTime : 0;
                        newEntry["25-29"] =
                            cohort === "25-29" ? view.playTime : 0;
                        newEntry["30-39"] =
                            cohort === "30-39" ? view.playTime : 0;
                        newEntry["40-49"] =
                            cohort === "40-49" ? view.playTime : 0;
                        newEntry["50-59"] =
                            cohort === "50-59" ? view.playTime : 0;
                        newEntry["60+"] = cohort === "60+" ? view.playTime : 0;
                        newEntry["unknown"] = cohort === "unknown" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "ethnicity":
                videoViews.forEach((view) => {
                    const cohort = view.ethnicity;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["asian"] as number) +=
                            cohort === "asian" ? view.playTime : 0;
                        (res[index]["black"] as number) +=
                            cohort === "black" ? view.playTime : 0;
                        (res[index]["hispanic"] as number) +=
                            cohort === "hispanic" ? view.playTime : 0;
                        (res[index]["indian"] as number) +=
                            cohort === "indian" ? view.playTime : 0;
                        (res[index]["mideast"] as number) +=
                            cohort === "mideast" ? view.playTime : 0;
                        (res[index]["white"] as number) +=
                            cohort === "white" ? view.playTime : 0;
                        (res[index]["other"] as number) +=
                            cohort === "other" ? view.playTime : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? view.playTime : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["asian"] =
                            cohort === "asian" ? view.playTime : 0;
                        newEntry["black"] =
                            cohort === "black" ? view.playTime : 0;
                        newEntry["hispanic"] =
                            cohort === "hispanic" ? view.playTime : 0;
                        newEntry["indian"] =
                            cohort === "indian" ? view.playTime : 0;
                        newEntry["mideast"] =
                            cohort === "mideast" ? view.playTime : 0;
                        newEntry["white"] =
                            cohort === "white" ? view.playTime : 0;
                        newEntry["other"] =
                            cohort === "other" ? view.playTime : 0;
                        newEntry["unknown"] =
                            cohort === "unknown" ? view.playTime : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "gender":
                videoViews.forEach((view) => {
                    const cohort = view.gender;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["female"] as number) +=
                            cohort === "female" ? view.playTime : 0;
                        (res[index]["male"] as number) +=
                            cohort === "male" ? view.playTime : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? view.playTime : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["female"] =
                            cohort === "female" ? view.playTime : 0;
                        newEntry["male"] =
                            cohort === "male" ? view.playTime : 0;
                        newEntry["unknown"] =
                            cohort === "unknown" ? view.playTime : 0;
                        res.push(newEntry);
                    }
                });
                break;
        }

        setViewerTimeChartData(
            res.sort((a, b) => {
                const cohortA = a.key.toLowerCase();
                const cohortB = b.key.toLowerCase();

                if (cohortA < cohortB) return -1;
                if (cohortA > cohortB) return 1;
                return 0;
            })
        );
    }, [allData, viewerTimeTargetCohort, videoViews]);

    /**
     * Calculates the "Top Team Viewers (Count)" (stacked) chart data
     */
    useEffect(() => {
        const res: StackedChartData[] = [];
        switch (viewerCountTargetCohort?.toLowerCase()) {
            case "age":
                videoViews.forEach((view) => {
                    const cohort = view.age;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["18-24"] as number) +=
                            cohort === "18-24" ? 1 : 0;
                        (res[index]["25-29"] as number) +=
                            cohort === "25-29" ? 1 : 0;
                        (res[index]["30-39"] as number) +=
                            cohort === "30-39" ? 1 : 0;
                        (res[index]["40-49"] as number) +=
                            cohort === "40-49" ? 1 : 0;
                        (res[index]["50-59"] as number) +=
                            cohort === "50-59" ? 1 : 0;
                        (res[index]["60+"] as number) +=
                            cohort === "60+" ? 1 : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? 1 : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["18-24"] = cohort === "18-24" ? 1 : 0;
                        newEntry["25-29"] = cohort === "25-29" ? 1 : 0;
                        newEntry["30-39"] = cohort === "30-39" ? 1 : 0;
                        newEntry["40-49"] = cohort === "40-49" ? 1 : 0;
                        newEntry["50-59"] = cohort === "50-59" ? 1 : 0;
                        newEntry["60+"] = cohort === "60+" ? 1 : 0;
                        newEntry["unknown"] = cohort === "unknown" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "ethnicity":
                videoViews.forEach((view) => {
                    const cohort = view.ethnicity;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["asian"] as number) +=
                            cohort === "asian" ? 1 : 0;
                        (res[index]["black"] as number) +=
                            cohort === "black" ? 1 : 0;
                        (res[index]["hispanic"] as number) +=
                            cohort === "hispanic" ? 1 : 0;
                        (res[index]["indian"] as number) +=
                            cohort === "indian" ? 1 : 0;
                        (res[index]["mideast"] as number) +=
                            cohort === "mideast" ? 1 : 0;
                        (res[index]["white"] as number) +=
                            cohort === "white" ? 1 : 0;
                        (res[index]["other"] as number) +=
                            cohort === "other" ? 1 : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? 1 : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["asian"] = cohort === "asian" ? 1 : 0;
                        newEntry["black"] = cohort === "black" ? 1 : 0;
                        newEntry["hispanic"] = cohort === "hispanic" ? 1 : 0;
                        newEntry["indian"] = cohort === "indian" ? 1 : 0;
                        newEntry["mideast"] = cohort === "mideast" ? 1 : 0;
                        newEntry["white"] = cohort === "white" ? 1 : 0;
                        newEntry["other"] = cohort === "other" ? 1 : 0;
                        newEntry["unknown"] = cohort === "unknown" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
            case "gender":
                videoViews.forEach((view) => {
                    const cohort = view.gender;
                    let index;

                    if (typeof view.viewerName !== "undefined") {
                        index = res.findIndex((x) => x.key === view.viewerName);
                    } else {
                        index = res.findIndex((x) => x.key === "unknown");
                    }

                    if (index > -1) {
                        // Already have this viewer, aggregate view count
                        (res[index]["female"] as number) +=
                            cohort === "female" ? 1 : 0;
                        (res[index]["male"] as number) +=
                            cohort === "male" ? 1 : 0;
                        (res[index]["unknown"] as number) +=
                            cohort === "unknown" ? 1 : 0;
                    } else {
                        // New viewer, add them in
                        const newEntry = new StackedChartData(view.viewerName);
                        newEntry["female"] = cohort === "female" ? 1 : 0;
                        newEntry["male"] = cohort === "male" ? 1 : 0;
                        newEntry["unknown"] = cohort === "unknown" ? 1 : 0;
                        res.push(newEntry);
                    }
                });
                break;
        }

        setViewerCountChartData(
            res.sort((a, b) => {
                const cohortA = a.key.toLowerCase();
                const cohortB = b.key.toLowerCase();

                if (cohortA < cohortB) return -1;
                if (cohortA > cohortB) return 1;
                return 0;
            })
        );
    }, [allData, videoViews, viewerCountTargetCohort]);

    /**
     * Calculates the list of viewers for each application and updates the store
     */
    const getViewers = (applicationId: string): BasicUser[] => {
        let viewers: BasicUser[] = [];
        // Get the views for this application from local state
        const views = videoViews.filter(
            (x) => x.applicationId === applicationId
        );
        // Get unique viewers
        views.forEach((view) => {
            if (
                viewers.findIndex((viewer) => view.viewerId === viewer.id) ===
                -1
            ) {
                viewers.push(
                    new BasicUser(
                        view.viewerId,
                        view.viewerName,
                        view.viewerAvatar
                    )
                );
            }
        });
        return viewers;
    };

    /**
     * Page forward
     */
    const onPageForward = useCallback(() => {
        setPageBounds({
            start: pageBounds.start + userSettingsState.perPage,
            end: pageBounds.end + userSettingsState.perPage,
        });
    }, [userSettingsState.perPage, pageBounds.end, pageBounds.start]);

    /**
     * Page back
     */
    const onPageBack = useCallback(() => {
        if (pageBounds.start < userSettingsState.perPage) {
            setPageBounds({ start: 0, end: userSettingsState.perPage });
        } else {
            setPageBounds({
                start: pageBounds.start - userSettingsState.perPage,
                end: pageBounds.start,
            });
        }
    }, [pageBounds.start, userSettingsState.perPage]);

    /**
     * Toggle selection of a single row within the table
     * If all are unselected, fires an unselection event to the select all checkbox
     */
    const onSelect = (event: React.FormEvent<HTMLInputElement>, id: string) => {
        // Perform the selection and set state
        let newSelection = [...selections];
        if ((event.target as HTMLInputElement).checked) {
            newSelection.push(id);
            setSelections(newSelection);
        } else {
            newSelection.splice(selections.indexOf(id), 1);
            setSelections(newSelection);
        }
    };

    /**
     * Toggle selection of all rows within the table
     */
    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Selection is based on current individual selections, rather than the checkbox state itself
        // If clicked and all items are currently selected, deselect all
        // If clicked and some or none of the items are currently selected, select all
        if (!vacancy) return;

        const newSelection =
            selections.length < allData.length
                ? allData.map((application) => application.id)
                : [];
        setSelections(newSelection);
    };

    /**
     * Function to return only the selections that are visible based on viewdata
     * Should be called by methods that perform an action based on the selection
     * @returns number of visible selections
     */
    const visibleSelections = () => {
        // Hide any selections that are no longer visible due to filtering
        return selections.filter(
            (s) => allData.map((d) => d.id).indexOf(s) > -1
        );
    };

    /**
     * Handler to set filters after selection within dropdown
     */
    const onSelectFilter = useCallback(
        (selection: string, remove: boolean = false) => {
            const currentFilters = [...activeFilters];
            const selectedFilter = AVAILABLE_FILTERS.find(
                (x) => x.id === selection
            );

            let filters: IdWithLabel[] = [];
            if (remove && selectedFilter) {
                // Remove
                filters = currentFilters.filter(
                    (x) => x.id !== selectedFilter.id
                );
            } else if (
                selectedFilter &&
                currentFilters.filter((x) => x.id === selectedFilter.id)
                    .length === 0
            ) {
                // Add - only if not already in here. UI should stop this but good idea to check here too
                filters = [...currentFilters, selectedFilter];
            }
            setActiveFilters(filters);
        },
        [activeFilters, AVAILABLE_FILTERS]
    );

    /**
     * Handler for search input - set state and call filter
     */
    const onSearchInput = (event: any) => {
        const words = event.target.value.split(",");
        setSearchWords(words);
    };

    /**
     * Sets the target cohort for the "Statuses by cohort" chart (stacked chart)
     */
    const onChangeApplicationStatusCohort = (selection: string) => {
        if (selection !== applicationStatusTargetCohort)
            setApplicationStatusChartData([]);

        setApplicationStatusTargetCohort(selection);
    };

    /**
     * Sets the target cohort for the "Top Team Viewers (Time)" chart (stacked chart)
     */
    const onChangeViewTimeCohort = (selection: string) => {
        if (selection !== viewerTimeTargetCohort) setViewerTimeChartData([]);

        setViewerTimeTargetCohort(selection);
    };

    /**
     * Sets the target cohort for the "Top Team Viewers (Count)" chart (stacked chart)
     */
    const onChangeViewerCountCohort = (selection: string) => {
        if (selection !== viewerCountTargetCohort) setViewerCountChartData([]);

        setViewerCountTargetCohort(selection);
    };

    /**
     * Hit the API to get the vacancy if we didn't rehydrate from storage
     */
    useEffect(() => {
        if (!vacancyId) return;
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        let targetVacancy = vacancyState.vacancies.find(
            (vacancy) => vacancy.id === vacancyId
        );
        const vacancyLoad = vacancyState.vacancyLoads[vacancyId];
        if (!targetVacancy || !vacancyLoad || vacancyLoad <= expiryThreshold) {
            console.log(
                "%cVacancy not found or expired - attempting get",
                "background-color: yellow;"
            );
            dispatch(loadVacancy(vacancyId)).catch(console.error);
            targetVacancy = vacancyState.vacancies.find(
                (vacancy) => vacancy.id === vacancyId
            );
        }
        setVacancy(targetVacancy);
    }, [
        dispatch,
        userSettingsState.dataExpiry,
        vacancyId,
        vacancyState.vacancies,
        vacancyState.vacancyLoads,
    ]);

    /**
     * Hit the API to get the view stats if we didn't rehydrate from storage
     */
    useEffect(() => {
        if (!vacancyId) return;
        // TODO - Detect if missing fields (another Type) and re-query views
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        const vacancyLoad = videoViewState.vacancyLoads[vacancyId];
        if (!vacancyLoad || vacancyLoad <= expiryThreshold) {
            console.log(
                "%cViews not found or expired - attempting get",
                "background-color: yellow;"
            );
            dispatch(loadVideoViewsByVacancyId(vacancyId)).catch(console.error);
        }
    }, [
        dispatch,
        userSettingsState.dataExpiry,
        vacancyId,
        videoViewState.vacancyLoads,
    ]);

    /**
     * Set views when views are re-loaded
     */
    useEffect(() => {
        if (!vacancyId) return;
        setVideoViews(
            videoViewState.videoViews.filter(
                (x) => x.vacancyId === vacancyId
            ) as IVideoViewFullData[]
        );
    }, [vacancyId, videoViewState.vacancyLoads, videoViewState.videoViews]);

    /**
     * Load all data - fire when vacancies are changed and set the data for use on this page (can be filtered etc without manipulating the store)
     */
    useEffect(() => {
        if (vacancy) setAllData([...vacancy.applications]);
    }, [vacancy]);

    /**
     * Paginated view data - fire when pagination or allData is changed and set the view data
     */
    useEffect(() => {
        setViewData([...allData.slice(pageBounds.start, pageBounds.end)]);
    }, [pageBounds, allData]);

    /**
     * Helper for 3-dot dropdown element
     */
    const dropdownButton = (
        <Button icon={faEllipsisVertical} color="transparent" />
    );

    /**
     * Helper for list of available filter options - used in the JSX return
     */
    const availableFilters = AVAILABLE_FILTERS.filter(
        (x) => activeFilters.map((y) => y.id).indexOf(x.id) === -1
    );

    return !vacancy && !vacancyState.isLoading ? (
        <BodyWrapper
            style={{ alignContent: "center", justifyItems: "center" }}
            size={size}
        >
            <h1 className="text-xl-medium">
                <Link to={`/`}>Vacancy not found</Link>
            </h1>
        </BodyWrapper>
    ) : (
        <BodyWrapper size={size}>
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">
                    {vacancy?.companyName} - {vacancy?.title} - Applicants:{" "}
                    {vacancy?.applications.length}
                </h1>
                <div className="grid row centered">
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={async () => await getAllData()}
                        disabled={
                            vacancyState.isLoading || videoViewState.isLoading
                        }
                    />
                </div>
            </PageHeaderRow>
            <ChartSection size={size} className="grid">
                <div className="grid column loader-wrapper">
                    {videoViewState.isLoading && <Loader fillParent={true} />}
                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            View Times vs Available
                        </h2>
                    </ChartToolbar>
                    <ChartViewerTime
                        data={viewerTimeVsAvailableChartData}
                        total={viewerTimeVsAvailableChartData_Total}
                        rotateX={true}
                    />
                </div>
                <div className="grid column loader-wrapper">
                    {videoViewState.isLoading && <Loader fillParent={true} />}
                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            Statuses by:{" "}
                            <span className="text-capitalize">
                                {applicationStatusTargetCohort}
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeApplicationStatusCohort}
                            options={COHORT_OPTIONS}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartStatusByCohort data={applicationStatusChartData} />
                </div>
            </ChartSection>
            <ChartSection className="grid" size={size}>
                <div className="grid column loader-wrapper">
                    {videoViewState.isLoading && <Loader fillParent={true} />}
                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            Top Team Viewers (Time) by:{" "}
                            <span className="text-capitalize">
                                {viewerTimeTargetCohort}
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeViewTimeCohort}
                            options={COHORT_OPTIONS}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartViewerTimeCohort data={viewerTimeChartData} />
                </div>
                <div className="grid column loader-wrapper">
                    {videoViewState.isLoading && <Loader fillParent={true} />}
                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            Top Team Viewers (Count) by:{" "}
                            <span className="text-capitalize">
                                {viewerCountTargetCohort}
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeViewerCountCohort}
                            options={COHORT_OPTIONS}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartViewerCountCohort data={viewerCountChartData} />
                </div>
            </ChartSection>
            <div className="grid column loader-wrapper">
                {vacancyState.isLoading && <Loader fillParent={true} />}
                <TableToolbar className="grid" isMdUp={size.isMdUp}>
                    <div className="grid row wrap centered">
                        <Dropdown
                            clickableElement={
                                <Button
                                    label="Add Filter"
                                    icon={faFilter}
                                    color="white"
                                    disabled={availableFilters.length === 0}
                                />
                            }
                            onOptionSelected={onSelectFilter}
                            options={availableFilters}
                            title="Filters"
                            positionX="right"
                            isDisabled={availableFilters.length === 0}
                        />

                        {activeFilters.map((filter) => (
                            <Button
                                label={filter.label}
                                icon={faMultiply}
                                color="primary-light"
                                isIconTrailing={true}
                                key={`option_${filter.id}`}
                                onClick={(e) => onSelectFilter(filter.id, true)}
                            />
                        ))}
                    </div>
                    <Input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search"
                        icon={faSearch}
                        style={{ minWidth: "100px" }}
                        onChange={onSearchInput}
                    />
                </TableToolbar>
                <div className="table-wrapper">
                    <table className="has-checkboxes">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox
                                        icon={
                                            visibleSelections().length !==
                                            allData.length
                                                ? faMinus
                                                : faCheck
                                        }
                                        onChange={onSelectAll}
                                        checked={visibleSelections().length > 0}
                                    />
                                </th>
                                <th>Candidate</th>
                                <th>Status</th>
                                {/* <th className="hidden-md-down">Education Level</th> */}
                                <th className="hidden-sm-down">Team Viewers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!viewData.length ? (
                                <tr>
                                    <td
                                        colSpan={4}
                                        className="text-center text-grey-500 text-sm-medium"
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                            }}
                                        >
                                            No data
                                        </div>
                                        &nbsp;
                                    </td>
                                </tr>
                            ) : (
                                viewData.map((application, i) => (
                                    <tr
                                        key={`candidate_${application.id}_${i}`}
                                    >
                                        <td>
                                            <Checkbox
                                                onChange={(e) =>
                                                    onSelect(e, application.id)
                                                }
                                                checked={
                                                    visibleSelections().indexOf(
                                                        application.id
                                                    ) > -1
                                                }
                                            />
                                        </td>
                                        <td>
                                            <UserEntry className="grid row">
                                                <UserAvatar
                                                    image={`${application.user.avatar}`}
                                                    title={
                                                        application.user.name
                                                    }
                                                ></UserAvatar>
                                                <div className="text-sm-medium">
                                                    <Link
                                                        to={`/candidate/${application.user.id}`}
                                                    >
                                                        {application.user.name}
                                                    </Link>
                                                    <p className="text-grey-500">
                                                        {
                                                            application.user
                                                                .candidate
                                                                .experience
                                                        }
                                                    </p>
                                                </div>
                                            </UserEntry>
                                        </td>
                                        <td>
                                            <Chip
                                                label={application.statusIndicator.label.toLowerCase()}
                                                color={
                                                    application.statusIndicator
                                                        .cssClass
                                                }
                                            />
                                        </td>
                                        {/* <td className="hidden-md-down">
                    <p>{application.user.candidate.educationLevel?.school}</p>
                    <p className="text-grey-500 truncate">
                      {application.user.candidate.educationLevel?.result}
                    </p>
                  </td> */}
                                        <td className="hidden-sm-down loader-wrapper">
                                            {videoViewState.isLoading && (
                                                <Loader
                                                    fillParent={false}
                                                    isInline={true}
                                                ></Loader>
                                            )}
                                            {!videoViewState.isLoading && (
                                                <UserCircles
                                                    users={getViewers(
                                                        application.id
                                                    )}
                                                ></UserCircles>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="grid row centered space-between">
                    <div className="grid row centered text-grey-500">
                        <select
                            id="perPage"
                            name="perPage"
                            color="transparent"
                            onChange={(e) =>
                                dispatch(
                                    userSettingsActions.setPerPage(
                                        Number(e.target.value)
                                    )
                                )
                            }
                            value={userSettingsState.perPage}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <label htmlFor="perPage">Per page</label>
                    </div>
                    <span className="text-grey-500">
                        {visibleSelections().length} selected
                    </span>
                    <div className="pagination">
                        <Button
                            label="Previous"
                            icon={faArrowLeft}
                            color="transparent"
                            onClick={(e) => onPageBack()}
                            disabled={pageBounds.start === 0}
                        />
                        <p className="text-primary">
                            {pageBounds.start + 1} -{" "}
                            {pageBounds.end > allData.length
                                ? allData.length
                                : pageBounds.end}{" "}
                            of {allData.length}
                        </p>
                        <Button
                            label="Next"
                            icon={faArrowRight}
                            color="transparent"
                            isIconTrailing={true}
                            onClick={(e) => onPageForward()}
                            disabled={pageBounds.end >= allData.length}
                        />
                    </div>
                </div>
            </div>
        </BodyWrapper>
    );
});

const ChartSection = styled.div<{ size: Size }>(
    ({ size }) => `
    grid-template-columns: ${size.isMdUp ? "1fr 1fr" : "1fr"};
    gap: calc(var(--base-spacing) * 4);
    `
);
const UserEntry = styled.div(
    () =>
        `grid-template-columns: 32px 1fr;
    align-items: center;
    gap: calc(var(--base-spacing) * 2);`
);
