import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplicationViewWithUser } from "../../models/application-view";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";

interface IApplicationViewState extends IRemoteState {
    applicationViews: IApplicationViewWithUser[];
    companyLoads: { [key: string]: number };
    vacancyLoads: { [key: string]: number };
    applicationLoads: { [key: string]: number };
    candidateLoads: { [key: string]: number };
}

const initialState: IApplicationViewState = {
    applicationViews: [],
    isLoading: false,
    companyLoads: {},
    vacancyLoads: {},
    applicationLoads: {},
    candidateLoads: {},
};

/**
 * Application view store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const applicationViewSlice = createSlice({
    name: "application-view",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<IApplicationViewWithUser>) => {
            const targetIndex = state.applicationViews.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1)
                state.applicationViews[targetIndex] = action.payload;
        },
        add: (state, action: PayloadAction<IApplicationViewWithUser[]>) => {
            state.applicationViews = state.applicationViews
                ? [...mergeArrays(state.applicationViews, action.payload)]
                : action.payload;
        },
        remove: (state, action: PayloadAction<IApplicationViewWithUser>) => {
            state.applicationViews.splice(
                state.applicationViews.findIndex(
                    (i) => i.id === action.payload.id
                ),
                1
            );
        },
        setCompanyLoaded: (state, action: PayloadAction<string>) => {
            state.companyLoads[action.payload] = new Date().valueOf();
        },
        setVacanciesLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.vacancyLoads[id] = new Date().valueOf();
            });
            state.vacancyLoads = _state.vacancyLoads;
        },
        setApplicationsLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.applicationLoads[id] = new Date().valueOf();
            });
            state.applicationLoads = _state.applicationLoads;
        },
        setCandidatesLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.candidateLoads[id] = new Date().valueOf();
            });
            state.candidateLoads = _state.candidateLoads;
        },
    },
});

export const applicationViewActions = applicationViewSlice.actions;
export default applicationViewSlice.reducer;
