import styled from "styled-components";
import { Size } from "../../hooks/window-size";

/**
 * Body wrapper
 * - For use across most pages that require a basic padded container for body content
 * - Responsive padding
 * - Grid layout
 */
export const BodyWrapper = styled.div<{ size: Size }>(
    ({ size }) => `
    display: grid;
    gap: var(--base-spacing);
    grid-auto-flow: row;
    align-self: flex-start;
    align-content: flex-start;
    position: relative;
    width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    gap: calc(var(--base-spacing) * 4);
    overflow: visible;
    padding: ${
        !size.isSmUp
            ? "calc(var(--base-spacing) * 4) calc(var(--base-spacing) * 2)"
            : !size.isMdUp
            ? "calc(var(--base-spacing) * 4) calc(var(--base-spacing) * 3)"
            : "calc(var(--base-spacing) * 4)"
    };    `
);
