import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine, Serie } from "@nivo/line";
import React from "react";
import { formatTime } from "../../utils/utils";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";

type Props = {
    data: Serie[];
};

/**
 * Line chart for time spent viewing a given cohort over 1 year (company overview)
 */
export const ChartCohortOverYear = React.memo<Props>(({ data }) => {
    // Available colours, selected sequentially based on index of data
    // If more entries are present than colours, it loops around to 0
    const colors = [
        "var(--color-chart-1)",
        "var(--color-chart-2)",
        "var(--color-chart-3)",
        "var(--color-chart-4)",
        "var(--color-chart-5)",
        "var(--color-chart-6)",
        "var(--color-chart-7)",
        "var(--color-chart-8)",
        "var(--color-chart-9)",
    ];

    const getColor = (d: Serie) => {
        // Map the ID to an index, and select a colour
        const baseIndex = data.findIndex((x) => x.id === d.id);

        // Index could exceed the amount of colour entries, ensure this doesn't happen
        // Indexes > colours.length will reset to 0
        const index = baseIndex % colors.length;
        return colors[index];
    };

    return (
        <ChartResponsiveWrapper>
            {!data || (Array.isArray(data) && !data.length) ? (
                <ChartNoData />
            ) : (
                <ResponsiveLine
                    areaOpacity={0.1}
                    axisBottom={{
                        format: "%b",
                        tickValues: "every month",
                    }}
                    axisLeft={{
                        format: formatTime,
                        tickValues: 5,
                    }}
                    axisRight={null}
                    axisTop={null}
                    curve="monotoneX"
                    data={data}
                    defs={[
                        linearGradientDef("gradient-cohort-over-year", [
                            { offset: 0, color: "var(--color-primary-800)" },
                            {
                                offset: 80,
                                color: "var(--color-primary-800)",
                                opacity: 0,
                            },
                        ]),
                    ]}
                    enableArea={true}
                    enablePoints={false}
                    enableGridX={false}
                    colors={(d) => getColor(d)}
                    fill={[{ match: "*", id: "gradient-cohort-over-year" }]}
                    legends={[
                        {
                            anchor: "top-right",
                            direction: "row",
                            justify: false,
                            translateX: 0,
                            translateY: -20,
                            itemsSpacing: 0,
                            itemDirection: "left-to-right",
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    margin={{ top: 40, left: 65, bottom: 24, right: 24 }}
                    pointBorderColor={{ from: "serieColor" }}
                    pointBorderWidth={2}
                    pointColor={{ theme: "background" }}
                    pointLabelYOffset={-12}
                    pointSize={10}
                    useMesh={true}
                    xFormat="time:%b %Y"
                    xScale={{
                        type: "time",
                        format: "%Y-%m-%d",
                        useUTC: false,
                        precision: "day",
                    }}
                    yFormat={(value) => {
                        const seconds = Number(value.valueOf());
                        return formatTime(seconds);
                    }}
                    yScale={{
                        type: "linear",
                        min: 0,
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                />
            )}
        </ChartResponsiveWrapper>
    );
});
