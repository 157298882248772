export interface IBasicUser {
    id: string;
    name: string;
    avatar: string;
}

export interface IUser extends IBasicUser {
    email: string;
    emailVerified: boolean;
    userType: string;
}

export class BasicUser implements IBasicUser {
    id: string;
    name: string;
    avatar: string;
    constructor(id: string, name: string, avatar: string) {
        this.id = id;
        this.name = name;
        this.avatar = avatar;
    }
}

export class User implements IUser {
    id: string;
    name: string;
    email: string;
    emailVerified: boolean;
    avatar: string;
    userType: string;

    constructor(
        id: string,
        name: string,
        email: string,
        emailVerified: boolean,
        avatar: string,
        userType: string
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.emailVerified = emailVerified;
        this.avatar = avatar;
        this.userType = userType;
    }
}

export interface IMembershipResponse {
    id: string;
    companyId: string;
    department?: string;
    role?: string;
    user: IUser;
}
