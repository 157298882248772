import React, { useCallback, useEffect, useState } from "react";
import { Size, useWindowSize } from "../hooks/window-size";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { loadVacancies } from "../stores/vacancy/vacancy-actions";
import { Button } from "../components/form/button";
import { PageHeaderRow } from "../components/layout/page-header-row";
import { Loader } from "../components/loader";
import { userSettingsActions } from "../stores/user-settings/user-settings-slice";
import { ApplicantCohortCount } from "../models/chart";
import { ChartApplicantCohortTotal } from "../components/chart/chart-applicant-cohort-total";
import styled from "styled-components";
import { BodyWrapper } from "../components/layout/body-wrapper";
import {
    faArrowLeft,
    faArrowRight,
    faRefresh,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Autocomplete } from "../components/form/autocomplete";
import { Toggle } from "../components/form/toggle";

/**
 * Interfaces with StringMap extension required for Type Safety since we're totalling columns
 * We may need these somewhere else in the app at some point, in which case break these out to be a shared util
 */
interface StringMap {
    [name: string]: any;
}

interface AgeData extends StringMap {
    "18-24": number;
    "25-29": number;
    "30-39": number;
    "40-49": number;
    "50-59": number;
    "60+": number;
    unknown: number;
}
interface EthnicityData extends StringMap {
    white: number;
    black: number;
    asian: number;
    hispanic: number;
    indian: number;
    mideast: number;
    other: number;
    unknown: number;
}
interface GenderData extends StringMap {
    male: number;
    female: number;
    unknown: number;
}
interface JobTotal {
    id: string;
    title: string;
    gender: GenderData;
    ethnicity: EthnicityData;
    age: AgeData;
    total: number;
    chartGender: ApplicantCohortCount[];
    chartEthnicity: ApplicantCohortCount[];
    chartAge: ApplicantCohortCount[];
}
/** End interfaces for type safety  */

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const JobStats = React.memo<Props>((_) => {
    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh
    const PER_PAGE = 3;

    // Hooks
    const size = useWindowSize();

    // Stores
    const dispatch = useAppDispatch();
    const { company } = useAppSelector((state) => state.auth);
    const vacancyState = useAppSelector((state) => state.vacancy);
    const userSettingsState = useAppSelector((state) => state.userSettings);

    // Pagination and data
    const [pageBounds, setPageBounds] = useState({ start: 0, end: PER_PAGE });
    // All view data is dupled from the Store. This can be filtered to alter the table
    const [allData, setAllData] = useState<JobTotal[]>([]);
    const [filteredData, setFilteredData] = useState<JobTotal[]>([]);
    // This is the actual viewable data on the current page
    const [viewData, setViewData] = useState<JobTotal[]>([]);

    // Filters and search
    const [searchWord, setSearchWord] = useState<string>();

    // Local state
    // Use estimated in calcs?
    const [useEstimated, setUseEstimated] = useState<boolean>(true);
    // Total / percentage switch
    const [dataType, setDataType] = useState<boolean>(false);

    /** Set user preferences on load */
    useEffect(() => {
        setUseEstimated(userSettingsState.useEstimated);
    }, [userSettingsState]);

    /**
     * Hit the API to get vacancies if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        if (!company) return;

        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;
        if (
            !vacancyState.companyLoads[company.id] ||
            vacancyState.companyLoads[company.id] <= expiryThreshold
        ) {
            console.log(
                "%cVacancy state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadVacancies(company.id)).catch(console.error);
        }
    }, [
        dispatch,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
        company,
        vacancyState.companyLoads,
    ]);

    /**
     * Gets new data from all data sources on the page
     * Used by the refresh button
     */
    const getAllData = useCallback(() => {
        if (!company) return;
        dispatch(loadVacancies(company.id));
    }, [company, dispatch]);

    /**
     * Go to a specific start index
     */
    const goToPageIndex = useCallback(
        (startIndex: number) => {
            setPageBounds({
                start: startIndex,
                end: startIndex + userSettingsState.perPage,
            });
        },
        [userSettingsState.perPage]
    );

    /**
     * Paginated view data - fire when pagination or allData is changed and set the view data
     */
    useEffect(() => {
        setViewData([...filteredData.slice(pageBounds.start, pageBounds.end)]);
    }, [pageBounds, filteredData]);

    /**
     * Filters the view data based on search field input and active filters
     * @param searchWord -f search word from the search input
     * @param filters - list of active filters
     */
    useEffect(() => {
        if (!allData) return;
        let filteredViewData = searchWord
            ? allData.filter(
                  (x) =>
                      x.title.toLowerCase().indexOf(searchWord.toLowerCase()) >
                      -1
              )
            : allData;

        // Set view data and repaginate
        setFilteredData(filteredViewData);
        goToPageIndex(0);
        // View data and selections will update automatically due to the useEffect hook
    }, [dispatch, vacancyState, searchWord, goToPageIndex, allData]);

    /**
     * Page forward
     */
    const onPageForward = useCallback(() => {
        setPageBounds({
            start: pageBounds.start + userSettingsState.perPage,
            end: pageBounds.end + userSettingsState.perPage,
        });
    }, [userSettingsState.perPage, pageBounds.end, pageBounds.start]);

    /**
     * Page back
     */
    const onPageBack = useCallback(() => {
        if (pageBounds.start < userSettingsState.perPage) {
            setPageBounds({ start: 0, end: userSettingsState.perPage });
        } else {
            setPageBounds({
                start: pageBounds.start - userSettingsState.perPage,
                end: pageBounds.start,
            });
        }
    }, [pageBounds.start, userSettingsState.perPage]);

    useEffect(() => {
        // Flatted all candidates in to a single array
        // const allUsers = vacancyState.vacancies
        //   .map((vacancy) => vacancy.applications.map((a) => a.user))
        //   .flat()

        // Get UNIQUE candidates for all our vacancies in state - a candidate may have applied many times - we don't care
        // const users = allUsers.filter(
        //   (value, index, self) =>
        //     index ===
        //     self.findIndex((t) => t.id === value.id && t.name === value.name),
        // )

        const jTotals: any[] = [];

        vacancyState.vacancies.forEach((vacancy) => {
            const allUsers = vacancy.applications.map((a) => a.user).flat();
            const users = allUsers.filter(
                (value, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.id === value.id && t.name === value.name
                    )
            );

            let total = 0;

            const ages = {
                "18-24": 0,
                "25-29": 0,
                "30-39": 0,
                "40-49": 0,
                "50-59": 0,
                "60+": 0,
                unknown: 0,
            };

            const ethnicities = {
                white: 0,
                black: 0,
                asian: 0,
                hispanic: 0,
                indian: 0,
                mideast: 0,
                other: 0,
                unknown: 0,
            };

            const genders = {
                male: 0,
                female: 0,
                unknown: 0,
            };

            let ageRes: any[] = [
                { total: 0, cohort: "18-24" },
                { total: 0, cohort: "25-29" },
                { total: 0, cohort: "30-39" },
                { total: 0, cohort: "40-49" },
                { total: 0, cohort: "50-59" },
                { total: 0, cohort: "60+" },
                { total: 0, cohort: "unknown" },
            ];

            let ethnicityRes: any[] = [
                { total: 0, cohort: "white" },
                { total: 0, cohort: "black" },
                { total: 0, cohort: "asian" },
                { total: 0, cohort: "hispanic" },
                { total: 0, cohort: "mideast" },
                { total: 0, cohort: "indian" },
                { total: 0, cohort: "other" },
                { total: 0, cohort: "unknown" },
            ];

            let genderRes: any[] = [
                { total: 0, cohort: "male" },
                { total: 0, cohort: "female" },
                { total: 0, cohort: "unknown" },
            ];

            users.forEach((user) => {
                total++;
                const age = useEstimated
                    ? user.candidate.age || user.candidate.estimatedAge?.val
                    : user.candidate.age;
                const ethnicity = useEstimated
                    ? user.candidate.ethnicity ||
                      user.candidate.estimatedEthnicity?.val
                    : user.candidate.ethnicity;
                const gender = useEstimated
                    ? user.candidate.gender ||
                      user.candidate.estimatedGender?.val
                    : user.candidate.gender;

                let index = 0;
                if (age) {
                    if (age >= 18 && age <= 24) {
                        ages["18-24"]++;
                        index = ageRes.findIndex((x) => x.cohort === "18-24");
                    } else if (age >= 25 && age <= 29) {
                        ages["25-29"]++;
                        index = ageRes.findIndex((x) => x.cohort === "25-29");
                    } else if (age >= 30 && age <= 39) {
                        ages["30-39"]++;
                        index = ageRes.findIndex((x) => x.cohort === "30-39");
                    } else if (age >= 40 && age <= 49) {
                        ages["40-49"]++;
                        index = ageRes.findIndex((x) => x.cohort === "40-49");
                    } else if (age >= 50 && age <= 59) {
                        ages["50-59"]++;
                        index = ageRes.findIndex((x) => x.cohort === "50-59");
                    } else if (age >= 60) {
                        ages["60+"]++;
                        index = ageRes.findIndex((x) => x.cohort === "60+");
                    } else {
                        ages.unknown++;
                    }
                } else {
                    ages.unknown++;
                }

                ageRes[index].total += 1;
                ageRes[index].percentage =
                    (ageRes[index].total / users.length) * 100;

                index = 0;

                switch (ethnicity) {
                    case "white":
                        ethnicities.white++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "white"
                        );
                        break;
                    case "black":
                        ethnicities.black++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "black"
                        );
                        break;
                    case "asian":
                        ethnicities.asian++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "asian"
                        );
                        break;
                    case "hispanic":
                        ethnicities.hispanic++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "hispanic"
                        );
                        break;
                    case "indian":
                        ethnicities.indian++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "indian"
                        );
                        break;
                    case "mideast":
                        ethnicities.mideast++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "mideast"
                        );
                        break;
                    case "other":
                        ethnicities.other++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "other"
                        );
                        break;
                    default:
                        ethnicities.unknown++;
                        index = ethnicityRes.findIndex(
                            (x) => x.cohort === "unknown"
                        );
                        break;
                }

                ethnicityRes[index].total++;
                ethnicityRes[index].percentage =
                    (ethnicityRes[index].total / users.length) * 100;

                switch (gender) {
                    case "male":
                        genders.male++;
                        index = genderRes.findIndex((x) => x.cohort === "male");
                        break;
                    case "female":
                        genders.female++;
                        index = genderRes.findIndex(
                            (x) => x.cohort === "female"
                        );
                        break;
                    default:
                        genders.unknown++;
                        index = genderRes.findIndex(
                            (x) => x.cohort === "unknown"
                        );
                        break;
                }

                genderRes[index].total++;
                genderRes[index].percentage =
                    (genderRes[index].total / users.length) * 100;
            });

            const res: JobTotal = {
                id: vacancy.id,
                title: vacancy.title,
                gender: genders,
                ethnicity: ethnicities,
                age: ages,
                total: total,
                chartGender: genderRes,
                chartEthnicity: ethnicityRes,
                chartAge: ageRes,
            };

            jTotals.push(res);
        });

        setAllData(jTotals);
    }, [dispatch, useEstimated, vacancyState.vacancies]);

    return (
        <BodyWrapper
            className="grid column"
            size={size}
            style={{ gridTemplateRows: "auto auto 1fr auto" }}
        >
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">
                    {company?.name} - Applicant Statistics - Vacancies
                </h1>
                <div className="grid row centered">
                    <div className="grid row centered">
                        <span className="text-xs-normal text-right">
                            {useEstimated ? "Inc. " : "Ex."} estimated
                        </span>
                        <Toggle
                            checked={useEstimated}
                            onChange={(e) =>
                                dispatch(
                                    userSettingsActions.setUseEstimated(
                                        !useEstimated
                                    )
                                )
                            }
                        />
                    </div>
                    <Button
                        label={"Type: " + (dataType ? "Total" : "Percentage")}
                        // icon={faRefresh}
                        color="white"
                        onClick={() => {
                            setDataType(!dataType);
                        }}
                    />
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={async () => await getAllData()}
                        disabled={vacancyState.isLoading}
                    />
                </div>
            </PageHeaderRow>
            <section>
                <Autocomplete
                    type="text"
                    name="jobTitle"
                    icon={faSearch}
                    placeholder="Job Title"
                    data={allData.map((x) => x.title)}
                    handleChange={setSearchWord}
                    updateOnInput={true}
                ></Autocomplete>
            </section>
            <section className="grid gap-lg">
                {vacancyState.isLoading ? (
                    <Loader fillParent={true} />
                ) : !viewData || !viewData.length ? (
                    <h2 className="text-md-semibold text-grey-500 text-center grid row centered">
                        No data
                    </h2>
                ) : (
                    viewData.map((vacancy, i) => (
                        <article className="grid gap-md" key={i}>
                            <h2 className="text-lg-medium">{vacancy.title}</h2>
                            <h3 className="text-sm-normal grid row start centered">
                                Gender
                                {useEstimated && (
                                    <i className="text-grey-500 text-xs-normal">
                                        (includes estimated)
                                    </i>
                                )}
                            </h3>
                            <ChartSection size={size} className="grid">
                                <div className="table-wrapper dash-grid">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Total</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Male</td>
                                                <td>{vacancy.gender.male}</td>
                                                <td>
                                                    {(
                                                        (vacancy.gender.male /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Female</td>
                                                <td>{vacancy.gender.female}</td>
                                                <td>
                                                    {(
                                                        (vacancy.gender.female /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Unknown</td>
                                                <td>
                                                    {vacancy.gender.unknown}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.gender
                                                            .unknown /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr className="bg-">
                                                <td>Total</td>
                                                <td>
                                                    {" "}
                                                    {Object.keys(vacancy.gender)
                                                        .map(
                                                            (prop) =>
                                                                vacancy.gender[
                                                                    prop
                                                                ]
                                                        )
                                                        .reduce(
                                                            (
                                                                a: number,
                                                                c: number
                                                            ) => a + c
                                                        )}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <ChartApplicantCohortTotal
                                    data={vacancy.chartGender}
                                    dataType={dataType ? "total" : "percentage"}
                                />
                            </ChartSection>
                            <h4 className="text-sm-normal grid row start centered">
                                Ethnicity
                                {useEstimated && (
                                    <i className="text-grey-500 text-xs-normal">
                                        (includes estimated)
                                    </i>
                                )}
                            </h4>
                            <ChartSection size={size} className="grid">
                                <div className="table-wrapper dash-grid">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Total</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>White</td>
                                                <td>
                                                    {vacancy.ethnicity.white}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .white /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Black</td>
                                                <td>
                                                    {vacancy.ethnicity.black}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .black /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Asian</td>
                                                <td>
                                                    {vacancy.ethnicity.asian}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .asian /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hispanic</td>
                                                <td>
                                                    {vacancy.ethnicity.hispanic}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .hispanic /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Indian</td>
                                                <td>
                                                    {vacancy.ethnicity.indian}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .indian /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Middle East</td>
                                                <td>
                                                    {vacancy.ethnicity.mideast}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .mideast /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Other</td>
                                                <td>
                                                    {vacancy.ethnicity.other}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .other /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Unknown</td>
                                                <td>
                                                    {vacancy.ethnicity.unknown}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.ethnicity
                                                            .unknown /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr className="bg-">
                                                <td>Total</td>
                                                <td>
                                                    {" "}
                                                    {Object.keys(
                                                        vacancy.ethnicity
                                                    )
                                                        .map(
                                                            (prop) =>
                                                                vacancy
                                                                    .ethnicity[
                                                                    prop
                                                                ]
                                                        )
                                                        .reduce(
                                                            (
                                                                a: number,
                                                                c: number
                                                            ) => a + c
                                                        )}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <ChartApplicantCohortTotal
                                    data={vacancy.chartEthnicity}
                                    dataType={dataType ? "total" : "percentage"}
                                />
                            </ChartSection>
                            <h4 className="text-sm-normal grid row start centered">
                                Age
                                {useEstimated && (
                                    <i className="text-grey-500 text-xs-normal">
                                        (includes estimated)
                                    </i>
                                )}
                            </h4>
                            <ChartSection size={size} className="grid">
                                <div className="table-wrapper dash-grid">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Total</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>18-24</td>
                                                <td>{vacancy.age["18-24"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["18-24"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>25-29</td>
                                                <td>{vacancy.age["25-29"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["25-29"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>30-39</td>
                                                <td>{vacancy.age["30-39"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["30-39"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>40-49</td>
                                                <td>{vacancy.age["40-49"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["40-49"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>50-59</td>
                                                <td>{vacancy.age["50-59"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["50-59"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>60+</td>
                                                <td>{vacancy.age["60+"]}</td>
                                                <td>
                                                    {(
                                                        (vacancy.age["60+"] /
                                                            vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Unknown</td>
                                                <td>
                                                    {vacancy.age["unknown"]}
                                                </td>
                                                <td>
                                                    {(
                                                        (vacancy.age[
                                                            "unknown"
                                                        ] / vacancy.total ||
                                                            0) * 100
                                                    ).toFixed(2)}
                                                    %
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr className="bg-">
                                                <td>Total</td>
                                                <td>
                                                    {" "}
                                                    {Object.keys(vacancy.age)
                                                        .map(
                                                            (prop) =>
                                                                vacancy.age[
                                                                    prop
                                                                ]
                                                        )
                                                        .reduce(
                                                            (
                                                                a: number,
                                                                c: number
                                                            ) => a + c
                                                        )}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <ChartApplicantCohortTotal
                                    data={vacancy.chartAge}
                                    dataType={dataType ? "total" : "percentage"}
                                />
                            </ChartSection>
                            {
                                // React requires "key" due to being in a map
                                // If we use a fragment here then we lose the ability to use a key, but get spacing set by the parent grid
                                // We need the key, so add the "missing" margin to the top. The main container has *4, the parent container has *1
                                // So add *3 margin
                            }
                            <hr
                                style={{
                                    marginTop: "calc(var(--base-spacing) * 3)",
                                }}
                            />
                        </article>
                    ))
                )}
            </section>
            <div className="grid row centered space-between">
                <div className="grid row centered text-grey-500">
                    <select
                        id="perPage"
                        name="perPage"
                        color="transparent"
                        onChange={(e) =>
                            dispatch(
                                userSettingsActions.setPerPage(
                                    Number(e.target.value)
                                )
                            )
                        }
                        value={userSettingsState.perPage}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                    <label htmlFor="perPage">Per page</label>
                </div>

                <div className="pagination">
                    <Button
                        label="Previous"
                        icon={faArrowLeft}
                        color="transparent"
                        onClick={(e) => onPageBack()}
                        disabled={pageBounds.start === 0}
                    />
                    <p className="text-primary">
                        {pageBounds.start + 1} -{" "}
                        {pageBounds.end > filteredData.length
                            ? filteredData.length
                            : pageBounds.end}{" "}
                        of {filteredData.length}
                    </p>
                    <Button
                        label="Next"
                        icon={faArrowRight}
                        color="transparent"
                        isIconTrailing={true}
                        onClick={(e) => onPageForward()}
                        disabled={pageBounds.end >= filteredData.length}
                    />
                </div>
            </div>
        </BodyWrapper>
    );
});

const ChartSection = styled.section<{ size: Size }>(
    ({ size }) => `
    grid-template-columns: ${size.isMdUp ? "1fr 1fr" : "1fr"};
    gap: calc(var(--base-spacing) * 4) calc(var(--base-spacing) * 2);
    align-items: flex-start;
    `
);
