import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { Application, IApplication } from "../../models/application";
import { errorActions } from "../error/error-slice";
import { requestOptions, ROOT_URL } from "../utils";
import { applicationActions } from "./application-slice";

const START_CUTOFF_DATE = process.env.REACT_APP_START_CUTOFF_DATE;

/**
 * Loads applications from the database and updates state
 * @returns state update
 */
export const loadApplicationsByCompanyId =
    (companyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(applicationActions.loading());

            const response = await fetch(
                `${ROOT_URL}/application/byMyCompanyWithCandidate`,
                requestOptions()
            );
            const data: IApplication[] & IApiError = await response.json();
            if (!data.statusCode) {
                // Apply cuttoff date, if set
                const applications = data
                    .filter(
                        (a) =>
                            a.user &&
                            a.user.candidate &&
                            new Date(a.createdAt).valueOf() >
                                (START_CUTOFF_DATE
                                    ? new Date(START_CUTOFF_DATE).valueOf()
                                    : 0)
                    )
                    .map((a) => {
                        return new Application(
                            a.id,
                            a.vacancyId,
                            a.companyId,
                            a.candidateId,
                            a.answers,
                            new Date(a.createdAt),
                            a.rightToWork,
                            new Date(a.updatedAt),
                            a.user,
                            a.status,
                            []
                        );
                    });

                dispatch(applicationActions.add(applications));
                dispatch(applicationActions.setCompanyLoaded(companyId));
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading applications";
            dispatch(applicationActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error: error,
                        message: message,
                    },
                ])
            );
        } finally {
            dispatch(applicationActions.loaded());
        }
    };

/**
 * Loads a specific candidateion from the database and adds to store (if not already present)
 * @param id (string) - Target candidateion ID
 */
export const loadApplication = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(applicationActions.loading());
        const response = await fetch(
            `${ROOT_URL}/candidate/byIdWithCandidate?id=${id}`,
            requestOptions()
        );
        const data: IApplication & IApiError = await response.json();
        if (!data.statusCode) {
            data.user &&
                data.user.candidate &&
                dispatch(
                    applicationActions.add([
                        new Application(
                            data.id,
                            data.vacancyId,
                            data.companyId,
                            data.candidateId,
                            data.answers,
                            new Date(data.createdAt),
                            data.rightToWork,
                            new Date(data.updatedAt),
                            data.user,
                            data.status,
                            data.viewers
                        ),
                    ])
                );
        } else {
            throw new Error((data as IApiError).message);
        }
    } catch (error) {
        console.error(error);
        const message =
            typeof error === "string"
                ? error
                : "Something went wrong when loading candidate";
        dispatch(applicationActions.error(message));
        dispatch(
            errorActions.add([
                {
                    error: error,
                    message: message,
                },
            ])
        );
    } finally {
        dispatch(applicationActions.loaded());
    }
};
