import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVacancy } from "../../models/vacancy";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";

interface IVacancyState extends IRemoteState {
    vacancies: IVacancy[];
    companyLoads: { [key: string]: number };
    vacancyLoads: { [key: string]: number };
}

const initialState: IVacancyState = {
    vacancies: [],
    companyLoads: {},
    vacancyLoads: {},
    isLoading: false,
};

/**
 * Vacancy store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const vacancySlice = createSlice({
    name: "vacancy",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<IVacancy>) => {
            const targetIndex = state.vacancies.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1) state.vacancies[targetIndex] = action.payload;
            const vacancyLoads = { ...state.vacancyLoads };
            vacancyLoads[action.payload.id] = new Date().valueOf();
            state.vacancyLoads = vacancyLoads;
        },
        add: (state, action: PayloadAction<IVacancy[]>) => {
            state.vacancies = state.vacancies
                ? [...mergeArrays(state.vacancies, action.payload)]
                : action.payload;
            const vacancyLoads = { ...state.vacancyLoads };
            action.payload.forEach((application) => {
                vacancyLoads[application.id] = new Date().valueOf();
            });
            state.vacancyLoads = vacancyLoads;
        },
        remove: (state, action: PayloadAction<IVacancy>) => {
            state.vacancies.splice(
                state.vacancies.findIndex((i) => i.id === action.payload.id),
                1
            );
        },
        setCompanyLoaded: (state, action: PayloadAction<string>) => {
            state.companyLoads[action.payload] = new Date().valueOf();
        },
    },
});

export const vacancyActions = vacancySlice.actions;
export default vacancySlice.reducer;
