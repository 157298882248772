import { EducationLevel } from "./education-level";
import { EstimatedCohort } from "./estimated-cohort";
import { User } from "./user";

/**
 * Backend/DB has candidate nested within User
 * Replicate this behaviour here so we can unify our User>Candidate objects like the backend does.
 */
export interface ICandidate extends User {
    candidate: {
        avatar: string;
        companySize?: string[];
        cv?: string;
        description?: string;
        importantThings?: string[];
        industries?: string[];
        preferredRoles?: string[];
        salaryExpectation?: number;
        skills?: string[];
        videos?: string[];
        age?: number;
        ethnicity?: string;
        gender?: string;
        estimatedAge?: EstimatedCohort<number>;
        estimatedEthnicity?: EstimatedCohort<string>;
        estimatedGender?: EstimatedCohort<string>;

        experience?: string;
        educationLevel?: EducationLevel;
        baseFilename?: string;
        scanFilename?: string;
        stats?: { name: string; value: unknown; certainty: number }[];
    };
}

export class Candidate extends User implements ICandidate {
    candidate: {
        avatar: string;
        companySize?: string[];
        cv?: string;
        description?: string;
        importantThings?: string[];
        industries?: string[];
        preferredRoles?: string[];
        salaryExpectation?: number;
        skills?: string[];
        videos?: string[];
        age?: number;
        ethnicity?: string;
        gender?: string;
        estimatedAge?: EstimatedCohort<number>;
        estimatedEthnicity?: EstimatedCohort<string>;
        estimatedGender?: EstimatedCohort<string>;

        experience?: string;
        educationLevel?: EducationLevel;
        baseFilename?: string;
        scanFilename?: string;
        stats?: { name: string; value: unknown; certainty: number }[];
    };

    constructor(
        id: string,
        name: string,
        avatar: string,
        email: string,
        emailVerified: boolean,
        userType: string,
        companySize?: string[],
        cv?: string,
        description?: string,
        importantThings?: string[],
        industries?: string[],
        preferredRoles?: string[],
        salaryExpectation?: number,
        skills?: string[],
        videos?: string[],
        age?: number,
        ethnicity?: string,
        gender?: string,
        estimatedAge?: EstimatedCohort<number>,
        estimatedEthnicity?: EstimatedCohort<string>,
        estimatedGender?: EstimatedCohort<string>,

        experience?: string,
        educationLevel?: EducationLevel,
        baseFilename?: string,
        scanFilename?: string,
        stats?: { name: string; value: unknown; certainty: number }[]
    ) {
        super(id, name, email, emailVerified, avatar, userType);

        this.candidate = {
            avatar: avatar,
            companySize: companySize,
            cv: cv,
            description: description,
            importantThings: importantThings,
            industries: industries,
            preferredRoles: preferredRoles,
            salaryExpectation: salaryExpectation,
            skills: skills,
            videos: videos,
            age: age,
            ethnicity: ethnicity,
            gender: gender,
            estimatedAge: estimatedAge,
            estimatedEthnicity: estimatedEthnicity,
            estimatedGender: estimatedGender,

            experience: experience,
            educationLevel: educationLevel,
            scanFilename: scanFilename,
            baseFilename: baseFilename,
            stats: stats,
        };
    }
}
