export interface ISkip {
    from: number;
    to: number;
    delta: number;
    timestamp: number;
}

export class Skip implements ISkip {
    from: number;
    to: number;
    delta: number;
    timestamp: number;

    constructor(from: number, to: number, delta: number, timestamp: number) {
        this.from = from;
        this.to = to;
        this.delta = delta;
        this.timestamp = timestamp;
    }
}

export interface IFocusChange {
    wasLost: boolean;
    timestamp: number;
}

export class FocusChange implements IFocusChange {
    wasLost: boolean;
    timestamp: number;
    constructor(wasLost: boolean, timestamp: number) {
        this.wasLost = wasLost;
        this.timestamp = timestamp;
    }
}

// Base interface for video views - ALL video views should extend or derive from this interface
export interface IVideoViewBase {
    id: string;
    vacancyId: string;
    applicationId: string;
    createdAt: Date;
    updatedAt: Date;
    playTime: number;
}

/**
 * Base interface for video viewer information
 * Classes that need to display Viewer info along with the Video View should extend this interface
 **/

export interface IVideoViewViewer {
    viewerId: string;
    viewerName: string;
    viewerAvatar: string;
}

/**
 * Base interface for cohort information
 * Classes that need to display Cohort info along with the Video View should extend this interface
 **/

export interface IVideoViewCohort {
    age: string;
    gender: string;
    ethnicity: string;
}

/**
 * VideoView DB interface - exactly as it is in the DB
 */
export interface IVideoView {
    id: string;
    companyId: string;
    candidateId: string;
    vacancyId: string;
    videoId: string;
    applicationId: string;
    watchNumber: number;
    userId: string;
    duration: number;
    activeTime: number;
    playTime: number;
    blurTime: number;
    volumeTime: number;
    skippedSeconds: number;
    skips: ISkip[];
    focusChanges: IFocusChange[];
    createdAt: Date;
    updatedAt: Date;
}

/** Interface combining cohort and viewer */
export interface IVideoViewFullData
    extends IVideoView,
        IVideoViewViewer,
        IVideoViewCohort {}

/** Class with all implemented interfaces
 * Previously we had a set of classes for base Video View and then extensions
 * However new requirements make it so that we always need all info, so now we have a single class
 */
export class VideoView implements IVideoViewFullData {
    id: string;
    companyId: string;
    candidateId: string;
    vacancyId: string;
    videoId: string;
    applicationId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
    watchNumber: number;
    duration: number;
    activeTime: number;
    playTime: number;
    blurTime: number;
    volumeTime: number;
    skippedSeconds: number;
    skips: ISkip[];
    focusChanges: IFocusChange[];
    age: string;
    gender: string;
    ethnicity: string;
    viewerId: string;
    viewerName: string;
    viewerAvatar: string;

    constructor(
        id: string,
        companyId: string,
        candidateId: string,
        vacancyId: string,
        videoId: string,
        applicationId: string,
        userId: string,
        createdAt: Date,
        updatedAt: Date,
        watchNumber: number,
        duration: number,
        activeTime: number,
        playTime: number,
        blurTime: number,
        volumeTime: number,
        skippedSeconds: number,
        skips: ISkip[],
        focusChanges: IFocusChange[],
        age: string,
        gender: string,
        ethnicity: string,
        viewerId: string,
        viewerName: string,
        viewerAvatar: string
    ) {
        this.id = id;
        this.companyId = companyId;
        this.candidateId = candidateId;
        this.vacancyId = vacancyId;
        this.videoId = videoId;
        this.applicationId = applicationId;
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.watchNumber = watchNumber;
        this.duration = duration;
        this.activeTime = activeTime;
        this.playTime = playTime;
        this.blurTime = blurTime;
        this.volumeTime = volumeTime;
        this.skippedSeconds = skippedSeconds;
        this.skips = skips;
        this.focusChanges = focusChanges;
        this.age = age;
        this.gender = gender;
        this.ethnicity = ethnicity;
        this.viewerId = viewerId;
        this.viewerName = viewerName;
        this.viewerAvatar = viewerAvatar;
    }
}
