import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CompanyOverview } from "./pages/company-overview";
import { CandidateOverview } from "./pages/candidate-overview";
import reportWebVitals from "./reportWebVitals";
import { Error } from "./pages/error";
import { DashboardRoot } from "./components/roots/dashboard-root";
import { VacancyOverview } from "./pages/vacancy-overview";
import { Provider } from "react-redux";
import { persistor, store } from "./stores/store";
import { PersistGate } from "redux-persist/integration/react";
import { Loader } from "./components/loader";
import { LogIn } from "./pages/log-in";
import { ForgotPassword } from "./pages/forgot-password";
import { AuthRoot } from "./components/roots/auth-root";
import { AccountRoot } from "./components/roots/account-root";
import { Account } from "./pages/account";
import { ErrorSink } from "./components/roots/error-sink";
import { AccessDenied } from "./pages/access-denied";
import { Dashboard } from "./pages/dashboard";
import { HiringManagerOverview } from "./pages/hiring-manager";
import { JobStats } from "./pages/job-stats";

const router = createBrowserRouter([
    {
        path: "/",
        element: <DashboardRoot />,
        errorElement: <Error />,
        children: [
            {
                path: "/",
                element: <CompanyOverview />,
            },
            {
                path: "/vacancy/:vacancyId",
                element: <VacancyOverview />,
            },
            {
                path: "/candidate/:candidateId",
                element: <CandidateOverview />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/job-stats",
                element: <JobStats />,
            },
            {
                path: "/managers",
                element: <HiringManagerOverview />,
            },
        ],
    },
    {
        path: "/auth",
        element: <AuthRoot />,
        errorElement: <Error />,
        children: [
            {
                path: "/auth/login",
                element: <LogIn />,
                errorElement: <Error />,
            },

            {
                path: "/auth/forgot-password",
                element: <ForgotPassword />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/account",
        element: <AccountRoot />,
        errorElement: <Error />,
        children: [
            { path: "/account", element: <Account />, errorElement: <Error /> },
        ],
    },
    {
        path: "/access-denied",
        element: <AccessDenied />,
        errorElement: <Error />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <PersistGate loading={<Loader isFullscreen={true} />} persistor={persistor}>
        <Provider store={store}>
            <ErrorSink>
                <RouterProvider router={router} />
            </ErrorSink>
        </Provider>
    </PersistGate>
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
