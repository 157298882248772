import styled from "styled-components";

import React, { useRef } from "react";
import { IBasicUser } from "../models/user";
import { UserAvatar } from "./user-avatar";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    users: IBasicUser[];
    displayCount?: number;
    size?: number;
    userRoute?: string;
    moreRoute?: string;
}

// Quick access to change the border width
const borderWidth = 2;

// Shorthand props
const _border = `${borderWidth}px solid var(--color-white)`;

/**
 * A horizontal row of avatar circles
 * - Displays a given amount based on `displayCount` with a final blank circle with the remaining count
 * - Clicking a user avatar navigates to `userRoute`
 * - Clicking the blank/overflow circle navigates to `moreRoute`
 * - Might refactor to accept functions instead of routes, would allow more flexibility
 */
export const UserCircles = React.memo<Props>(
    // 28 px = usual 24px + border width of 2 (2*2 = 4)
    ({ users, displayCount = 5, size = 28, userRoute, moreRoute }) => {
        const navigate = useNavigate();
        const guid = useRef(uuidv4());

        /**
         * Fires when clicking the blank/overflow circle
         */
        const onClickMore = () => {
            if (moreRoute) navigate(`${moreRoute}`);
        };

        /**
         * Fires when clicking a user circle
         */
        const onClick = (user: IBasicUser) => {
            if (userRoute) navigate(`${userRoute}/${user.id}`);
        };

        return (
            <Container size={`${size}px`}>
                {/* We don't want to do this in a map, since that will iterate the entire array when all we want is the displayCount (+1)
        If you know a better way of doing this in React without an immediately invoked function, please refactor. 
        I did explore userEffect and useRef but it got very messy very quickly with depdendencies. 
        This should be pretty performant, it only renders twice.
        Can you tell I'm an Angular guy? 😂 */}
                {(() => {
                    let elements: JSX.Element[] = [];

                    for (let i = 0; i < users.length; i++) {
                        // If display count is exceeded, show another entry signifying how many are left and then break
                        if (i >= displayCount) {
                            elements.push(
                                <UserAvatar
                                    key={`${guid.current}_${i}`}
                                    onClick={
                                        moreRoute ? onClickMore : undefined
                                    }
                                    animateOnHover={!moreRoute}
                                    label={`+${users.length - i}`}
                                    size={`${size}px`}
                                    style={{
                                        position: "absolute",
                                        left: `${(i * size) / 1.5}px`,
                                        border: _border,
                                        cursor: moreRoute
                                            ? "pointer"
                                            : "normal",
                                    }}
                                />
                            );
                            break;
                        }

                        // Else push in a user avatar, we've still got room
                        elements.push(
                            <UserAvatar
                                onClick={() => onClick(users[i])}
                                animateOnHover={true}
                                key={`${guid.current}_${users[i].id}`}
                                image={`${users[i].avatar}`}
                                size={`${size}px`}
                                style={{
                                    position: "absolute",
                                    left: `${(i * size) / 1.5}px`,
                                    border: _border,
                                    cursor: userRoute ? "pointer" : "normal",
                                }}
                                title={`${users[i].name}`}
                            />
                        );
                    }

                    return elements;
                })()}
                {/* 
        Use a relative positioned block element of the same width to force containing column width
        Since the images are positioned absolute, the parent container width is 0, causing overflow issues
        This prevents that with no real computation and width being set via a hook/listener, albeit a bit of a hack
        It should at least be more performant.
      */}
                <Placeholder
                    size={`${
                        (size *
                            (users.length > displayCount
                                ? displayCount + 1
                                : displayCount)) /
                        1.5
                    }px`}
                ></Placeholder>
            </Container>
        );
    }
);

const Container = styled.div<{ size: string }>(
    ({ size }) => `
      position: relative;
      display: flex;
      align-items: center;
      height: ${size}
    `
);

const Placeholder = styled.div<{ size: string }>(
    ({ size }) => `width: ${size}; height: 24px`
);
