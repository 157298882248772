import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { applicationViewActions } from "./application-view-slice";
import { requestOptions, ROOT_URL } from "../utils";
import { errorActions } from "../error/error-slice";
import {
    IApplicationViewWithUser,
    ApplicationView,
} from "../../models/application-view";

/**
 * Loads application views by company ID and updates state
 * @param companyId - the vacancy ID to query
 */
export const loadApplicationViewsByCompanyId =
    (companyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(applicationViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/application-view/byCompanyId?id=${companyId}`,
                requestOptions()
            );
            const data: IApplicationViewWithUser[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const applicationViews = (
                    data as IApplicationViewWithUser[]
                ).map((view) => {
                    return new ApplicationView(
                        view.id,
                        view.companyId,
                        view.candidateId,
                        view.vacancyId,
                        view.applicationId,
                        view.userId,
                        view.createdAt,
                        view.updatedAt,
                        view.user
                    );
                });
                dispatch(applicationViewActions.add(applicationViews));
                dispatch(applicationViewActions.setCompanyLoaded(companyId));

                // Loading company views also gives us all vacancies for this company. Set these as loaded too
                const vacancyApplicationViews = applicationViews.map(
                    (view) => view.vacancyId
                );
                dispatch(
                    applicationViewActions.setVacanciesLoaded(
                        vacancyApplicationViews
                    )
                );

                // Loading company views also gives us all applications for this company. Set these as loaded too
                const applicationApplicationViews = applicationViews.map(
                    (view) => view.applicationId
                );
                dispatch(
                    applicationViewActions.setApplicationsLoaded(
                        applicationApplicationViews
                    )
                );

                // Loading company views also gives us all candidates for this company. Set these as loaded too
                const candidateApplicationViews = applicationViews.map(
                    (view) => view.candidateId
                );
                dispatch(
                    applicationViewActions.setCandidatesLoaded(
                        candidateApplicationViews
                    )
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading application views by vacancy ID";
            dispatch(applicationViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(applicationViewActions.loaded());
        }
    };

/**
 * Loads application views by vacancy ID and updates state
 * @param vacancyId - the vacancy ID to query
 */
export const loadApplicationViewsByVacancyId =
    (vacancyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(applicationViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/application-view/byVacancyId?id=${vacancyId}`,
                requestOptions()
            );
            const data: IApplicationViewWithUser[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const applicationViews = (
                    data as IApplicationViewWithUser[]
                ).map((view) => {
                    return new ApplicationView(
                        view.id,
                        view.companyId,
                        view.candidateId,
                        view.vacancyId,
                        view.applicationId,
                        view.userId,
                        view.createdAt,
                        view.updatedAt,
                        view.user
                    );
                });
                dispatch(applicationViewActions.add(applicationViews));
                dispatch(
                    applicationViewActions.setVacanciesLoaded([vacancyId])
                );
                // Loading vacancy views also gives us some applications. Set these as loaded too
                const applicationApplicationViews = applicationViews.map(
                    (view) => view.applicationId
                );
                dispatch(
                    applicationViewActions.setApplicationsLoaded(
                        applicationApplicationViews
                    )
                );

                // Loading vacancy views also gives us some candidates. Set these as loaded too
                const candidateApplicationViews = applicationViews.map(
                    (view) => view.candidateId
                );
                dispatch(
                    applicationViewActions.setCandidatesLoaded(
                        candidateApplicationViews
                    )
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading application views by vacancy ID";
            dispatch(applicationViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(applicationViewActions.loaded());
        }
    };

/**
 * Loads application views by application ID and updates state
 * @param applicationId - the application ID to query
 */
export const loadApplicationViewsByApplicationId =
    (applicationId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(applicationViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/application-view/byApplicationId?id=${applicationId}`,
                requestOptions()
            );
            const data: IApplicationViewWithUser[] & IApiError =
                await response.json();

            if (!data.statusCode) {
                const applicationViews = (
                    data as IApplicationViewWithUser[]
                ).map((view) => {
                    return new ApplicationView(
                        view.id,
                        view.companyId,
                        view.candidateId,
                        view.vacancyId,
                        view.applicationId,
                        view.userId,
                        view.createdAt,
                        view.updatedAt,
                        view.user
                    );
                });
                dispatch(applicationViewActions.add(applicationViews));
                dispatch(
                    applicationViewActions.setApplicationsLoaded([
                        applicationId,
                    ])
                );

                // Loading appliation views also gives us some candidates. Set these as loaded too
                const candidateApplicationViews = applicationViews.map(
                    (view) => view.candidateId
                );
                dispatch(
                    applicationViewActions.setCandidatesLoaded(
                        candidateApplicationViews
                    )
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading application views by application ID";
            dispatch(applicationViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(applicationViewActions.loaded());
        }
    };

/**
 * Loads application views by application ID and updates state
 * @param candidateId - the candidate ID to query
 */
export const loadApplicationViewsByCandidateId =
    (candidateId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(applicationViewActions.loading());

            const response = await fetch(
                `${ROOT_URL}/application-view/byCandidateId?id=${candidateId}`,
                requestOptions()
            );
            const data: IApplicationViewWithUser[] & IApiError =
                await response.json();
            if (!data.statusCode) {
                const applicationViews = (
                    data as IApplicationViewWithUser[]
                ).map((view) => {
                    return new ApplicationView(
                        view.id,
                        view.companyId,
                        view.candidateId,
                        view.vacancyId,
                        view.applicationId,
                        view.userId,
                        view.createdAt,
                        view.updatedAt,
                        view.user
                    );
                });
                dispatch(applicationViewActions.add(applicationViews));
                dispatch(
                    applicationViewActions.setCandidatesLoaded([candidateId])
                );
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading application views by application ID";
            dispatch(applicationViewActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(applicationViewActions.loaded());
        }
    };
