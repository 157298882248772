import styled from "styled-components";
import React from "react";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./icon";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isFullscreen?: boolean;
    fillParent?: boolean;
    isInline?: boolean;
}

/**
 * Animated circular loader component
 * - At 48px, this is intended for use when loading a page or section rather than a single value
 * - `isFullscreen` will cause the Loader to span the entire viewport with a white shaded background
 * - `fillParent` will cause the Loader to span the parent (as long as its positioned relative) with a semi-transparent white shaded background
 */
export const Loader = React.memo<Props>(
    ({ isFullscreen = false, fillParent = false, isInline = false, style }) => (
        <Container
            isFullscreen={isFullscreen}
            fillParent={fillParent}
            isInline={isInline}
            style={style}
        >
            <Icon
                icon={faCircleNotch}
                title="Loading"
                className="animated spin"
            ></Icon>
        </Container>
    )
);

const Container = styled.div<{
    isFullscreen: boolean;
    fillParent: boolean;
    isInline: boolean;
}>(
    ({ isFullscreen, fillParent, isInline }) => `
    background: ${
        isFullscreen
            ? "var(--color-white)"
            : fillParent
            ? "rgba(255,255,255,0.8)"
            : "transparent"
    };
    color: var(--color-primary-700);
    position: ${isFullscreen ? "fixed" : fillParent ? "absolute" : "relative"};
    inset: 0;
    display: ${isInline ? "inline-block" : "grid"};
    place-items: ${isInline ? "inherit" : "center"};;
    font-size: ${isInline ? "inherit" : "48px"};
    backdrop-filter: blur(3px);
    z-index: 9999;
  `
);
