import {
    faExclamationCircle,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useRouteError } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "../components/icon";
import { BlankPage } from "../components/roots/blank-page";
import { Size, useWindowSize } from "../hooks/window-size";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Error page
 */
export const Error = React.memo<Props>((props) => {
    // Window size info
    const size = useWindowSize();

    const error = useRouteError() as {
        status: number;
        statusText: string;
        data: any;
    };

    return (
        <BlankPage className="animated gradient-background">
            <Container size={size}>
                <Tag>
                    {error?.status === 404 ? (
                        <Icon icon={faSearch} />
                    ) : (
                        <Icon icon={faExclamationCircle} />
                    )}
                </Tag>
                <div className="grid column">
                    <Title>{error?.status ? error?.status : "Uh-oh!"}</Title>
                    <Description>
                        {error?.status === 404
                            ? `We couldn't find what you're looking for!`
                            : error?.status === 401
                            ? `You aren't authorized to see this`
                            : "Something went wrong!"}
                    </Description>
                </div>
            </Container>
        </BlankPage>
    );
});

const Container = styled.div<{ size: Size }>(
    ({ size }) => `
  display: grid;
  gap: calc(var(--base-spacing) * 4);
  grid-template-columns: ${size.isSmUp ? "auto 1fr" : "1fr"};
  align-items: center;
  justify-items: ${size.isSmUp ? "flex-start" : "center"};
  padding: calc(var(--base-spacing) * 2);
  text-align: ${size.isSmUp ? "left" : "center"};
`
);

const Title = styled.div(
    () => `
  font-size: 48px;
    font-weight: 600;
    color: var(--color-primary-50);
    `
);

const Description = styled.div(
    () => `
      font-size: 22px;
      color: var(--color-white);
`
);

const Tag = styled.div(
    () => `
  display: grid;
  place-content: center;
  width: 100px;
  height: 100px;
  font-size: 48px;
  color: white;
  background: var(--color-danger-500);
  border-radius: 100vw;
`
);
