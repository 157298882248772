import { Button } from "../components/form/button";
import { UserCircles } from "../components/user-circles";
import { PageHeaderRow } from "../components/layout/page-header-row";
import { Size, useWindowSize } from "../hooks/window-size";
import {
    faEllipsisVertical,
    faFilter,
    faMultiply,
    faSearch,
    faMinus,
    faCheck,
    faRefresh,
    faArrowLeft,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Chip } from "../components/chip";
import { Input } from "../components/form/input";
import { ChartCohortOverYear } from "../components/chart/chart-cohort-over-year";
import { Checkbox } from "../components/form/checkbox";
import { TableToolbar } from "../components/table/table-toolbar";
import { ChartToolbar } from "../components/chart/chart-toolbar";
import { IVacancy } from "../models/vacancy";
import { BodyWrapper } from "../components/layout/body-wrapper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../stores/hooks";
import { Serie } from "@nivo/line";
import { aggregateByPropGroup } from "../utils/utils";
import { Dropdown } from "../components/dropdown";
import { loadVacancies } from "../stores/vacancy/vacancy-actions";
import { loadMonthlyVideoStats } from "../stores/monthly-video-stats/monthly-video-stats-actions";
import { loadMonthlyViewerStats } from "../stores/monthly-viewer-stats/monthly-viewer-stats-actions";
import { Loader } from "../components/loader";
import { IApplication } from "../models/application";
import { BasicUser, User } from "../models/user";
import {
    AGE_TYPES,
    COHORT_OPTIONS,
    ETHNICITY_TYPES,
    GENDER_TYPES,
    IdWithLabel,
    TIME_PERIOD_OPTIONS,
} from "../models/utils";
import { logError } from "../stores/error/error-actions";
import { userSettingsActions } from "../stores/user-settings/user-settings-slice";
import { ChartTotalApplicantsOverTime } from "../components/chart/chart-total-applicants-over-time";
import { ChartViewerTotal } from "../components/chart/chart-viewer-total";
import { ViewerViewTime } from "../models/chart";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Main dashboard page
 * - Shows a list of vacancies specific to the currently viewed company
 */
export const CompanyOverview = React.memo<Props>((_) => {
    const { company } = useAppSelector((state) => state.auth);

    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh
    const PER_PAGE = 10; // Items per page

    // Stores
    const userSettingsState = useAppSelector((state) => state.userSettings);
    const vacancyState = useAppSelector((state) => state.vacancy);
    const applicationState = useAppSelector((state) => state.application);
    const monthlyVideoStatsState = useAppSelector(
        (state) => state.monthlyVideoStats
    );
    const monthlyViewerStatsState = useAppSelector(
        (state) => state.monthlyViewerStats
    );

    const dispatch = useAppDispatch();

    // Window size info
    const size = useWindowSize();

    // Chart data
    const [cohortOverYearChartData, setCohortOverYearChartData] = useState<
        Serie[]
    >([]);

    const [applicantsOverTimeChartData, setApplicantsOverTimeChartData] =
        useState<Serie[]>([]);

    // Selections
    const initialSelection: string[] = [];
    const [selections, setSelections] = useState(initialSelection);

    // Pagination and data
    const [pageBounds, setPageBounds] = useState({ start: 0, end: PER_PAGE });
    // All view data is dupled from the Store. This can be filtered to alter the table
    const [allData, setAllData] = useState<IVacancy[]>([]);
    // This is the actual viewable data on the current page
    const [viewData, setViewData] = useState<IVacancy[]>([]);

    // Chart options
    const [cohortOverYearTargetCohort, setCohortOverYearTargetCohort] =
        useState<string>("ethnicity");

    const [applicantsOverYearTargetPeriod, setApplicantsOverYearTargetPeriod] =
        useState<"year" | "month" | "day">("year");

    const [cohortMinutesTargetEthnicity, setCohortMinutesTargetEthnicity] =
        useState<string>("asian");

    const [cohortMinutesTargetGender, setCohortMinutesTargetGender] =
        useState<string>("female");

    const [cohortMinutesTargetAge, setCohortMinutesTargetAge] =
        useState<string>("18-24");

    const [
        totalViewerTimeChartDataEthnicity,
        setTotalViewerTimeChartDataEthnicity,
    ] = useState<ViewerViewTime[]>([]);
    const [
        totalViewerTimeChartDataEthnicity_Total,
        setTotalViewerTimeChartDataEthnicity_Total,
    ] = useState<number>(0);
    const [totalViewerTimeChartDataGender, setTotalViewerTimeChartDataGender] =
        useState<ViewerViewTime[]>([]);
    const [
        totalViewerTimeChartDataGender_Total,
        setTotalViewerTimeChartDataGender_Total,
    ] = useState<number>(0);
    const [totalViewerTimeChartDataAge, setTotalViewerTimeChartDataAge] =
        useState<ViewerViewTime[]>([]);
    const [
        totalViewerTimeChartDataAge_Total,
        setTotalViewerTimeChartDataAge_Total,
    ] = useState<number>(0);

    // Filters and search
    const [searchWords, setSearchWords] = useState<string[]>([]);
    const [activeFilters, setActiveFilters] = useState<IdWithLabel[]>([]);

    const AVAILABLE_FILTERS: IdWithLabel[] = useMemo(
        () => [
            { id: "status:active", label: "Active" },
            { id: "status:closed", label: "Closed" },
            { id: "with-applications", label: "With applications" },
            { id: "listed-today", label: "Listed today" },
            { id: "listed-this-week", label: "Listed this week" },
            { id: "listed-this-month", label: "Listed this month" },
        ],
        []
    );

    /**
     * Go to a specific start index
     */
    const goToPageIndex = useCallback(
        (startIndex: number) => {
            setPageBounds({
                start: startIndex,
                end: startIndex + userSettingsState.perPage,
            });
        },
        [userSettingsState.perPage]
    );

    /**
     * Filters the view data based on search field input and active filters
     */
    useEffect(() => {
        if (!vacancyState.vacancies) return;
        let filteredViewData = [...vacancyState.vacancies];

        try {
            // Start by filtering based on search input.
            searchWords.forEach((word) => {
                // Convert to lower case and trim whitespace
                const _word = word.toLowerCase().trim();

                // Find anything that matches across various props - use the store data here
                const matches = filteredViewData.filter(
                    (j) =>
                        j.title.toLowerCase().indexOf(_word) > -1 ||
                        j.description.toLowerCase().indexOf(_word) > -1 ||
                        j.contractType.toLowerCase().indexOf(_word) > -1 ||
                        j.industry.toLowerCase().indexOf(_word) > -1 ||
                        j.remoteWorking.toLowerCase().indexOf(_word) > -1 ||
                        j.tags.map((t) => t.toLowerCase()).indexOf(_word) > -1
                );

                // If the current resultset is 0 length, push in matches
                // Else we should start to remove the filtered dataset if they don't match the next word iteration
                // This is effectively an "AND" operation. Each word MUST be found to show in the filtered results.
                // I.e. "full time, remote" will show all those results that contain "Full time" AND "remote"
                filteredViewData =
                    filteredViewData.length === 0
                        ? [...matches]
                        : [
                              ...filteredViewData.filter(
                                  (x) =>
                                      matches.map((m) => m.id).indexOf(x.id) >
                                      -1
                              ),
                          ];
            });
        } catch (ex) {
            console.error("Failed to perform search filter operation");
            dispatch(logError(ex));
        }

        // Now perform filters
        try {
            // Perform the actual filtering operation
            activeFilters.forEach((filter) => {
                switch (filter.id) {
                    case "status:active":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "active"
                        );
                        break;
                    case "status:closed":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.status.toLowerCase() === "closed"
                        );
                        break;
                    case "with-applications":
                        filteredViewData = filteredViewData.filter(
                            (x) => x.applications.length > 0
                        );
                        break;
                    case "listed-today":
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).toDateString() ===
                                new Date().toDateString()
                        );
                        break;
                    case "listed-this-week":
                        const now = new Date();
                        const startOfWeek = new Date(
                            new Date(
                                now.setDate(now.getDate() - now.getDay() + 1)
                            ).toDateString()
                        );
                        const endOfWeek = new Date(
                            new Date(
                                new Date(
                                    now.setDate(
                                        now.getDate() - now.getDay() + 8
                                    )
                                ).toDateString()
                            ).valueOf() - 1
                        );
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).valueOf() >=
                                    startOfWeek.valueOf() &&
                                new Date(x.createdAt).valueOf() <=
                                    endOfWeek.valueOf()
                        );
                        break;
                    case "listed-this-month":
                        const thisMonth = new Date().getMonth();
                        filteredViewData = filteredViewData.filter(
                            (x) =>
                                new Date(x.createdAt).getMonth() === thisMonth
                        );
                        break;
                    default:
                        throw new Error("Unknown filter");
                }
            });
        } catch (ex) {
            console.error("Failed to perform filter operation");
            dispatch(logError(ex));
        }

        // Set view data and repaginate
        setAllData(filteredViewData);
        goToPageIndex(0);
        // View data and selections will update automatically due to the useEffect hook
    }, [
        dispatch,
        vacancyState.vacancies,
        searchWords,
        activeFilters,
        goToPageIndex,
    ]);

    /**
     * Gets new data from all data sources on the page
     * Used by the refresh button
     */
    const getAllData = useCallback(async () => {
        if (!company) return;
        await Promise.allSettled([
            dispatch(loadVacancies(company.id)),
            dispatch(loadMonthlyVideoStats()),
            dispatch(loadMonthlyViewerStats()),
        ]);
    }, [company, dispatch]);

    /**
     * Calculates the Cohort Over Year (line) chart data
     */
    useEffect(() => {
        // Get the data from the store. This data needs transposing to Serie[] in order to be plotted on the chart
        // We have a little work to do here
        const stats = [...monthlyVideoStatsState.stats];

        // Get our possible values for x (time)
        // We'll use these when creating our plot data - there is no guarantee our actual data contains all possible months so we want them here
        const possibleMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        // Get each unique cohort for the selected target cohort group from the actual data.
        const possibleCohorts: string[] = Array.from(
            new Set(stats.map((s) => s[cohortOverYearTargetCohort]))
        )
            .sort()
            .reverse();

        // Group our data by month and the target cohort, and aggregate by playTime
        // Cast to a union type to make TypeScript happy - this type is variable depending on the output of `aggregateByPropGroup` - check the annotations for more
        const groups = aggregateByPropGroup(
            stats,
            ["month", cohortOverYearTargetCohort],
            // We might be adding totals to this chart to compare against - do it like this
            // ['playTime', 'recordedDuration'],
            ["playTime"]
        ) as ({ month: number; playTime: number; recordedDuration: number } & {
            [key: string]: string;
        })[];

        const newCohortOverYearChartData = possibleCohorts.map((c: string) => {
            return {
                id: c.toString(),
                data: possibleMonths.map((m) => {
                    return {
                        x: `2023-${m}-01`,
                        y: groups
                            .map((g) => {
                                return g.month === m &&
                                    g[cohortOverYearTargetCohort].toString() ===
                                        c.toString()
                                    ? g.playTime
                                    : 0;
                            })
                            .reduce((a: number, c: number) => a + c),
                        // We can pass the total to the chart like this
                        // t: groups
                        //   .map((g) => {
                        //     return g.month === m &&
                        //       g[cohortOverYearTargetCohort].toString() === c.toString()
                        //       ? g.recordedDuration ?? 0
                        //       : 0
                        //   })
                        //   .reduce((a: number, c: number) => a + c),
                    };
                }),
            };
        });
        setCohortOverYearChartData(newCohortOverYearChartData);
    }, [monthlyVideoStatsState.stats, cohortOverYearTargetCohort]);

    /**
     * Calculates the "Total Applicants over time (line)" chart data
     */
    useEffect(() => {
        let res: any[] = [];
        const applications = allData.map((vacancy, i) => vacancy.applications);

        switch (applicantsOverYearTargetPeriod) {
            case "year":
                {
                    for (let i = 1; i < 13; i++) {
                        res[i - 1] = {
                            x: `${new Date().getFullYear()}-${i}-01`,
                            y: 0,
                        };
                    }

                    applications.forEach((application) =>
                        // Filter out anything that isn't from this year
                        application
                            .filter(
                                (x) =>
                                    new Date(x.createdAt).getFullYear() ===
                                    new Date().getFullYear()
                            )
                            .forEach((applicant) => {
                                const createDate = new Date(
                                    applicant.createdAt
                                );
                                res[createDate.getMonth()].y += 1;
                            })
                    );

                    const applicantsOverYear: Serie[] = [
                        {
                            id: "Applicants",
                            data: res,
                        },
                    ];

                    setApplicantsOverTimeChartData(applicantsOverYear);
                }
                break;
            case "month":
                {
                    const now = new Date();
                    const currentYear = now.getFullYear();
                    const currentMonth = now.getMonth() + 1;
                    const daysInMonth = new Date(
                        currentYear,
                        currentMonth,
                        0
                    ).getDate();

                    for (let i = 1; i <= daysInMonth; i++) {
                        res[i - 1] = {
                            x: `${new Date().getFullYear()}-${currentMonth}-${i}`,
                            y: 0,
                        };
                    }

                    applications.forEach((application) =>
                        // Filter out anything that isn't from this year
                        application
                            .filter((x) => {
                                const createdAt = new Date(x.createdAt);
                                return (
                                    createdAt.getFullYear() === currentYear &&
                                    createdAt.getMonth() + 1 === currentMonth
                                );
                            })
                            .forEach((applicant) => {
                                const createDate = new Date(
                                    applicant.createdAt
                                );

                                res[createDate.getDate()].y += 1;
                            })
                    );

                    const applicantsOverMonth: Serie[] = [
                        {
                            id: "Applicants",
                            data: res,
                        },
                    ];

                    setApplicantsOverTimeChartData(applicantsOverMonth);
                }
                break;
            case "day":
                {
                    const now = new Date();
                    const currentYear = now.getFullYear();
                    const currentMonth = now.getMonth() + 1;
                    const currentDate = now.getDate();

                    for (let i = 0; i < 24; i++) {
                        res[i] = {
                            x: `${i}`,
                            y: 0,
                        };
                    }

                    applications.forEach((application) =>
                        // Filter out anything that isn't from this year
                        application
                            .filter((x) => {
                                const createdAt = new Date(x.createdAt);
                                return (
                                    createdAt.getFullYear() === currentYear &&
                                    createdAt.getMonth() + 1 === currentMonth &&
                                    createdAt.getDate() === currentDate
                                );
                            })
                            .forEach((applicant) => {
                                const createDate = new Date(
                                    applicant.createdAt
                                );
                                const h = createDate.getHours();
                                res[h].y += 1;
                            })
                    );

                    const applicantsOverDay: Serie[] = [
                        {
                            id: "Applicants",
                            data: res,
                        },
                    ];

                    setApplicantsOverTimeChartData(applicantsOverDay);
                }
                break;
        }
    }, [allData, viewData, applicantsOverYearTargetPeriod]);

    /**
     * Page forward
     */
    const onPageForward = useCallback(() => {
        setPageBounds({
            start: pageBounds.start + userSettingsState.perPage,
            end: pageBounds.end + userSettingsState.perPage,
        });
    }, [userSettingsState.perPage, pageBounds.end, pageBounds.start]);

    /**
     * Page back
     */
    const onPageBack = useCallback(() => {
        if (pageBounds.start < userSettingsState.perPage) {
            setPageBounds({ start: 0, end: userSettingsState.perPage });
        } else {
            setPageBounds({
                start: pageBounds.start - userSettingsState.perPage,
                end: pageBounds.start,
            });
        }
    }, [pageBounds.start, userSettingsState.perPage]);

    /**
     * Toggle selection of a single row within the table
     * If all are unselected, fires an unselection event to the select all checkbox
     */
    const onSelect = (event: React.FormEvent<HTMLInputElement>, id: string) => {
        // Perform the selection and set state
        let newSelection = [...selections];
        if ((event.target as HTMLInputElement).checked) {
            newSelection.push(id);
            setSelections(newSelection);
        } else {
            newSelection.splice(selections.indexOf(id), 1);
            setSelections(newSelection);
        }
    };

    /**
     * Toggle selection of all rows within the table
     */
    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Selection is based on current individual selections, rather than the checkbox state itself
        // If clicked and all items are currently selected, deselect all
        // If clicked and some or none of the items are currently selected, select all
        const newSelection =
            selections.length < allData.length
                ? allData.map((vacancy) => vacancy.id)
                : [];
        setSelections(newSelection);
    };

    /**
     * Function to return only the selections that are visible based on viewdata
     * Should be called by methods that perform an action based on the selection
     * @returns number of visible selections
     */
    const visibleSelections = () => {
        // Hide any selections that are no longer visible due to filtering
        return selections.filter(
            (s) => allData.map((d) => d.id).indexOf(s) > -1
        );
    };

    /**
     * Handler to set filters after selection within dropdown
     */
    const onSelectFilter = useCallback(
        (selection: string, remove: boolean = false) => {
            const currentFilters = [...activeFilters];
            const selectedFilter = AVAILABLE_FILTERS.find(
                (x) => x.id === selection
            );

            let filters: IdWithLabel[] = [];
            if (remove && selectedFilter) {
                // Remove
                filters = currentFilters.filter(
                    (x) => x.id !== selectedFilter.id
                );
            } else if (
                selectedFilter &&
                currentFilters.filter((x) => x.id === selectedFilter.id)
                    .length === 0
            ) {
                // Add - only if not already in here. UI should stop this but good idea to check here too
                filters = [...currentFilters, selectedFilter];
            }
            setActiveFilters(filters);
        },
        [activeFilters, AVAILABLE_FILTERS]
    );

    /**
     * Handler for search input - set state and call filter
     */
    const onSearchInput = (event: any) => {
        const words = event.target.value.split(",");
        setSearchWords(words);
    };

    /**
     * Sets the target cohort for the "Cohort over year" chart (line chart)
     */
    const onChangeCohortOverYearCohort = (selection: string) => {
        if (selection !== cohortOverYearTargetCohort)
            setCohortOverYearChartData([]);

        setCohortOverYearTargetCohort(selection);
    };

    /**
     * Sets the target cohort for the "Applicants over time" chart (line chart)
     */
    const onChangeApplicantsOverYearCohort = (selection: string) => {
        // Because the target chart switches between plotting time and linear we need to clear the data down before we change the target period
        // Failure to do this results in the chart rerendering before the data is recalculated, causing an error
        if (selection !== applicantsOverYearTargetPeriod)
            setApplicantsOverTimeChartData([]);

        setApplicantsOverYearTargetPeriod(
            selection as "year" | "month" | "day"
        );
    };

    /**
     * Sets the target cohort for the "View times for Age" chart (bar chart)
     */
    const onChangeCohortAge = (selection: string) => {
        if (selection !== cohortMinutesTargetAge) {
            setTotalViewerTimeChartDataAge([]);
            setTotalViewerTimeChartDataAge_Total(0);
        }

        setCohortMinutesTargetAge(selection);
    };

    /**
     * Sets the target cohort for the "View times for Ethnicity" chart (bar chart)
     */
    const onChangeCohortEthnicity = (selection: string) => {
        if (selection !== cohortMinutesTargetEthnicity) {
            setTotalViewerTimeChartDataEthnicity([]);
            setTotalViewerTimeChartDataEthnicity_Total(0);
        }

        setCohortMinutesTargetEthnicity(selection);
    };

    /**
     * Sets the target cohort for the "View times for Gender" chart (bar chart)
     */
    const onChangeCohortGender = (selection: string) => {
        if (selection !== cohortMinutesTargetGender) {
            setTotalViewerTimeChartDataGender([]);
            setTotalViewerTimeChartDataGender_Total(0);
        }

        setCohortMinutesTargetGender(selection);
    };

    /**
     * Hit the API to get vacancies if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        if (!company) return;
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        // Check vacancy state and reload
        if (
            !vacancyState.companyLoads[company!.id] ||
            vacancyState.companyLoads[company!.id] <= expiryThreshold
        ) {
            console.log(
                "%cVacancy state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadVacancies(company.id)).catch(console.error);
        }
    }, [
        dispatch,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
        vacancyState.companyLoads,
        company,
    ]);

    /**
     * Hit the API to get stats if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;
        if (
            !monthlyVideoStatsState.serverUpdated ||
            monthlyVideoStatsState.serverUpdated <= expiryThreshold
        ) {
            console.log(
                "%cMonthly video stats state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadMonthlyVideoStats()).catch(console.error);
        }
    }, [
        dispatch,
        monthlyVideoStatsState.serverUpdated,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Hit the API to get view stats if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;
        if (
            !monthlyViewerStatsState.serverUpdated ||
            monthlyViewerStatsState.serverUpdated <= expiryThreshold
        ) {
            console.log(
                "%cMonthly view stats state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadMonthlyViewerStats()).catch(console.error);
        }
    }, [
        dispatch,
        monthlyViewerStatsState.serverUpdated,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Gets view time by Age
     */
    useEffect(() => {
        // Filter stats by this month and the desired cohort
        const now = new Date();
        const targetStats = [...monthlyViewerStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.age.toLowerCase() === cohortMinutesTargetAge.toLowerCase()
        );

        // Get unique viewers, ordered alphabetically
        const viewers = targetStats
            .reduce((a: BasicUser[], c) => {
                const exists = a.filter((x) => x.id === c.viewerId).length
                    ? true
                    : false;
                if (!exists)
                    a.push(
                        new BasicUser(c.viewerId, c.viewerName, c.viewerAvatar)
                    );
                return a;
            }, [])
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        // For each viewer, calculate aggregate stats from global store
        const viewStats: ViewerViewTime[] = [];
        viewers.forEach((viewer) => {
            const userStats = targetStats.filter(
                (s) => s.viewerId === viewer.id
            );

            const ageStats = aggregateByPropGroup(
                userStats,
                ["age"],
                ["playTime"]
            ) as {
                age: string;
                playTime: number;
            }[];

            const ageData = ageStats.find(
                (s) =>
                    s.age.toLowerCase() === cohortMinutesTargetAge.toLowerCase()
            );
            if (!ageData) return;

            viewStats.push(
                new ViewerViewTime(viewer.id, viewer.name, ageData.playTime)
            );
        });

        // We want to get total available time to stat this against.
        const videoStats = [...monthlyVideoStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.age.toLowerCase() === cohortMinutesTargetAge.toLowerCase()
        );

        const groups = aggregateByPropGroup(
            videoStats,
            ["age"],
            ["recordedDuration"]
        ) as ({
            recordedDuration: number;
        } & {
            [key: string]: string;
        })[];

        const target = groups.find(
            (g) => g["age"] === cohortMinutesTargetAge.toLowerCase()
        );

        setTotalViewerTimeChartDataAge(viewStats);
        setTotalViewerTimeChartDataAge_Total(target?.recordedDuration ?? 0);
    }, [
        cohortMinutesTargetAge,
        monthlyVideoStatsState.stats,
        monthlyViewerStatsState.stats,
    ]);

    /**
     * Gets total duration by Ethnicity
     */
    useEffect(() => {
        // Filter stats by this month and the desired cohort
        const now = new Date();
        const targetStats = [...monthlyViewerStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.ethnicity.toLowerCase() ===
                    cohortMinutesTargetEthnicity.toLowerCase()
        );

        // Get unique viewers, ordered alphabetically
        const viewers = targetStats
            .reduce((a: BasicUser[], c) => {
                const exists = a.filter((x) => x.id === c.viewerId).length
                    ? true
                    : false;
                if (!exists)
                    a.push(
                        new BasicUser(c.viewerId, c.viewerName, c.viewerAvatar)
                    );
                return a;
            }, [])
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        // For each viewer, calculate aggregate stats from global store
        const viewStats: ViewerViewTime[] = [];
        viewers.forEach((viewer) => {
            const userStats = targetStats.filter(
                (s) => s.viewerId === viewer.id
            );

            const ethnicityStats = aggregateByPropGroup(
                userStats,
                ["ethnicity"],
                ["playTime"]
            ) as {
                ethnicity: string;
                playTime: number;
            }[];

            const ethnicityData = ethnicityStats.find(
                (s) =>
                    s.ethnicity.toLowerCase() ===
                    cohortMinutesTargetEthnicity.toLowerCase()
            );
            if (!ethnicityData) return;

            viewStats.push(
                new ViewerViewTime(
                    viewer.id,
                    viewer.name,
                    ethnicityData.playTime
                )
            );
        });

        // We want to get total available time to stat this against.
        const videoStats = [...monthlyVideoStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.ethnicity.toLowerCase() ===
                    cohortMinutesTargetEthnicity.toLowerCase()
        );
        const groups = aggregateByPropGroup(
            videoStats,
            ["ethnicity"],
            ["recordedDuration"]
        ) as ({
            recordedDuration: number;
        } & {
            [key: string]: string;
        })[];
        const target = groups.find(
            (g) => g["ethnicity"] === cohortMinutesTargetEthnicity.toLowerCase()
        );

        setTotalViewerTimeChartDataEthnicity(viewStats);
        setTotalViewerTimeChartDataEthnicity_Total(
            target?.recordedDuration ?? 0
        );
    }, [
        cohortMinutesTargetEthnicity,
        monthlyVideoStatsState.stats,
        monthlyViewerStatsState.stats,
    ]);

    /**
     * Gets total duration by Gender
     */
    useEffect(() => {
        // Filter stats by this month and the desired cohort
        const now = new Date();
        const targetStats = [...monthlyViewerStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.gender.toLowerCase() ===
                    cohortMinutesTargetGender.toLowerCase()
        );

        // Get unique viewers, ordered alphabetically
        const viewers = targetStats
            .reduce((a: BasicUser[], c) => {
                const exists = a.filter((x) => x.id === c.viewerId).length
                    ? true
                    : false;
                if (!exists)
                    a.push(
                        new BasicUser(c.viewerId, c.viewerName, c.viewerAvatar)
                    );
                return a;
            }, [])
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        // For each viewer, calculate aggregate stats from global store
        const viewStats: ViewerViewTime[] = [];
        viewers.forEach((viewer) => {
            const userStats = targetStats.filter(
                (s) => s.viewerId === viewer.id
            );

            const genderStats = aggregateByPropGroup(
                userStats,
                ["gender"],
                ["playTime"]
            ) as {
                gender: string;
                playTime: number;
            }[];

            const genderData = genderStats.find(
                (s) =>
                    s.gender.toLowerCase() ===
                    cohortMinutesTargetGender.toLowerCase()
            );
            if (!genderData) return;

            viewStats.push(
                new ViewerViewTime(viewer.id, viewer.name, genderData.playTime)
            );
        });

        // We want to get total available time to stat this against.
        const videoStats = [...monthlyVideoStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 &&
                s.year === now.getFullYear() &&
                s.gender.toLowerCase() ===
                    cohortMinutesTargetGender.toLowerCase()
        );

        const groups = aggregateByPropGroup(
            videoStats,
            ["gender"],
            ["recordedDuration"]
        ) as ({
            recordedDuration: number;
        } & {
            [key: string]: string;
        })[];

        const target = groups.find(
            (g) => g["gender"] === cohortMinutesTargetGender.toLowerCase()
        );

        setTotalViewerTimeChartDataGender(viewStats);
        setTotalViewerTimeChartDataGender_Total(target?.recordedDuration ?? 0);
    }, [
        cohortMinutesTargetGender,
        monthlyVideoStatsState.stats,
        monthlyViewerStatsState.stats,
    ]);

    /**
     * Paginated view data - fire when pagination or allData is changed and set the view data
     */
    useEffect(() => {
        setViewData([...allData.slice(pageBounds.start, pageBounds.end)]);
    }, [pageBounds, allData]);

    /**
     * Helper for 3-dot dropdown element
     */
    const dropdownButton = (
        <Button icon={faEllipsisVertical} color="transparent" />
    );

    /**
     * Helper for list of available filter options - used in the JSX return
     */
    const availableFilters = AVAILABLE_FILTERS.filter(
        (x) => activeFilters.map((y) => y.id).indexOf(x.id) === -1
    );

    return (
        <BodyWrapper size={size}>
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">{company?.name} - Overview</h1>
                <div className="grid row centered">
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={async () => await getAllData()}
                        disabled={
                            vacancyState.isLoading ||
                            monthlyVideoStatsState.isLoading
                        }
                    />
                </div>
            </PageHeaderRow>
            <ChartSection className="grid" size={size}>
                <article className="grid column loader-wrapper">
                    {monthlyVideoStatsState.isLoading && (
                        <Loader fillParent={true} />
                    )}

                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            Time Spent by{" "}
                            <span className="text-capitalize">
                                {cohortOverYearTargetCohort}
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeCohortOverYearCohort}
                            options={COHORT_OPTIONS}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartCohortOverYear data={cohortOverYearChartData} />
                </article>
                <article className="grid column loader-wrapper">
                    {monthlyVideoStatsState.isLoading && (
                        <Loader fillParent={true} />
                    )}
                    <ChartToolbar className="grid row centered">
                        <h2 className="text-lg-medium">
                            Total Applicants Over Time{" "}
                            <span className="text-capitalize">
                                ({applicantsOverYearTargetPeriod}):
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeApplicantsOverYearCohort}
                            options={TIME_PERIOD_OPTIONS}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartTotalApplicantsOverTime
                        data={applicantsOverTimeChartData}
                        period={applicantsOverYearTargetPeriod}
                    />
                </article>

                <article className="grid column loader-wrapper">
                    {monthlyViewerStatsState.isLoading && (
                        <Loader fillParent={true} />
                    )}
                    <ChartToolbar className="grid row centered">
                        <h2 className="grid column">
                            <span className="text-lg-medium">
                                View times for Age:{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                    {cohortMinutesTargetAge}
                                </span>
                            </span>
                            <span className="text-xs-normal text-grey-500">
                                (This month)
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeCohortAge}
                            options={AGE_TYPES}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartViewerTotal
                        data={totalViewerTimeChartDataAge}
                        total={totalViewerTimeChartDataAge_Total}
                    />
                </article>

                <article className="grid column loader-wrapper">
                    {monthlyViewerStatsState.isLoading && (
                        <Loader fillParent={true} />
                    )}
                    <ChartToolbar className="grid row centered">
                        <h2 className="grid column">
                            <span className="text-lg-medium">
                                View times for Ethnicity:{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                    {cohortMinutesTargetEthnicity}
                                </span>
                            </span>
                            <span className="text-xs-normal text-grey-500">
                                (This month)
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeCohortEthnicity}
                            options={ETHNICITY_TYPES}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartViewerTotal
                        data={totalViewerTimeChartDataEthnicity}
                        total={totalViewerTimeChartDataEthnicity_Total}
                    />
                </article>

                <article className="grid column loader-wrapper">
                    {monthlyViewerStatsState.isLoading && (
                        <Loader fillParent={true} />
                    )}
                    <ChartToolbar className="grid row centered">
                        <h2 className="grid column">
                            <span className="text-lg-medium">
                                View times for Gender:{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                    {cohortMinutesTargetGender}
                                </span>
                            </span>
                            <span className="text-xs-normal text-grey-500">
                                (This month)
                            </span>
                        </h2>
                        <Dropdown
                            clickableElement={dropdownButton}
                            onOptionSelected={onChangeCohortGender}
                            options={GENDER_TYPES}
                            title="Change charted cohort"
                            positionX="left"
                        />
                    </ChartToolbar>
                    <ChartViewerTotal
                        data={totalViewerTimeChartDataGender}
                        total={totalViewerTimeChartDataGender_Total}
                    />
                </article>
            </ChartSection>
            <section className="grid column loader-wrapper">
                {vacancyState.isLoading && <Loader fillParent={true} />}
                <TableToolbar className="grid" isMdUp={size.isMdUp}>
                    <div className="grid row wrap centered">
                        <Dropdown
                            clickableElement={
                                <Button
                                    label="Add Filter"
                                    icon={faFilter}
                                    color="white"
                                    disabled={availableFilters.length === 0}
                                />
                            }
                            onOptionSelected={onSelectFilter}
                            options={availableFilters}
                            title="Filters"
                            positionX="right"
                            isDisabled={availableFilters.length === 0}
                        />

                        {activeFilters.map((filter) => (
                            <Button
                                label={filter.label}
                                icon={faMultiply}
                                color="primary-light"
                                isIconTrailing={true}
                                key={`option_${filter.id}`}
                                onClick={(e) => onSelectFilter(filter.id, true)}
                            />
                        ))}
                    </div>
                    <Input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search"
                        icon={faSearch}
                        style={{ minWidth: "100px" }}
                        onChange={onSearchInput}
                    />
                </TableToolbar>
                <div className="table-wrapper">
                    <table className="has-checkboxes">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox
                                        icon={
                                            visibleSelections().length !==
                                            allData.length
                                                ? faMinus
                                                : faCheck
                                        }
                                        onChange={onSelectAll}
                                        checked={visibleSelections().length > 0}
                                    />
                                </th>
                                <th>Vacancy</th>
                                <th>Status</th>
                                <th className="hidden-sm-down">About</th>
                                <th>Candidates</th>
                                <th>Candidate Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!viewData.length ? (
                                <tr>
                                    <td className="text-center text-grey-500 text-sm-medium">
                                        <div
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                            }}
                                        >
                                            No data
                                        </div>
                                        &nbsp;
                                    </td>
                                </tr>
                            ) : (
                                viewData.map((vacancy, i) => (
                                    <tr key={`vacancy_${vacancy.id}`}>
                                        <td>
                                            <Checkbox
                                                onChange={(e) =>
                                                    onSelect(e, vacancy.id)
                                                }
                                                checked={
                                                    visibleSelections().indexOf(
                                                        vacancy.id
                                                    ) > -1
                                                }
                                            />
                                        </td>
                                        <td>
                                            <p className="text-sm-medium">
                                                <Link
                                                    preventScrollReset={false}
                                                    to={`vacancy/${vacancy.id}`}
                                                >
                                                    {vacancy.title}
                                                </Link>
                                            </p>
                                            <p className="text-grey-500">
                                                {vacancy.contractType} -{" "}
                                                {vacancy.remoteWorking}
                                            </p>
                                        </td>
                                        <td>
                                            <Chip
                                                label={vacancy.statusIndicator.label.toLowerCase()}
                                                color={
                                                    vacancy.statusIndicator
                                                        .cssClass
                                                }
                                            />
                                        </td>
                                        <td className="hidden-sm-down">
                                            <p>{vacancy.title}</p>
                                            <p
                                                className="text-grey-500 truncate"
                                                title={vacancy.description}
                                            >
                                                {vacancy.description}
                                            </p>
                                        </td>
                                        <td>
                                            {applicationState.isLoading ? (
                                                <Loader
                                                    isInline={true}
                                                ></Loader>
                                            ) : (
                                                <UserCircles
                                                    users={vacancy.applications.map(
                                                        (a: IApplication) =>
                                                            a.user as User
                                                    )}
                                                    userRoute={"candidate"}
                                                    moreRoute={`vacancy/${vacancy.id}`}
                                                ></UserCircles>
                                            )}
                                        </td>
                                        <td>
                                            <p>
                                                {
                                                    vacancy.applications.map(
                                                        (a: IApplication) =>
                                                            a.user as User
                                                    ).length
                                                }
                                            </p>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="grid row centered space-between">
                    <div className="grid row centered text-grey-500">
                        <select
                            id="perPage"
                            name="perPage"
                            color="transparent"
                            onChange={(e) =>
                                dispatch(
                                    userSettingsActions.setPerPage(
                                        Number(e.target.value)
                                    )
                                )
                            }
                            value={userSettingsState.perPage}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <label htmlFor="perPage">Per page</label>
                    </div>
                    <span className="text-grey-500">
                        {visibleSelections().length} selected
                    </span>
                    <div className="pagination">
                        <Button
                            label="Previous"
                            icon={faArrowLeft}
                            color="transparent"
                            onClick={(e) => onPageBack()}
                            disabled={pageBounds.start === 0}
                        />
                        <p className="text-primary">
                            {pageBounds.start + 1} -{" "}
                            {pageBounds.end > allData.length
                                ? allData.length
                                : pageBounds.end}{" "}
                            of {allData.length}
                        </p>
                        <Button
                            label="Next"
                            icon={faArrowRight}
                            color="transparent"
                            isIconTrailing={true}
                            onClick={(e) => onPageForward()}
                            disabled={pageBounds.end >= allData.length}
                        />
                    </div>
                </div>
            </section>
        </BodyWrapper>
    );
});

const ChartSection = styled.div<{ size: Size }>(
    ({ size }) => `
      grid-template-columns: ${size.isMdUp ? "1fr 1fr" : "1fr"};
      gap: calc(var(--base-spacing) * 4);
      `
);
