import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { ICandidate } from "../../models/candidate";
import { errorActions } from "../error/error-slice";

import { requestOptions, ROOT_URL } from "../utils";
import { candidateActions } from "./candidate-slice";

/**
 * Loads a specific candidate from the database and adds to store (if not already present)
 * @param id (string) - Target candidate ID
 */
export const loadCandidate = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(candidateActions.loading());
        const response = await fetch(`${ROOT_URL}/user/byId?id=${id}`, {
            ...requestOptions(),
        });
        const data: ICandidate & IApiError = await response.json();
        if (!data.statusCode) {
            data.candidate &&
                dispatch(
                    candidateActions.add([
                        {
                            ...data,
                            candidate: {
                                ...data.candidate,
                            },
                        } as ICandidate,
                    ])
                );
        } else {
            throw new Error((data as IApiError).message);
        }
    } catch (error) {
        const message =
            typeof error === "string"
                ? error
                : "Something went wrong when loading candidate";
        console.error(error);
        dispatch(candidateActions.error(message));
        dispatch(
            errorActions.add([
                {
                    error,
                    message,
                },
            ])
        );
    } finally {
        dispatch(candidateActions.loaded());
    }
};
