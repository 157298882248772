import { IApplication } from "./application";
import { IStatusIndicator, StatusIndicator } from "./status-indicator";

export interface ISalaryRange {
    start: number;
    end: number;
}
export class SalaryRange implements ISalaryRange {
    start: number;
    end: number;

    constructor(start: number, end: number) {
        this.start = start;
        this.end = end;
    }
}

export interface IVacancy {
    id: string;
    title: string;
    description: string;
    industry: string;
    location: string;
    remoteWorking: string;
    contractType: string;
    companyName: string;
    salary: ISalaryRange;
    equityAvailable: boolean;
    showSalary: boolean;
    tags: string[];
    benefits: string[];
    questions: string[];
    createdBy: string;
    companyId: string;
    zeggioToken: string;
    videoURL: string;
    createdAt: Date;
    updatedAt: Date;
    topPick: boolean;
    disabled: boolean;
    status: string;
    statusIndicator: IStatusIndicator;
    applications: IApplication[];
}

export class Vacancy implements IVacancy {
    id: string;
    title: string;
    description: string;
    industry: string;
    location: string;
    remoteWorking: string;
    contractType: string;
    companyName: string;
    salary: ISalaryRange;
    equityAvailable: boolean;
    showSalary: boolean;
    tags: string[];
    benefits: string[];
    questions: string[];
    createdBy: string;
    companyId: string;
    zeggioToken: string;
    videoURL: string;
    createdAt: Date;
    updatedAt: Date;
    topPick: boolean;
    disabled: boolean;
    status: string;

    statusIndicator: IStatusIndicator;
    applications: IApplication[];

    constructor(
        id: string,
        title: string,
        description: string,
        industry: string,
        location: string,
        remoteWorking: string,
        contractType: string,
        companyName: string,
        salary: ISalaryRange,
        equityAvailable: boolean,
        showSalary: boolean,
        tags: string[],
        benefits: string[],
        questions: string[],
        createdBy: string,
        companyId: string,
        zeggioToken: string,
        videoURL: string,
        createdAt: Date,
        updatedAt: Date,
        topPick: boolean,
        disabled: boolean,
        applications: IApplication[]
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.industry = industry;
        this.location = location;
        this.remoteWorking = remoteWorking;
        this.contractType = contractType;
        this.companyName = companyName;
        this.salary = salary;
        this.equityAvailable = equityAvailable;
        this.showSalary = showSalary;
        this.tags = tags;
        this.benefits = benefits;
        this.questions = questions;
        this.createdBy = createdBy;
        this.companyId = companyId;
        this.zeggioToken = zeggioToken;
        this.videoURL = videoURL;
        this.createdAt = new Date(createdAt);
        this.updatedAt = new Date(updatedAt);
        this.topPick = topPick;
        this.disabled = disabled;

        this.status = disabled ? "closed" : "active";
        this.statusIndicator = disabled
            ? new StatusIndicator("closed", "red")
            : new StatusIndicator("active", "green");
        this.applications = applications;
    }
}
