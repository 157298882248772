import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { BodyWrapper } from "../layout/body-wrapper";
import { Loader } from "../loader";
import { useWindowSize } from "../../hooks/window-size";
import { AuthGuard } from "./auth-guard";
import { BlankPage } from "./blank-page";
import ScrollToTop from "../helpers/scroll-to-top";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Root "account" page
 * - For use with pages related to a user's account
 * - Default grey with centered content
 * - Users must be authenticated to access
 */
export const AccountRoot = React.memo<Props>((_) => {
    // Window size info
    const size = useWindowSize();

    // Root loading state
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Loaaaaaad
        setIsLoaded(true);
    }, []);

    return (
        <AuthGuard redirect="/auth/login">
            <ScrollToTop />
            <BlankPage>
                {isLoaded && (
                    <BodyWrapper size={size} style={{ alignSelf: "center" }}>
                        <Outlet></Outlet>
                    </BodyWrapper>
                )}
                {!isLoaded && <Loader />}
            </BlankPage>
        </AuthGuard>
    );
});
