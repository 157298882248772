import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMonthlyVideoStats } from "../../models/monthly-video-stats";
import { IRemoteState } from "../remote-state";

interface IMonthlyVideoStatsState extends IRemoteState {
    stats: IMonthlyVideoStats[];
}

const initialState: IMonthlyVideoStatsState = {
    stats: [],
    isLoading: false,
};

/**
 * Monthly video stats store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const monthlyVideoStatsSlice = createSlice({
    name: "monthlyVideoStats",
    initialState,
    reducers: {
        updated: (state) => {
            state.serverUpdated = new Date().valueOf();
        },
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        add: (state, action: PayloadAction<IMonthlyVideoStats[]>) => {
            state.stats = action.payload;
        },
    },
});

export const monthlyVideoStatsActions = monthlyVideoStatsSlice.actions;
export default monthlyVideoStatsSlice.reducer;
