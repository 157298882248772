import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../loader";
import { BlankPage } from "./blank-page";
import ScrollToTop from "../helpers/scroll-to-top";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Root component for auth related pages
 * - Animated primary background with centered content
 * - Users do *not* need to be authenticated to access
 */
export const AuthRoot = React.memo<Props>((_) => {
    // Root loading state
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Loaaaaaad
        setIsLoaded(true);
    }, []);

    return (
        <>
            <ScrollToTop />
            <Background className="animated gradient-background" />
            <BlankPage backgroundColor="transparent" className="auth-root">
                <Logo src="/logo.png"></Logo>
                {isLoaded && <Outlet></Outlet>}
                {!isLoaded && <Loader />}
            </BlankPage>
        </>
    );
});

const Logo = styled.img(
    () => `
  position: absolute;
  top: 30px;
  left: 30px;
  height: 30px;
  width: 30px;
  margin-bottom: 12px;
`
);

// Ensures background animation works on all viewport sizes, applying to BlankPage breaks on tiny-height viewports
const Background = styled.div(
    () => `
    position: fixed;
    inset: 0;
    z-index: -1;
  `
);
