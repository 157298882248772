import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplication } from "../../models/application";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";

interface IApplicationState extends IRemoteState {
    applications: IApplication[];
    applicationLoads: { [key: string]: number };
    companyLoads: { [key: string]: number };
}

const initialState: IApplicationState = {
    applications: [],
    applicationLoads: {},
    companyLoads: {},
    isLoading: false,
};

/**
 * Application store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<IApplication>) => {
            const targetIndex = state.applications.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1)
                state.applications[targetIndex] = action.payload;
            const applicationLoads = { ...state.applicationLoads };
            applicationLoads[action.payload.id] = new Date().valueOf();
            state.applicationLoads = applicationLoads;
        },
        add: (state, action: PayloadAction<IApplication[]>) => {
            state.applications = state.applications
                ? [...mergeArrays(state.applications, action.payload)]
                : action.payload;
            const applicationLoads = { ...state.applicationLoads };
            action.payload.forEach((application) => {
                applicationLoads[application.id] = new Date().valueOf();
            });
            state.applicationLoads = applicationLoads;
        },
        remove: (state, action: PayloadAction<IApplication>) => {
            state.applications.splice(
                state.applications.findIndex((i) => i.id === action.payload.id),
                1
            );
        },
        setApplicationsLoaded: (state, action: PayloadAction<string[]>) => {
            const _state = { ...state };
            action.payload.forEach((id) => {
                _state.applicationLoads[id] = new Date().valueOf();
            });
            state.applicationLoads = _state.applicationLoads;
        },
        setCompanyLoaded: (state, action: PayloadAction<string>) => {
            state.companyLoads[action.payload] = new Date().valueOf();
        },
    },
});

export const applicationActions = applicationSlice.actions;
export default applicationSlice.reducer;
