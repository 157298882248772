import styled from "styled-components";

/**
 * Chart "no data" component
 * Positions self centered via absolute positioning
 * Must be contained in `<ChartResponsiveWrapper />`
 */
export const ChartNoData = () => {
    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M8 17c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5c0 .55-.45 1-1 1zm4 0c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v8c0 .55-.45 1-1 1zm4 0c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm2 2H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                />
            </svg>
            <p>No data</p>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    position: absolute;
    inset: 0;
    place-items: center;
    color: var(--color-grey-400);
    font-size: 16px;
    font-weight: 600;
    svg {
        width: 128px;
        position: absolute;
        opacity: 0.1;
    }
`;
