import React, { useEffect, useState } from "react";
import { Sidebar } from "../layout/sidebar";
import { Outlet } from "react-router-dom";
import { Loader } from "../loader";
import { AuthGuard } from "./auth-guard";
import styled from "styled-components";
import ScrollToTop from "../helpers/scroll-to-top";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Root dashboard page
 * - Base page for dashboard-style pages
 * - Users must be authenticated
 */
export const DashboardRoot = React.memo<Props>((_) => {
    // Root loading state
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Add any pre-load required actions here.
        setIsLoaded(true);
    }, []);

    return (
        <AuthGuard criteria={[{ value: "EMPLOYER", check: "user.userType" }]}>
            <ScrollToTop />
            <DashboardContainer>
                <Sidebar />
                <BodyContainer className="grid column">
                    {isLoaded && <Outlet></Outlet>}
                    {!isLoaded && <Loader />}
                </BodyContainer>
            </DashboardContainer>
        </AuthGuard>
    );
});

const DashboardContainer = styled.div(
    () => `
    display: grid; 
    grid-template-columns: 82px 1fr;
`
);

export const BodyContainer = styled.div(
    () => `
    gap: calc(var(--base-spacing) * 4);
    background: var(--color-white);
    border-top-left-radius: 40px;
    margin-top: calc(var(--base-spacing) * 1.5);
    min-height: calc(100vh - calc(var(--base-spacing) * 1.5));
    min-height: calc(100dvh - calc(var(--base-spacing) * 1.5));
    overflow-x: hidden;
    `
);
