import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVideoMinutes } from "../../models/application";
import { IRemoteState } from "../remote-state";

interface IVideoMinuteState extends IRemoteState {
    videoMinutes: IVideoMinutes[];
}

const initialState: IVideoMinuteState = {
    videoMinutes: [],
    isLoading: false,
};

/**
 * VideoMinutes store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const videoMinutesSlice = createSlice({
    name: "videoMinutes",
    initialState,
    reducers: {
        updated: (state) => {
            state.serverUpdated = new Date().valueOf();
        },
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        // update: (state, action: PayloadAction<IApplication>) => {
        //   const targetIndex = state.videoMinutes.findIndex(
        //     (x) => x.id === action.payload.id,
        //   )
        //   if (targetIndex > -1) state.videoMinutes[targetIndex] = action.payload
        // },
        add: (state, action: PayloadAction<IVideoMinutes[]>) => {
            state.videoMinutes = action.payload;
        },
        // remove: (state, action: PayloadAction<IApplication>) => {
        //   state.videoMinutes.splice(
        //     state.videoMinutes.findIndex((i) => i.id === action.payload.id),
        //     1,
        //   )
        // },
    },
});

export const videoMinutesActions = videoMinutesSlice.actions;
export default videoMinutesSlice.reducer;
