import React from "react";
import { BodyWrapper } from "../components/layout/body-wrapper";
import { useWindowSize } from "../hooks/window-size";
import ApplicantData from "../components/dashboard/applicant-data";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Dashboard = React.memo<Props>((_) => {
    const size = useWindowSize();

    return (
        <BodyWrapper size={size}>
            <ApplicantData />
        </BodyWrapper>
    );
});
