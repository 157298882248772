import { Candidate, ICandidate } from "./candidate";
import { IStatusIndicator, StatusIndicator } from "./status-indicator";
import { IBasicUser } from "./user";

export interface IApplication {
    id: string;
    vacancyId: string;
    companyId: string;
    candidateId: string;
    answers: IQuestionAnswer[];
    createdAt: Date;
    status: string;
    rightToWork: boolean;
    updatedAt: Date;
    user: ICandidate;
    viewers: IBasicUser[];
    statusIndicator: IStatusIndicator;
}
export class Application implements IApplication {
    id: string;
    vacancyId: string;
    companyId: string;
    candidateId: string;
    answers: IQuestionAnswer[];
    createdAt: Date;
    rightToWork: boolean;
    updatedAt: Date;
    user: Candidate;
    status: string;
    viewers: IBasicUser[];
    statusIndicator: StatusIndicator;

    constructor(
        id: string,
        vacancyId: string,
        companyId: string,
        candidateId: string,
        answers: IQuestionAnswer[],
        createdAt: Date,
        rightToWork: boolean,
        updatedAt: Date,
        user: ICandidate,
        status: string,
        viewers: IBasicUser[]
    ) {
        // Mutate "ACCEPTED" to be "SHORTLISTED". They changed the working but didn't change the DB entry. This is the easiest way of updating this.
        if (status === "ACCEPTED") status = "shortlisted";
        /**
         * New requirement regarding stats, its messy but that's what happens when you shoehorn things in later
         * We want to
         * - If actual provided, show it and hide estimated
         * - It no actual provided and estimated exists, show estimated
         * - It neither exist, show "unknown"
         */
        let stats: any[] = [];
        if (user.candidate?.age) {
            stats = [
                ...stats,
                { name: "age", value: user.candidate?.age, certainty: -1 },
            ];
        } else if (user.candidate?.estimatedAge) {
            stats = [
                ...stats,
                {
                    name: "age",
                    value: `${user.candidate?.estimatedAge} (estimated)`,
                    certainty: -1,
                },
            ];
        } else {
            stats = [
                ...stats,
                {
                    name: "age",
                    value: "Unknown",
                    certainty: -1,
                },
            ];
        }

        if (user.candidate?.ethnicity) {
            stats = [
                ...stats,
                {
                    name: "ethnicity",
                    value: user.candidate?.ethnicity,
                    certainty: -1,
                },
            ];
        } else if (user.candidate?.estimatedEthnicity) {
            stats = [
                ...stats,
                {
                    name: "ethnicity",
                    value: `${user.candidate?.estimatedEthnicity} (estimated)`,
                    certainty: -1,
                },
            ];
        } else {
            stats = [
                ...stats,
                {
                    name: "ethnicity",
                    value: "Unknown",
                    certainty: -1,
                },
            ];
        }

        if (user.candidate?.gender) {
            stats = [
                ...stats,
                {
                    name: "gender",
                    value: user.candidate?.gender,
                    certainty: -1,
                },
            ];
        } else if (user.candidate?.estimatedGender) {
            stats = [
                ...stats,
                {
                    name: "gender",
                    value: `${user.candidate?.estimatedGender} (estimated)`,
                    certainty: -1,
                },
            ];
        } else {
            stats = [
                ...stats,
                {
                    name: "gender",
                    value: "Unknown",
                    certainty: -1,
                },
            ];
        }

        const formattedUser = new Candidate(
            user.id,
            user.name,
            user.candidate?.avatar || user.avatar,
            user.email,
            user.emailVerified,
            user.userType,
            user.candidate?.companySize,
            user.candidate?.cv,
            user.candidate?.description,
            user.candidate?.importantThings,
            user.candidate?.industries,
            user.candidate?.preferredRoles,
            user.candidate?.salaryExpectation,
            user.candidate?.skills,
            user.candidate?.videos,
            user.candidate?.age,
            user.candidate?.ethnicity,
            user.candidate?.gender,
            user.candidate?.estimatedAge,
            user.candidate?.estimatedEthnicity,
            user.candidate?.estimatedGender,
            user.candidate?.experience,
            user.candidate?.educationLevel,
            user.candidate?.baseFilename,
            user.candidate?.scanFilename,
            stats
        );
        this.id = id;
        this.vacancyId = vacancyId;
        this.companyId = companyId;
        this.candidateId = candidateId;
        this.answers = answers;
        this.createdAt = createdAt;
        this.rightToWork = rightToWork;
        this.updatedAt = updatedAt;
        this.user = formattedUser;
        this.status = status;
        this.statusIndicator = new StatusIndicator(
            status,
            status.toLowerCase() === "shortlisted"
                ? "green"
                : status.toLowerCase() === "rejected"
                ? "red"
                : status.toLowerCase() === "applied"
                ? "primary"
                : "grey"
        );
        this.viewers = viewers;
    }
}

export interface IQuestionAnswer {
    question: string;
    answer: string;
    videoDuration?: number;
}

export class VideoMinutesId {
    age: string;
    gender: string;
    ethnicity: string;
    companyId: string;

    constructor(
        age: string,
        gender: string,
        ethnicity: string,
        companyId: string
    ) {
        this.age = age;
        this.gender = gender;
        this.ethnicity = ethnicity;
        this.companyId = companyId;
    }
}

export interface IVideoMinutes {
    _id: VideoMinutesId;
    videoDuration: number;
}

export class VideoMinutes implements IVideoMinutes {
    _id: VideoMinutesId;
    videoDuration: number;

    constructor(_id: VideoMinutesId, videoDuration: number) {
        this._id = _id;
        this.videoDuration = videoDuration;
    }
}
