import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";

import { monthlyVideoStatsActions } from "./monthly-video-stats-slice";
import { requestOptions, ROOT_URL } from "../utils";
import {
    IDBMonthlyVideoStats,
    MonthlyVideoStats,
} from "../../models/monthly-video-stats";
import { errorActions } from "../error/error-slice";

const START_CUTOFF_DATE = process.env.REACT_APP_START_CUTOFF_DATE;

/**
 * Loads stats from the database and updates state
 */
export const loadMonthlyVideoStats = () => async (dispatch: Dispatch) => {
    try {
        dispatch(monthlyVideoStatsActions.loading());

        const response = await fetch(
            `${ROOT_URL}/company/myMonthlyVideoStats`,
            requestOptions()
        );
        const data: IDBMonthlyVideoStats[] & IApiError = await response.json();
        if (!data.statusCode) {
            const stats = (
                START_CUTOFF_DATE
                    ? data.filter(
                          (s) =>
                              new Date(
                                  s._id.year,
                                  s._id.month - 1,
                                  1
                              ).valueOf() >
                              new Date(START_CUTOFF_DATE).valueOf()
                      )
                    : data
            ).map((dbStat) => {
                return new MonthlyVideoStats(
                    dbStat._id.year,
                    dbStat._id.month,
                    dbStat._id.companyId,
                    dbStat._id.age,
                    dbStat._id.gender,
                    dbStat._id.ethnicity,
                    dbStat.views,
                    dbStat.activeTime,
                    dbStat.playTime,
                    dbStat.blurTime,
                    dbStat.volumeTime,
                    dbStat.skippedSeconds,
                    dbStat.recordedDuration
                );
            });
            dispatch(monthlyVideoStatsActions.add(stats));
            dispatch(monthlyVideoStatsActions.updated());
        } else {
            throw new Error((data as IApiError).message);
        }
    } catch (error) {
        console.error(error);
        const message =
            typeof error === "string"
                ? error
                : "Something went wrong when loading monthly video stats";
        dispatch(monthlyVideoStatsActions.error(message));
        dispatch(
            errorActions.add([
                {
                    error,
                    message,
                },
            ])
        );
    } finally {
        dispatch(monthlyVideoStatsActions.loaded());
    }
};
