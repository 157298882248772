import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/form/button";
import { UserAvatar } from "../components/user-avatar";
import { logOutUser } from "../stores/auth/auth-actions";
import { useAppDispatch, useAppSelector } from "../stores/hooks";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Main account page
 */
export const Account = React.memo<Props>((props) => {
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /**
     * Log out
     */
    const onGoBack = () => {
        navigate("/");
    };

    /**
     * Log out
     */
    const onLogOut = () => {
        dispatch(logOutUser());
    };

    return (
        <div className="min-width grid column centered gap-lg">
            <UserAvatar
                image={user?.avatar}
                size={"180px"}
                shadow={true}
            ></UserAvatar>
            <div className="grid column centered">
                <h1>{user?.name}</h1>
                <p>{user?.email}</p>
            </div>
            <div className="grid row centered">
                <Button onClick={onGoBack} label={"Home"} />
                <Button onClick={onLogOut} label={"Log out"} />
            </div>
        </div>
    );
});
