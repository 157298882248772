import { faBan } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../components/form/button";
import { Icon } from "../components/icon";
import { BlankPage } from "../components/roots/blank-page";
import { Size, useWindowSize } from "../hooks/window-size";
import { logOutUser } from "../stores/auth/auth-actions";
import { useAppDispatch } from "../stores/hooks";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Access Denied page
 */
export const AccessDenied = React.memo<Props>((props) => {
    // Window size info
    const size = useWindowSize();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <BlankPage className="animated gradient-background">
            <Container size={size}>
                <Tag>
                    <Icon icon={faBan} />
                </Tag>
                <div className="grid column gap-md">
                    <div className="grid column">
                        <Title>Access Denied</Title>
                        <Description>
                            You don't have access to that resource.
                        </Description>
                    </div>
                    <div className="grid row gap-md">
                        <Button
                            onClick={async () => {
                                dispatch(logOutUser());
                                navigate("/auth/login");
                            }}
                            label={"Log out"}
                            className="large column centered"
                        />
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                            label={"Go back"}
                            isOutline={true}
                            className="large column centered"
                        />
                    </div>
                </div>
            </Container>
        </BlankPage>
    );
});

const Container = styled.div<{ size: Size }>(
    ({ size }) => `
  display: grid;
  gap: calc(var(--base-spacing) * 4);
  grid-template-columns: ${size.isSmUp ? "auto 1fr" : "1fr"};
  align-items: center;
  justify-items: ${size.isSmUp ? "flex-start" : "center"};
  padding: calc(var(--base-spacing) * 2);
  text-align: ${size.isSmUp ? "left" : "center"};
`
);

const Title = styled.div(
    () => `
    font-size: 48px;
    font-weight: 600;
    color: var(--color-primary-50);
    `
);

const Description = styled.div(
    () => `
      font-size: 22px;
      color: var(--color-white);
`
);

const Tag = styled.div(
    () => `
  display: grid;
  place-content: center;
  width: 100px;
  height: 100px;
  font-size: 48px;
  color: white;
  background: var(--color-danger-500);
  border-radius: 100vw;
`
);
