import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserSettingsState {
    perPage: number; // View per page  setting
    dataExpiry: number; // Remote data source considered expired after x minutes
    useEstimated: boolean; // Whether to use estimated stats in various calculations
}

export const initialState: IUserSettingsState = {
    perPage: 10,
    dataExpiry: 15,
    useEstimated: true,
};

/**
 * User settings store
 * Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState,
    reducers: {
        setPerPage: (state, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        },
        setDataExpiry: (state, action: PayloadAction<number>) => {
            state.dataExpiry = action.payload;
        },
        setUseEstimated: (state, action: PayloadAction<boolean>) => {
            state.useEstimated = action.payload;
        },
    },
});

export const userSettingsActions = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
