import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppError, AppError } from "../../models/app-error";

interface IErrorState {
    errors: IAppError[];
}

const initialState: IErrorState = {
    errors: [],
};

export type ErrorPayload = {
    error: any;
    message: string;
};
/**
 * Errors store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        /**
         *
         * @param state - injected by dispatch
         * @param action - error object(s) to be added
         */
        add: (state, action: PayloadAction<ErrorPayload[]>) => {
            console.log(
                `%cError`,
                `background-color: red; color: white`,
                `Adding ${action.payload.length} errors to store`
            );

            // Instantiate an AppError object for each error given
            const errors = action.payload.map(
                (error: ErrorPayload, index: number): AppError => {
                    return new AppError(error.error, error.message);
                }
            );

            // If length of local store would exceed 100, start again.
            // We'll lose the old logs but we don't want this to be enormous. 100 should be more than enough
            if (state.errors.length > 100 - errors.length) {
                state.errors = [...errors];
            } else {
                state.errors = [...state.errors, ...errors];
            }
        },
    },
});

export const errorActions = errorSlice.actions;
export default errorSlice.reducer;
