import { Dispatch } from "@reduxjs/toolkit";
import { IApiError } from "../../models/api";
import { Application } from "../../models/application";
import { IVacancy, Vacancy } from "../../models/vacancy";
import { vacancyActions } from "./vacancy-slice";
import { applicationActions } from "../application/application-slice";
import { candidateActions } from "../candidate/candidate-slice";
import { requestOptions, ROOT_URL } from "../utils";
import { errorActions } from "../error/error-slice";

const START_CUTOFF_DATE = process.env.REACT_APP_START_CUTOFF_DATE;

/**
 * Loads vacancies for a companyfrom the database and updates state
 * @param companyId - the company Id to query
 */
export const loadVacancies =
    (companyId: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(vacancyActions.loading());

            const response = await fetch(
                `${ROOT_URL}/company/vacancy/byMyCompanyWithApplications`,
                requestOptions()
            );
            const data: IVacancy[] & IApiError = await response.json();
            if (!data.statusCode) {
                const vacancies = data
                    .filter(
                        (v) =>
                            new Date(v.createdAt).valueOf() >
                            (START_CUTOFF_DATE
                                ? new Date(START_CUTOFF_DATE).valueOf()
                                : 0)
                    )
                    .map((v) => {
                        return new Vacancy(
                            v.id,
                            v.title,
                            v.description,
                            v.industry,
                            v.location,
                            v.remoteWorking,
                            v.contractType,
                            v.companyName,
                            v.salary,
                            v.equityAvailable,
                            v.showSalary,
                            v.tags,
                            v.benefits,
                            v.questions,
                            v.createdBy,
                            v.companyId,
                            v.zeggioToken,
                            v.videoURL,
                            new Date(v.createdAt),
                            new Date(v.updatedAt),
                            v.topPick,
                            v.disabled,
                            v.applications
                                .filter((a) => a.user && a.user.candidate)
                                .map((a) => {
                                    return new Application(
                                        a.id,
                                        a.vacancyId,
                                        a.companyId,
                                        a.candidateId,
                                        a.answers,
                                        new Date(a.createdAt),
                                        a.rightToWork,
                                        new Date(a.updatedAt),
                                        a.user,
                                        a.status,
                                        []
                                    );
                                })
                        );
                    });

                dispatch(vacancyActions.add(vacancies));
                dispatch(vacancyActions.setCompanyLoaded(companyId));
                const applications = vacancies.flatMap(
                    (vacancy) => vacancy.applications
                );
                dispatch(applicationActions.add(applications));
                const candidates = vacancies.flatMap((vacancy) =>
                    vacancy.applications.map((application) => application.user)
                );
                dispatch(candidateActions.add(candidates));
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when loading vacancies";
            dispatch(vacancyActions.error(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        } finally {
            dispatch(vacancyActions.loaded());
        }
    };

/**
 * Loads a specific vacancy from the database and adds to store (if not already present)
 * @param id (string) - Target vacancy ID
 */
export const loadVacancy = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(vacancyActions.loading());
        const response = await fetch(
            `${ROOT_URL}/company/vacancy/byIdWithApplications?id=${id}`,
            requestOptions()
        );
        const data: IVacancy & IApiError = await response.json();
        if (!data.statusCode) {
            const vacancy = new Vacancy(
                data.id,
                data.title,
                data.description,
                data.industry,
                data.location,
                data.remoteWorking,
                data.contractType,
                data.companyName,
                data.salary,
                data.equityAvailable,
                data.showSalary,
                data.tags,
                data.benefits,
                data.questions,
                data.createdBy,
                data.companyId,
                data.zeggioToken,
                data.videoURL,
                new Date(data.createdAt),
                new Date(data.updatedAt),
                data.topPick,
                data.disabled,
                data.applications
                    .filter((a) => a.user && a.user.candidate)
                    .map((a) => {
                        return new Application(
                            a.id,
                            a.vacancyId,
                            a.companyId,
                            a.candidateId,
                            a.answers,
                            new Date(a.createdAt),
                            a.rightToWork,
                            new Date(a.updatedAt),
                            a.user,
                            a.status,
                            []
                        );
                    })
            );

            // As this is a unified endpoint, update our other stores for candidates and applications
            dispatch(vacancyActions.add([vacancy]));

            const applications = vacancy.applications;
            const candidates = vacancy.applications.map(
                (application) => application.user
            );

            dispatch(applicationActions.add(applications));
            dispatch(candidateActions.add(candidates));
        } else {
            throw new Error((data as IApiError).message);
        }
    } catch (error) {
        console.error(error);
        const message =
            typeof error === "string"
                ? error
                : "Something went wrong when loading vacancy";
        dispatch(vacancyActions.error(message));
        dispatch(
            errorActions.add([
                {
                    error,
                    message,
                },
            ])
        );
    } finally {
        dispatch(vacancyActions.loaded());
    }
};
