import { IBasicUser } from "./user";

/**
 * Application View DB interface - exactly as it is in the DB
 */
export interface IApplicationView {
    id: string;
    companyId: string;
    candidateId: string;
    vacancyId: string;
    applicationId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
}

/** Interface combining user */
export interface IApplicationViewWithUser extends IApplicationView {
    user: IBasicUser;
}

/** Class with all implemented interfaces
 * Previously we had a set of classes for base Application View and then extensions
 * However new requirements make it so that we always need all info, so now we have a single class
 */
export class ApplicationView implements IApplicationViewWithUser {
    id: string;
    companyId: string;
    candidateId: string;
    vacancyId: string;
    applicationId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
    user: IBasicUser;

    constructor(
        id: string,
        companyId: string,
        candidateId: string,
        vacancyId: string,
        applicationId: string,
        userId: string,
        createdAt: Date,
        updatedAt: Date,
        user: IBasicUser
    ) {
        this.id = id;
        this.companyId = companyId;
        this.candidateId = candidateId;
        this.vacancyId = vacancyId;
        this.applicationId = applicationId;
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.user = user;
    }
}
