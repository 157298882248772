import React, { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormError } from "../components/form/formError";
import { logInUser } from "../stores/auth/auth-actions";
import { authActions } from "../stores/auth/auth-slice";
import { useAppDispatch, useAppSelector } from "../stores/hooks";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Log in page
 */
export const LogIn = React.memo<Props>((props) => {
    // Navigate
    const navigate = useNavigate();

    // Store
    const authState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    // Form inputs
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();

    /**
     * Reset error state on leave
     */
    useEffect(() => {
        return () => {
            dispatch(authActions.clearErrors());
        };
    }, [dispatch]);

    /**
     * Redirect if already logged in
     */
    useEffect(() => {
        if (authState.user) navigate("/");
    }, [authState.user, navigate]);

    /**
     * Attempt to sign in with email and password
     */
    const onSignIn = (e: FormEvent) => {
        e.preventDefault();
        if (email && password) dispatch(logInUser({ email, password }));
    };

    return (
        <section className="grid gap-lg">
            <header className="grid column">
                <h1 className="text-lg-bold text-uppercase text-primary">
                    Welcome to Includr
                </h1>
                <h2 className="text-sm-medium">
                    Sign in to your account to continue
                </h2>
            </header>
            <form action="#" onSubmit={onSignIn} className="grid gap-md">
                {authState.error && <FormError>{authState.error}</FormError>}
                <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className={"large"}
                />
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className={"large"}
                />
                <input
                    type="submit"
                    value="Sign in"
                    className="grid column centered large"
                />
                <Link to={`/auth/forgot-password`}>Forgot password</Link>
            </form>
        </section>
    );
});
