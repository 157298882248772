import { useState, useEffect } from "react";
import { Breakpoints } from "../styles/responsive";
// Define general type for useWindowSize hook, which includes width and height
export interface Size {
    width: number | undefined;
    height: number | undefined;
    isXs: boolean;
    isSm: boolean;
    isSmUp: boolean;
    isMd: boolean;
    isMdUp: boolean;
    isLg: boolean;
    isLgUp: boolean;
    currentSize: string;
}

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}
// Hook
export function useWindowSize(): Size {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    const width = windowSize.width || 0;

    const isXs = width < Breakpoints.sm - 1;
    const isSm = width > Breakpoints.sm && width < Breakpoints.md - 1;
    const isMd = width > Breakpoints.md && width < Breakpoints.lg - 1;
    const isLg = width > Breakpoints.lg;

    let currentSize = null;
    if (isLg) {
        currentSize = "lg";
    } else if (isMd) {
        currentSize = "md";
    } else if (isSm) {
        currentSize = "sm";
    } else {
        currentSize = "xs";
    }

    return {
        width,
        height: window.innerHeight,
        isXs,
        isSm,
        isSmUp: width > Breakpoints.sm,
        isMd,
        isMdUp: width > Breakpoints.md,
        isLg,
        isLgUp: width > Breakpoints.lg,
        currentSize,
    };
}
