import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useCallback, useState } from "react";
import { Input } from "./input";
import { Button } from "./button";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    icon?: IconProp;
    data: any[];
    handleChange: (value: string) => void;
    updateOnInput?: boolean;
}

/**
 * Form input component
 * - Can be prefixed with an icon
 * - *If an icon is not required, consider using a vanilla `<input />` element* as these are styled the same
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 * - Fires onChange when input changes
 */
export const Autocomplete = React.memo<Props>(
    ({
        label,
        icon,
        type,
        name,
        data,
        required = false,
        handleChange,
        updateOnInput = false,
        style,
        ...rest
    }) => {
        const inputRef = React.createRef<HTMLInputElement>();
        const [options, setOptions] = useState<string[]>([]);

        /**
         * Select handler - fires on change even to parent and sets input
         * @param value
         */
        const onSelect = (value: string) => {
            // Set text input
            if (!inputRef.current) return;
            inputRef.current.value = value;

            // Fire change handler
            handleChange && handleChange(value);

            // Close the panel via setting options to 0
            setOptions([]);
        };

        const onChange = useCallback(
            (value: string) => {
                // Do nothing until we've typed at least 3 characters
                if (value.length < 3) {
                    setOptions([]);
                    handleChange("");
                } else {
                    const options = data.filter(
                        (x) => x.toLowerCase().indexOf(value.toLowerCase()) > -1
                    );
                    setOptions(options);
                    handleChange && updateOnInput && handleChange(value);
                }
            },
            [data, handleChange, updateOnInput]
        );

        return (
            <Container optionsLength={options?.length ?? 0}>
                <Input
                    ref={inputRef}
                    label={label}
                    icon={icon}
                    id={name}
                    onChange={(e) => onChange(e.target.value)}
                    required={required}
                    autoComplete="off"
                    className="large"
                    {...rest}
                />
                {options.length > 0 && (
                    <Options className="grid thin-scrollbar">
                        {options?.map((option, i) => {
                            return (
                                <li key={i}>
                                    <Button
                                        onClick={() => onSelect(option)}
                                        label={option}
                                        className="text-sm-semibold"
                                    />
                                </li>
                            );
                        })}
                    </Options>
                )}
            </Container>
        );
    }
);

const Container = styled.div<{ optionsLength: number }>(
    ({ optionsLength }) => `
  position: relative;

  // :has() lets us fix Safari's issue where :focus-within is lost before the click fires
  // Its support is not yet perfect, so we'll also include :focus-within as a fallback
  :has(button:focus, button:active),
  :focus-within {
    // Set bottom border radii to 0
    ${
        optionsLength > 0
            ? `input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }`
            : ""
    }

    // Show the panel
    div ~ ul {
      display: grid;
    }

    // Change borders of panel and input to stay active
    ul,
    input {
        border-color: var(--color-primary-700);
    }
  }

  input {
    outline: 0;
  }
`
);

const Options = styled.ul`
width: 100%;
list-style: none;
grid-template-columns: 1fr;

position: absolute;
top: 100%;
left: 0;
min-width: 150px;
max-height: min(300px, 33vh);
overflow-y: auto;

padding: var(--base-spacing);
background-color: var(--color-white);
border: 1px solid var(--color-grey-300);
border-top: 0;
background-color: var(--color-white);
border-bottom-left-radius: var(--border-radius);
border-bottom-right-radius: var(--border-radius);
color: var(--color-grey-500);
z-index: 999;

display: none;

button {
    color: var(--color-ash);
    background: transparent;
    border: 0;
    width: 100%;
    text-transform: capitalize;
    text-align: left;
    padding: calc(var(--base-spacing) * 1.5) calc(var(--base-spacing) * 1.5);
}
button:hover {
    background: var(--color-primary-50);
})`;
