import React, { useCallback, useEffect, useState } from "react";
import { BodyWrapper } from "../components/layout/body-wrapper";
import { PageHeaderRow } from "../components/layout/page-header-row";
import { Size, useWindowSize } from "../hooks/window-size";
import { BasicUser } from "../models/user";
import { UserAvatar } from "../components/user-avatar";
import { useAppSelector, useAppDispatch } from "../stores/hooks";
import { DashboardViewer, ViewerViewTimeTotal } from "../models/chart";
import { Button } from "../components/form/button";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { loadMonthlyViewerStats } from "../stores/monthly-viewer-stats/monthly-viewer-stats-actions";
import styled from "styled-components";
import { ageRange, aggregateByPropGroup, uniqueBy } from "../utils/utils";
import { Loader } from "../components/loader";
import { loadMonthlyVideoStats } from "../stores/monthly-video-stats/monthly-video-stats-actions";
import { ChartViewTimeCohort } from "../components/chart/chart-view-time-cohort";
import { ChartToolbar } from "../components/chart/chart-toolbar";
import { Chip } from "../components/chip";
import { loadApplicationViewsByCompanyId } from "../stores/application-view/application-view-actions";
import { loadApplicationsByCompanyId } from "../stores/application/application-actions";
import { ICandidate } from "../models/candidate";
import { IApplicationViewWithUser } from "../models/application-view";
import { loadCompanyUsers } from "../stores/company-user/company-user-actions";
import { ChartNoData } from "../components/chart/chart-no-data";
import { ChartResponsiveWrapper } from "../components/chart/chart-responsive-wrapper";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const HiringManagerOverview = React.memo<Props>((_) => {
    const { company } = useAppSelector((state) => state.auth);

    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh

    // Stores
    const userSettingsState = useAppSelector((state) => state.userSettings);
    const monthlyViewerStatsState = useAppSelector(
        (state) => state.monthlyViewerStats
    );
    const monthlyVideoStatsState = useAppSelector(
        (state) => state.monthlyVideoStats
    );
    const applicationViewState = useAppSelector(
        (state) => state.applicationView
    );
    const applicationState = useAppSelector((state) => state.application);
    const companyUsersState = useAppSelector((state) => state.companyUser);

    const [cohortData, setCohortData] = useState<DashboardViewer[]>([]);
    const [viewData, setViewData] = useState<DashboardViewer[]>([]);

    const dispatch = useAppDispatch();

    /**
     * Gets new data from all data sources on the page
     * Used by the refresh button
     */
    const getAllData = useCallback(() => {
        dispatch(loadMonthlyViewerStats());
    }, [dispatch]);

    /**
     * Hit the API to get company users if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        if (
            !companyUsersState.companyLoads[company!.id] ||
            companyUsersState.companyLoads[company!.id] <= expiryThreshold
        ) {
            console.log(
                "%cCompany user state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadCompanyUsers(company!.id)).catch(console.error);
        }
    }, [
        company,
        companyUsersState.companyLoads,
        dispatch,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Hit the API to get monthly viewer stats if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        if (
            !monthlyViewerStatsState.serverUpdated ||
            monthlyViewerStatsState.serverUpdated <= expiryThreshold
        ) {
            console.log(
                "%cMonthly view stats state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadMonthlyViewerStats()).catch(console.error);
        }
    }, [
        dispatch,
        monthlyViewerStatsState.serverUpdated,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Hit the API to get monthly video stats if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        if (
            !monthlyVideoStatsState.serverUpdated ||
            monthlyVideoStatsState.serverUpdated <= expiryThreshold
        ) {
            console.log(
                "%cMonthly video stats state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadMonthlyVideoStats()).catch(console.error);
        }
    }, [
        dispatch,
        monthlyVideoStatsState.serverUpdated,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Hit the API to get company application views if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        if (
            !applicationViewState.companyLoads[company!.id] ||
            applicationViewState.companyLoads[company!.id] <= expiryThreshold
        ) {
            console.log(
                "%cCompany application view stats state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadApplicationViewsByCompanyId(company!.id)).catch(
                console.error
            );
        }
    }, [
        applicationViewState.companyLoads,
        company,
        dispatch,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Hit the API to get applications this month if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

        if (
            !applicationState.companyLoads[company!.id] ||
            applicationState.companyLoads[company!.id] <= expiryThreshold
        ) {
            console.log(
                "%cCompany applications state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadApplicationsByCompanyId(company!.id)).catch(
                console.error
            );
        }
    }, [
        applicationState.companyLoads,
        company,
        dispatch,
        userSettingsState.dataExpiry,
    ]);

    /**
     * Gets stats for all hiring managers
     */
    useEffect(() => {
        const res: DashboardViewer[] = [];

        // Filter stats by this month
        const now = new Date();
        const viewStats = [...monthlyViewerStatsState.stats].filter(
            (s) =>
                s.month === now.getMonth() + 1 && s.year === now.getFullYear()
        );

        // Get unique viewers, ordered alphabetically
        const viewers = viewStats
            .reduce((a: BasicUser[], c) => {
                const exists = a.filter((x) => x.id === c.viewerId).length
                    ? true
                    : false;
                if (!exists)
                    a.push(
                        new BasicUser(c.viewerId, c.viewerName, c.viewerAvatar)
                    );
                return a;
            }, [])
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        // For each viewer, calculate aggregate stats from global store
        viewers.forEach((viewer) => {
            const userStats = viewStats.filter((s) => s.viewerId === viewer.id);

            const ageStats = aggregateByPropGroup(
                userStats,
                ["age"],
                ["playTime"]
            ) as {
                age: string;
                playTime: number;
            }[];

            const ethnicityStats = aggregateByPropGroup(
                userStats,
                ["ethnicity"],
                ["playTime"]
            ) as {
                ethnicity: string;
                playTime: number;
            }[];

            const genderStats = aggregateByPropGroup(
                userStats,
                ["gender"],
                ["playTime"]
            ) as {
                gender: string;
                playTime: number;
            }[];

            const ageData = ageStats.map((a) => {
                return new ViewerViewTimeTotal(
                    a.age,
                    a.age,
                    [],
                    [a.playTime],
                    [
                        monthlyVideoStatsState.stats
                            .filter(
                                (s) =>
                                    s.month === now.getMonth() + 1 &&
                                    s.year === now.getFullYear() &&
                                    s.age === a.age
                            )
                            .reduce((a, c) => a + c.recordedDuration, 0),
                    ]
                );
            });

            const ethnicityData = ethnicityStats.map(
                (a) =>
                    new ViewerViewTimeTotal(
                        a.ethnicity,
                        a.ethnicity,
                        [],
                        [a.playTime],
                        [
                            monthlyVideoStatsState.stats
                                .filter(
                                    (s) =>
                                        s.month === now.getMonth() + 1 &&
                                        s.year === now.getFullYear() &&
                                        s.ethnicity === a.ethnicity
                                )
                                .reduce((a, c) => a + c.recordedDuration, 0),
                        ]
                    )
            );

            const genderData = genderStats.map(
                (a) =>
                    new ViewerViewTimeTotal(
                        a.gender,
                        a.gender,
                        [],
                        [a.playTime],
                        [
                            monthlyVideoStatsState.stats
                                .filter(
                                    (s) =>
                                        s.month === now.getMonth() + 1 &&
                                        s.year === now.getFullYear() &&
                                        s.gender === a.gender
                                )
                                .reduce((a, c) => a + c.recordedDuration, 0),
                        ]
                    )
            );
            res.push(
                new DashboardViewer(
                    viewer.id,
                    viewer.name,
                    viewer.avatar,
                    ageData,
                    genderData,
                    ethnicityData
                )
            );
        });

        setCohortData(res);
    }, [monthlyVideoStatsState.stats, monthlyViewerStatsState.stats]);

    /**
     * Gets view stats
     */
    useEffect(() => {
        let res: DashboardViewer[] = [];

        // Sanitize candidate estimations and age ranges
        const sanitizedCandidates = applicationState.applications.map((a) => {
            return {
                ...a.user,
                candidate: {
                    ...a.user.candidate,
                    age: a.user.candidate.age
                        ? ageRange(a.user.candidate.age)
                        : a.user.candidate.estimatedAge
                        ? ageRange(a.user.candidate.estimatedAge.val)
                        : "unknown",
                    ethnicity:
                        a.user.candidate.ethnicity ??
                        a.user.candidate.estimatedEthnicity?.val ??
                        "unknown",
                    gender:
                        a.user.candidate.gender ??
                        a.user.candidate.estimatedGender?.val ??
                        "unknown",
                },
            };
        });
        console.log(sanitizedCandidates);

        // For each viewer, calculate view stats
        companyUsersState.users.forEach((viewer) => {
            // Find this user's application views
            const applicationViews =
                applicationViewState.applicationViews.filter(
                    (av) => av.userId === viewer.id
                );

            // Since we just pulled "application views this month", we may have views that are associated with an application from last month
            // We only care about application views that are for applications in THIS month.
            const applicationViewsForApplicationsThisMonth =
                applicationViews.filter(
                    (av) =>
                        applicationState.applications
                            .map((a) => a.id)
                            .indexOf(av.applicationId) > -1
                );

            // Filter out views for the same application IDs
            const uniqueApplicationViews = uniqueBy(
                applicationViewsForApplicationsThisMonth,
                "applicationId"
            );

            // Find who we viewed from the sanitized candidates list
            const viewedCandidates = uniqueApplicationViews.map((av) =>
                sanitizedCandidates.find((c) => c.id === av.candidateId)
            );

            if (viewedCandidates.length === 0) return;

            const ageStats = aggregateByPropGroup(
                viewedCandidates.map((u) => u?.candidate),
                ["age"],
                []
            ) as {
                age: string;
                count: number;
            }[];
            const ageData = ageStats.map((a) => {
                return new ViewerViewTimeTotal(
                    a.age,
                    a.age,
                    [],
                    [a.count],
                    [
                        sanitizedCandidates
                            .filter((s) => s.candidate.age === a.age)
                            .reduce((a, c) => a + 1, 0),
                    ]
                );
            });

            const ethnicityStats = aggregateByPropGroup(
                viewedCandidates.map((u) => u?.candidate),
                ["ethnicity"],
                []
            ) as {
                ethnicity: string;
                count: number;
            }[];
            const ethnicityData = ethnicityStats.map((a) => {
                return new ViewerViewTimeTotal(
                    a.ethnicity,
                    a.ethnicity,
                    [],
                    [a.count],
                    [
                        sanitizedCandidates
                            .filter(
                                (s) => s.candidate.ethnicity === a.ethnicity
                            )
                            .reduce((a, c) => a + 1, 0),
                    ]
                );
            });

            const genderStats = aggregateByPropGroup(
                viewedCandidates.map((u) => u?.candidate),
                ["gender"],
                []
            ) as {
                gender: string;
                count: number;
            }[];
            const genderData = genderStats.map((a) => {
                return new ViewerViewTimeTotal(
                    a.gender,
                    a.gender,
                    [],
                    [a.count],
                    [
                        sanitizedCandidates
                            .filter((s) => s.candidate.gender === a.gender)
                            .reduce((a, c) => a + 1, 0),
                    ]
                );
            });
            res.push(
                new DashboardViewer(
                    viewer.id,
                    viewer.name,
                    viewer.avatar,
                    ageData,
                    genderData,
                    ethnicityData
                )
            );
        });

        setViewData(res);
    }, [
        applicationState.applications,
        applicationViewState.applicationViews,
        companyUsersState.users,
    ]);

    // Get window size
    const size = useWindowSize();

    return (
        <BodyWrapper size={size}>
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">
                    {company?.name} - Hiring Managers - Monthly Stats
                </h1>
                <div className="grid row centered">
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={getAllData}
                        disabled={
                            monthlyVideoStatsState.isLoading ||
                            monthlyViewerStatsState.isLoading
                        }
                    />
                </div>
            </PageHeaderRow>
            {companyUsersState.isLoading || companyUsersState.isLoading ? (
                <Loader fillParent={true} />
            ) : (
                companyUsersState.users.map((user) => {
                    return (
                        <section key={user.id} className="grid gap-lg">
                            <header className="grid gap-md row start centered">
                                <UserAvatar image={user.avatar} size={"50px"} />
                                <h2>{user.name}</h2>
                            </header>
                            <div className="grid gap-md">
                                <h3>
                                    <Chip label="View" /> time vs{" "}
                                    <Chip
                                        color="primary-inverted"
                                        label="Available"
                                    />{" "}
                                    recorded time
                                </h3>
                                <p>
                                    These charts represent the time spent by a
                                    user watching videos, vs the total available
                                    recorded duration of a given cohort.
                                </p>
                                {cohortData.filter(
                                    (d) => d.viewerId === user.id
                                ).length === 0 && (
                                    <div>
                                        <ChartResponsiveWrapper>
                                            <ChartNoData />
                                        </ChartResponsiveWrapper>
                                    </div>
                                )}
                                {cohortData
                                    .filter((d) => d.viewerId === user.id)
                                    .map((e) => (
                                        <ChartSection
                                            className="grid gap-xl"
                                            key={e.viewerId}
                                            size={size}
                                        >
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Age
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.age}
                                                />
                                            </article>
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Ethnicity
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.ethnicity}
                                                />
                                            </article>
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Gender
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.gender}
                                                />
                                            </article>
                                        </ChartSection>
                                    ))}
                            </div>
                            <div className="grid gap-md">
                                <h3>
                                    Count of <Chip label="views" /> vs{" "}
                                    <Chip
                                        color="primary-inverted"
                                        label="applied"
                                    />{" "}
                                    by cohort
                                </h3>
                                <p>
                                    These charts represent a user's viewed
                                    applications vs the total spread of cohorts
                                    for applicants this month.
                                </p>
                                {viewData.filter((d) => d.viewerId === user.id)
                                    .length === 0 && (
                                    <div>
                                        <ChartResponsiveWrapper>
                                            <ChartNoData />
                                        </ChartResponsiveWrapper>
                                    </div>
                                )}
                                {viewData
                                    .filter((d) => d.viewerId === user.id)
                                    .map((e) => (
                                        <ChartSection
                                            className="grid gap-xl"
                                            key={e.viewerId}
                                            size={size}
                                        >
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Age
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.age}
                                                />
                                            </article>
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Ethnicity
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.ethnicity}
                                                />
                                            </article>
                                            <article className="grid">
                                                <ChartToolbar className="grid row centered">
                                                    <h2 className="text-lg-medium">
                                                        Gender
                                                    </h2>
                                                </ChartToolbar>
                                                <ChartViewTimeCohort
                                                    data={e.gender}
                                                />
                                            </article>
                                        </ChartSection>
                                    ))}
                            </div>
                            <hr />
                        </section>
                    );
                })
            )}
        </BodyWrapper>
    );
});

const ChartSection = styled.div<{ size: Size }>(
    ({ size }) => `
    grid-template-columns: ${size.isMdUp ? "1fr 1fr" : "1fr"};
    gap: calc(var(--base-spacing) * 4);
    `
);
