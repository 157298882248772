import { Dispatch } from "@reduxjs/toolkit";

import { errorActions, ErrorPayload } from "./error-slice";

/**
 * Helper class to adds an error and message to the store with `useAppDispatch()`.
 * For use within components. If used in other stores/slices/actions, use dispatch(errorActions.add)
 * @param errors - raw error object
 * @returns
 */
export const logError =
    (errors: any | any[] | ErrorPayload | ErrorPayload[]) =>
    async (dispatch: Dispatch): Promise<void> => {
        // Handle the provided inputs based on their type.
        if (isErrorPayload(errors)) {
            // This is an `ErrorPayload`, the error will be logged to the store the message will be displayed as an alert in `error-sink`
            dispatch(errorActions.add([errors]));
        } else if (Array.isArray(errors) && isErrorPayload(errors[0])) {
            console.log("is ErrorPayload");
            // This is an `ErrorPayload[]`, errors will be logged to the store and the messages will be displayed as alerts in `error-sink`
            dispatch(errorActions.add(errors));
        } else {
            console.log("is any | any[]");
            // This is `any` | `any[]`, error(s) will be logged to the store but not displayed to the user in any way
            dispatch(
                errorActions.add(Array.isArray(errors) ? errors : [errors])
            );
        }
    };

/**
 * Typeguard for ErrorPayload type
 * @param toBeDetermined - the object to be checked
 * @returns - true/false. TypeScript will retain the type once this is called
 */
const isErrorPayload = (
    toBeDetermined: any
): toBeDetermined is ErrorPayload => {
    return toBeDetermined.message ? true : false;
};
