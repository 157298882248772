import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../stores/hooks";

interface Props extends React.PropsWithChildren {
    criteria: { value: string; check: string; not?: boolean }[];
    redirect: any;
}

/**
 * Basic access check.
 * @param criteria - Array of checks to perform
 * @param criteria.value - The value that the result of the check must equal. E.g. a companyId, a userId, or role. Pass a `*` for a null check where as long as the prop exists, we pass.
 * @param criteria.check - Dot notation selector for accessing the user's `auth` Context. E.g. `company.id` to access the user's company ID, or `userDetails.userType` for their role type.
 * @param criteria.not - If true, applies "not" to the given check. Useful for things like "not an employer" which allows anonymous and candidates.
 * @param redirect - Route to navigate to. If not specified will redirect to Access Denied.
 * }
 */
export const AuthGuard = React.memo<any>((props: Props) => {
    // Navigator
    const navigate = useNavigate();

    // Store
    const authState = useAppSelector((state) => state.auth);

    useEffect(() => {
        console.log(`Running Auth check...`, authState);
        if (!authState.user) return navigate("/auth/login");

        let hasAccess = true;
        try {
            // If we're an admin, we're allowed in
            if (authState.user?.userType !== "ADMIN") {
                props.criteria.forEach((c) => {
                    // If c is `undefined` go to the next loop
                    // React will pass in `undefined` if the JSX used was a truthy insert on the criteria array,
                    // e.g. `(existingVacancy && { value: existingVacancy.companyId, check: 'membership.companyId' })`
                    if (!c) return;

                    // Deconstruct dot notation in the check to target the correct object
                    const checkValue = c.check
                        .split(".")
                        // @ts-expect-error
                        .reduce((a, c) => a[c], authState);

                    // value may be a `*`.
                    // This is a "not null" check, act accordingly
                    if (c.value === "*") {
                        console.log(`Auth: Checking ${c.check} exists`);
                        if (!checkValue) {
                            console.log(
                                `${checkValue} does not exist - access denied`
                            );
                            hasAccess = false;
                        }
                    } else {
                        // Not a `*` null check - check the actual values
                        console.log(
                            `Auth: Checking ${c.value} ${
                                c.not ? "does not match" : "matches"
                            } ${c.check}`
                        );
                        if (
                            c.not
                                ? // @ts-ignore
                                  c.value === checkValue
                                : // @ts-ignore
                                  c.value !== checkValue
                        ) {
                            console.log(
                                `${c.value} ${
                                    c.not ? "matches" : "does not match"
                                } ${checkValue} - access denied`
                            );
                            hasAccess = false;
                        }
                    }
                });
            }

            // Navigate to the given `redirect` if provided, else access denied.
            // Replace history entry so "back" works correctly and skips the page they were just redirected from
            if (!hasAccess) {
                navigate(props.redirect ? props.redirect : "/access-denied", {
                    replace: true,
                });
            }
        } catch (ex) {
            // If something goes wrong, deny access. Perhaps the dev passed in something wild in the check 🤷‍♀️
            navigate(props.redirect ? props.redirect : "/access-denied", {
                replace: true,
            });
        }
    }, [authState, props.criteria, navigate, props.redirect]);

    return props.children;
});
