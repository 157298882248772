import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { Button } from "../form/button";
import { UserAvatar } from "../user-avatar";
import {
    faChartSimple,
    faHouse,
    faRankingStar,
    faUser,
    faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { logOutUser } from "../../stores/auth/auth-actions";
import { Chip } from "../chip";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Sidebar component
 * - Vertial container
 * - 2 grid content areas, aligned top and bottom (space-between)
 * - Self-contained slim scroll for control overflow
 */
export const Sidebar = React.memo<Props>((props) => {
    const { user } = useAppSelector((state) => state.auth);
    const state = useAppSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const goToLogin = () => {
        navigate(`/login`);
    };

    const confirmSignOut = () => {
        const confirm = window.confirm(
            `${user?.name}, are you sure you want to sign out?`
        );
        if (confirm) {
            dispatch(logOutUser());
            navigate("/auth/login");
        }
    };

    return (
        <Container>
            <Fixed className="grid column centered thin-scrollbar">
                <Top className="grid column centered">
                    <Logo src="/logo.png"></Logo>
                    {process.env.REACT_APP_ENV !== "production" && (
                        <Chip
                            style={{
                                marginBottom: "calc(var(--base-spacing) * 2)",
                                fontWeight: 600,
                            }}
                            label={process.env.REACT_APP_ENV!}
                            color="primary-inverted"
                        />
                    )}
                    <Button
                        icon={faHouse}
                        color="primary-alt"
                        isOutline={true}
                        isSquare={true}
                        onClick={() => {
                            navigate("/");
                        }}
                        title="Home"
                        className={`${pathname === "/" ? "active" : ""}`}
                    />
                    <Button
                        icon={faRankingStar}
                        color="primary-alt"
                        isOutline={true}
                        isSquare={true}
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                        title="Applicant Statistics - Totals"
                        className={`${
                            pathname === "/dashboard" ? "active" : ""
                        }`}
                    />
                    <Button
                        icon={faChartSimple}
                        color="primary-alt"
                        isOutline={true}
                        isSquare={true}
                        onClick={() => {
                            navigate("/job-stats");
                        }}
                        title="Applicant Statistics - Vacancies"
                        className={`${
                            pathname === "/job-stats" ? "active" : ""
                        }`}
                    />
                    <Button
                        icon={faUserTie}
                        color="primary-alt"
                        isOutline={true}
                        isSquare={true}
                        onClick={() => {
                            navigate("/managers");
                        }}
                        title="Manager Statistics"
                        className={`${
                            pathname === "/managers" ? "active" : ""
                        }`}
                    />
                    {/*<Button
            icon={faSquareCheck}
            color="primary-alt"
            isOutline={true}
            isSquare={true}
            onClick={() => {}}
          />
          <Button
            icon={faFlag}
            color="primary-alt"
            isOutline={true}
            isSquare={true}
            onClick={() => {}}
          />
          <Button
            icon={faUserGroup}
            color="primary-alt"
            isOutline={true}
            isSquare={true}
            onClick={() => {}}
          /> */}
                </Top>

                <Bottom className="grid column centered">
                    {/* <Button
            icon={faLifeRing}
            color="primary"
            isSquare={true}
            onClick={() => {}}
            title="Help"
          /> */}
                    {/* <Button
            icon={faGear}
            color="primary"
            isSquare={true}
            onClick={() => {}}
            title="Settings"
          /> */}
                    <Divider />
                    {state.auth.user ? (
                        <UserAvatar
                            image={user?.avatar}
                            size="100%"
                            onClick={confirmSignOut}
                            title={`${user?.name} (${user?.email}) - Sign out`}
                        />
                    ) : (
                        <UserAvatar
                            icon={faUser}
                            size="100%"
                            onClick={goToLogin}
                            title="Sign in"
                        />
                    )}
                </Bottom>
            </Fixed>
        </Container>
    );
});

const Container = styled.div(
    () => `
  background: var(--color-ghostAsh);
  color: var(--color-white);
  `
);

const Fixed = styled.div(
    () => `
    overflow-y: auto;
    height: 100vh;
    height: 100dvh;
    position: fixed;
    grid-template-rows: auto 1fr;
    align-items: space-between;
    padding: 40px calc(var(--base-spacing) * 2);
    }
  `
);

const Logo = styled.img`
    height: 30px;
    width: 30px;
    margin-bottom: 12px;
`;

const Top = styled.div(
    () => `
    width: 100%;
    `
);

const Bottom = styled.div(
    () => `
    width: 100%;
    align-content: flex-end;
`
);

const Divider = styled.div(
    () => `
     diplay: block;
     height: 2px;
     width 100%;
     margin: 16px 0;
     background: var(--color-primary-600);
  `
);
