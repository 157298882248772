import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMonthlyViewerStats } from "../../models/monthly-viewer-stats";
import { IRemoteState } from "../remote-state";

interface IMonthlyViewerStatsState extends IRemoteState {
    stats: IMonthlyViewerStats[];
}

const initialState: IMonthlyViewerStatsState = {
    stats: [],
    isLoading: false,
};

/**
 * Monthly Viewer stats store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const monthlyViewerStatsSlice = createSlice({
    name: "monthlyViewerStats",
    initialState,
    reducers: {
        updated: (state) => {
            state.serverUpdated = new Date().valueOf();
        },
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        add: (state, action: PayloadAction<IMonthlyViewerStats[]>) => {
            state.stats = action.payload;
        },
    },
});

export const monthlyViewerStatsActions = monthlyViewerStatsSlice.actions;
export default monthlyViewerStatsSlice.reducer;
