import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../models/user";
import { ICompany } from "../../models/company";

const storageJson = localStorage.getItem("currentUser");
let user: IUser = storageJson ? JSON.parse(storageJson).user : "";
let company: ICompany = storageJson ? JSON.parse(storageJson).company : "";

const tokenJson = localStorage.getItem("currentUser");
let token = tokenJson ? JSON.parse(tokenJson).jwt : "";

export interface IAuthState {
    user?: IUser;
    company?: ICompany;
    token?: string;
    isLoading: boolean;
    error?: string;
    errorTime?: number;
}

export const initialState: IAuthState = {
    user: undefined || user,
    company: undefined || company,
    token: undefined || token,
    isLoading: false,
    error: undefined,
    errorTime: undefined,
};

/**
 * Auth store
 * Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 * But not THIS one, for reasons I do not understand. For this one, we must *return* a state object. Works regardless of mutated state or a new object.
 * But why?! If you know why, please feel free to fix it, React and I are not friends.
 */
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logInRequest: (state) => {
            const newState = {
                ...state,
                isLoading: true,
                error: undefined,
            };
            return newState;
        },
        logInSuccess: (
            state,
            action: PayloadAction<{
                user: IUser;
                company: ICompany;
                jwt: string;
            }>
        ) => {
            const newState = {
                ...state,
                user: action.payload.user,
                company: action.payload.company,
                token: action.payload.jwt,
                isLoading: false,
                error: undefined,
            };
            return newState;
        },
        logInError: (state, action: PayloadAction<string>) => {
            const newState = {
                ...state,
                isLoading: false,
                error: action.payload,
                errorTime: new Date().valueOf(),
            };
            return newState;
        },
        logOut: (state) => {
            const newState = {
                ...state,
                user: undefined,
                company: undefined,
                token: undefined,
                isLoading: false,
                error: undefined,
            };
            return newState;
        },
        resetPasswordRequest: (state) => {
            const newState = {
                ...state,
                isLoading: true,
                error: undefined,
            };
            return newState;
        },
        resetPasswordSuccess: (state) => {
            const newState = {
                ...state,
                isLoading: false,
                error: undefined,
            };
            return newState;
        },
        resetPasswordError: (state, action: PayloadAction<string>) => {
            const newState = {
                ...state,
                isLoading: false,
                error: action.payload,
                errorTime: new Date().valueOf(),
            };
            return newState;
        },
        clearErrors: (state) => {
            const newState = {
                ...state,
                isLoading: false,
                error: undefined,
                errorTime: new Date().valueOf(),
            };
            return newState;
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
