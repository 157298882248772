import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useMemo, useState } from "react";
import { StackedChartData } from "../../models/chart";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";
import { formatTime } from "../../utils/utils";

type Props = {
    data: StackedChartData[];
};

/**
 * Stacked bar chart for aggregate time of views for each viewer, broken down and stacked by a cohort (vacancy overview)
 */
export const ChartViewerTimeCohort = React.memo<Props>(({ data }) => {
    // We need to keep track of colour assignments, use local state to do so
    const [keyColors, setKeyColors] = useState<{ [key: string]: number }>({});
    const [series, setSeries] = useState<string[]>([]);

    // Available colours, selected sequentially based on index of data
    // If more entries are present than colours, it loops around to 0
    const colors = useMemo(
        () => [
            "var(--color-chart-1)",
            "var(--color-chart-2)",
            "var(--color-chart-3)",
            "var(--color-chart-4)",
            "var(--color-chart-5)",
            "var(--color-chart-6)",
            "var(--color-chart-7)",
            "var(--color-chart-8)",
            "var(--color-chart-9)",
        ],
        []
    );

    /**
     * Calculates unique series from the 1st data index
     * Calculates colors for each series
     */
    useEffect(() => {
        if (data.length < 1) return;

        // Find unique series
        const series = Object.entries(data[0])
            .map(([key]) => key)
            .filter((key) => key !== "key");
        // .sort((a, b) => {
        //     if (a < b) return -1;
        //     if (a > b) return 1;
        //     return 0;
        // })
        setSeries(series);

        // Now loop through the available series and work out colour assignments
        let _keyColors: { [key: string]: number } = {};
        series.forEach((s) => {
            // Check if we already have this series figured out.
            // If we do, drop to the next iteration in the loop
            if (_keyColors[s]) return;

            // New entry
            // Ensure we don't exceed the number of available colours
            // Indexes > colours.length will reset to 0
            const index = Object.keys(_keyColors).length % colors.length;
            _keyColors[s] = index;
        });
        setKeyColors(_keyColors);
    }, [colors, data]);

    return (
        <ChartResponsiveWrapper>
            {!data || (Array.isArray(data) && !data.length) ? (
                <ChartNoData />
            ) : (
                <ResponsiveBar
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 30,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -40,
                        format: (value) => formatTime(value),
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={(d) => {
                        return colors[keyColors[d.id]];
                    }}
                    data={data}
                    enableGridX={false}
                    enableLabel={false}
                    indexBy="key"
                    indexScale={{ type: "band", round: true }}
                    keys={series}
                    labelFormat={(d) => formatTime(Number(d))}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor="var(--color-white)"
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "top-left",
                            direction: "column",
                            translateX: -120,
                            translateY: -25,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: "#999",
                            symbolSize: 12,
                            symbolShape: "circle",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemTextColor: "#000",
                                    },
                                },
                            ],
                        },
                    ]}
                    margin={{ top: 40, left: 120, bottom: 24 }}
                    tooltip={(bar) => {
                        return (
                            <div
                                style={{
                                    // Identical style to default tooltips
                                    background: "white",
                                    color: "inherit",
                                    fontSize: "inherit",
                                    borderRadius: "2px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                                    padding: "5px 9px",
                                }}
                            >
                                {bar.label}: {bar.formattedValue}
                            </div>
                        );
                    }}
                    valueFormat={(value) => formatTime(value)}
                    valueScale={{ type: "linear" }}
                />
            )}
        </ChartResponsiveWrapper>
    );
});
