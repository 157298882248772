import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { Button } from "./form/button";

interface PositionProps {
    positionX?: "left" | "right";
    positionY?: "top" | "bottom";
}

interface Props extends React.HTMLAttributes<HTMLButtonElement>, PositionProps {
    clickableElement: ReactElement;
    options: { id: string; label: string }[];
    onOptionSelected: (selected: string) => void;
    isDisabled?: boolean;
}

/**
 * Basic styled dropdown/flyout menu
 * - *Not* a modal, behaves similar to `<select>` but with prettier styling
 * - Blocks interaction with other elements and will close if clicked away
 * - `clickableElement` is the click target to open the menu, and can be any React component
 * - Can be positioned
 * - Fires onOptionSelected when a user clicks an option
 */
export const Dropdown = React.memo<Props>(
    ({
        clickableElement,
        color,
        options,
        positionX,
        positionY,
        onOptionSelected,
        isDisabled = false,
        style,
        className,
        ...props
    }) => {
        const [isVisible, setIsVisible] = useState(false);

        /**
         * Fires when a user cilcks an option
         * @param option - the option that was selected
         */
        const onSelect = (option: string) => {
            setIsVisible(false);
            onOptionSelected(option);
        };

        return (
            <>
                <Shade
                    isVisible={isVisible}
                    onClick={() => setIsVisible(false)}
                ></Shade>
                <div style={{ position: "relative" }}>
                    <div
                        onClick={() =>
                            !isDisabled && setIsVisible((current) => !current)
                        }
                    >
                        <>{clickableElement}</>
                    </div>
                    <Container
                        isVisible={isVisible}
                        positionX={positionX}
                        positionY={positionY}
                        className="grid"
                    >
                        {options?.map((option, i) => {
                            return (
                                <li key={option.id}>
                                    <Button
                                        onClick={() => onSelect(option.id)}
                                        label={option.label}
                                    />
                                </li>
                            );
                        })}
                    </Container>
                </div>
            </>
        );
    }
);

const Container = styled.ul<{ isVisible: boolean } & PositionProps>(
    ({ isVisible, positionX, positionY }) => `
    list-style: none;
    display: ${isVisible ? "grid" : "none"};
    grid-template-columns: 1fr;
    position: absolute;
    ${positionY === "top" ? "bottom: 100%" : "top: 100%"};
    ${positionX === "left" ? "right: 0" : "left: 0"};
    background-color: var(--color-white);
    padding: var(--base-spacing);
    border-radius: var(--border-radius);
    z-index: 999;
    box-shadow: 0 2px 10px var(--color-shadow);
    min-width: 150px;
    button {
        color: var(--color-ash);
        background: transparent;
        border: 0;
        width: 100%;
        text-transform: capitalize;
        text-align: left;
        padding: calc(var(--base-spacing) * 1.5) calc(var(--base-spacing) * 1.5);
    }
    button:hover {
        background: var(--color-primary-600);
        color: var(--color-white);
        font-weight: 600;
    }
  `
);

const Shade = styled.div<{ isVisible: boolean }>(
    ({ isVisible }) =>
        `display: ${isVisible ? "block" : "none"};
    position: fixed;
    inset: 0;
    background: transparent;
    z-index: 998;
`
);
