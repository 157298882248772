import { useCallback, useEffect, useState } from "react";
import { Size, useWindowSize } from "../../hooks/window-size";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { loadVacancies } from "../../stores/vacancy/vacancy-actions";
import { Button } from "../form/button";
import { PageHeaderRow } from "../layout/page-header-row";
import { Loader } from "../loader";
import { userSettingsActions } from "../../stores/user-settings/user-settings-slice";
import { ApplicantCohortCount } from "../../models/chart";
import { ChartApplicantCohortTotal } from "../chart/chart-applicant-cohort-total";
import styled from "styled-components";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Toggle } from "../form/toggle";

/**
 * Interfaces with StringMap extension required for Type Safety since we're totalling columns
 * We may need these somewhere else in the app at some point, in which case break these out to be a shared util
 */
interface StringMap {
    [name: string]: any;
}

interface AgeData extends StringMap {
    "18-24": number;
    "25-29": number;
    "30-39": number;
    "40-49": number;
    "50-59": number;
    "60+": number;
    unknown: number;
}
interface EthnicityData extends StringMap {
    white: number;
    black: number;
    asian: number;
    hispanic: number;
    indian: number;
    mideast: number;
    other: number;
    unknown: number;
}
interface GenderData extends StringMap {
    male: number;
    female: number;
    unknown: number;
}
/** End interfaces for type safety  */

function ApplicantData() {
    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh

    // Hooks
    const size = useWindowSize();

    // Stores
    const dispatch = useAppDispatch();
    const { company } = useAppSelector((state) => state.auth);
    const vacancyState = useAppSelector((state) => state.vacancy);
    const userSettingsState = useAppSelector((state) => state.userSettings);

    // Local state
    // Use estimated in calcs?
    const [useEstimated, setUseEstimated] = useState<boolean>(true);
    // Total / percentage switch
    const [dataType, setDataType] = useState<boolean>(false);

    // Age data
    const [ageData, setAgeData] = useState<AgeData>({
        "18-24": 0,
        "25-29": 0,
        "30-39": 0,
        "40-49": 0,
        "50-59": 0,
        "60+": 0,
        unknown: 0,
    });
    // Ethnicity data
    const [ethnicityData, setEthnicityData] = useState<EthnicityData>({
        white: 0,
        black: 0,
        asian: 0,
        hispanic: 0,
        indian: 0,
        mideast: 0,
        other: 0,
        unknown: 0,
    });
    // Gender data
    const [genderData, setGenderData] = useState<GenderData>({
        male: 0,
        female: 0,
        unknown: 0,
    });
    // Totals
    const [totalViews, setTotalViews] = useState<number>(0);

    const [ageTotal, setAgeTotal] = useState<ApplicantCohortCount[]>([]);
    const [genderTotal, setGenderTotal] = useState<ApplicantCohortCount[]>([]);
    const [ethnicityTotal, setEthnicityTotal] = useState<
        ApplicantCohortCount[]
    >([]);

    /** Set user preferences on load */
    useEffect(() => {
        setUseEstimated(userSettingsState.useEstimated);
    }, [userSettingsState]);

    /**
     * Gets new data from all data sources on the page
     * Used by the refresh button
     */
    const getAllData = useCallback(() => {
        if (company) dispatch(loadVacancies(company.id));
    }, [company, dispatch]);

    /**
     * Hit the API to get vacancies if we didn't rehydrate from storage or if state is more than x minutes old
     */
    useEffect(() => {
        if (!company) return;

        const expiryThreshold =
            new Date().valueOf() -
            (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;
        if (
            !vacancyState.companyLoads[company.id] ||
            vacancyState.companyLoads[company.id] <= expiryThreshold
        ) {
            console.log(
                "%cVacancy state expired, refreshing",
                "background-color: yellow;"
            );
            dispatch(loadVacancies(company.id)).catch(console.error);
        }
    }, [
        dispatch,
        DATA_EXPIRY,
        userSettingsState.dataExpiry,
        company,
        vacancyState.companyLoads,
    ]);

    useEffect(() => {
        // Flatted all candidates in to a single array
        const allUsers = vacancyState.vacancies
            .map((vacancy) => vacancy.applications.map((a) => a.user))
            .flat();

        // Get UNIQUE candidates for all our vacancies in state - a candidate may have applied many times - we don't care
        const users = allUsers.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.id === value.id && t.name === value.name
                )
        );

        setTotalViews(users.length);

        const ages = {
            "18-24": 0,
            "25-29": 0,
            "30-39": 0,
            "40-49": 0,
            "50-59": 0,
            "60+": 0,
            unknown: 0,
        };

        const ethnicities = {
            white: 0,
            black: 0,
            asian: 0,
            hispanic: 0,
            indian: 0,
            mideast: 0,
            other: 0,
            unknown: 0,
        };

        const genders = {
            male: 0,
            female: 0,
            unknown: 0,
        };

        let ageRes: any[] = [
            { total: 0, cohort: "18-24" },
            { total: 0, cohort: "25-29" },
            { total: 0, cohort: "30-39" },
            { total: 0, cohort: "40-49" },
            { total: 0, cohort: "50-59" },
            { total: 0, cohort: "60+" },
            { total: 0, cohort: "unknown" },
        ];

        let ethnicityRes: any[] = [
            { total: 0, cohort: "white" },
            { total: 0, cohort: "black" },
            { total: 0, cohort: "asian" },
            { total: 0, cohort: "hispanic" },
            { total: 0, cohort: "mideast" },
            { total: 0, cohort: "indian" },
            { total: 0, cohort: "other" },
            { total: 0, cohort: "unknown" },
        ];

        let genderRes: any[] = [
            { total: 0, cohort: "male" },
            { total: 0, cohort: "female" },
            { total: 0, cohort: "unknown" },
        ];

        users.forEach((user) => {
            const age = useEstimated
                ? user.candidate.age || user.candidate.estimatedAge?.val
                : user.candidate.age;
            const ethnicity = useEstimated
                ? user.candidate.ethnicity ||
                  user.candidate.estimatedEthnicity?.val
                : user.candidate.ethnicity;
            const gender = useEstimated
                ? user.candidate.gender || user.candidate.estimatedGender?.val
                : user.candidate.gender;

            let index = 0;
            if (age) {
                if (age >= 18 && age <= 24) {
                    ages["18-24"]++;
                    index = ageRes.findIndex((x) => x.cohort === "18-24");
                } else if (age >= 25 && age <= 29) {
                    ages["25-29"]++;
                    index = ageRes.findIndex((x) => x.cohort === "25-29");
                } else if (age >= 30 && age <= 39) {
                    ages["30-39"]++;
                    index = ageRes.findIndex((x) => x.cohort === "30-39");
                } else if (age >= 40 && age <= 49) {
                    ages["40-49"]++;
                    index = ageRes.findIndex((x) => x.cohort === "40-49");
                } else if (age >= 50 && age <= 59) {
                    ages["50-59"]++;
                    index = ageRes.findIndex((x) => x.cohort === "50-59");
                } else if (age >= 60) {
                    ages["60+"]++;
                    index = ageRes.findIndex((x) => x.cohort === "60+");
                } else {
                    ages.unknown++;
                    index = ageRes.findIndex((x) => x.cohort === "unknown");
                }
            } else {
                ages.unknown++;
                index = ageRes.findIndex((x) => x.cohort === "unknown");
            }

            ageRes[index].total += 1;
            ageRes[index].percentage =
                (ageRes[index].total / users.length) * 100;

            switch (ethnicity) {
                case "white":
                    ethnicities.white++;
                    index = ethnicityRes.findIndex((x) => x.cohort === "white");
                    break;
                case "black":
                    ethnicities.black++;
                    index = ethnicityRes.findIndex((x) => x.cohort === "black");
                    break;
                case "asian":
                    ethnicities.asian++;
                    index = ethnicityRes.findIndex((x) => x.cohort === "asian");
                    break;
                case "hispanic":
                    ethnicities.hispanic++;
                    index = ethnicityRes.findIndex(
                        (x) => x.cohort === "hispanic"
                    );
                    break;
                case "indian":
                    ethnicities.indian++;
                    index = ethnicityRes.findIndex(
                        (x) => x.cohort === "indian"
                    );
                    break;
                case "mideast":
                    ethnicities.mideast++;
                    index = ethnicityRes.findIndex(
                        (x) => x.cohort === "mideast"
                    );
                    break;
                case "other":
                    ethnicities.other++;
                    index = ethnicityRes.findIndex((x) => x.cohort === "other");
                    break;
                default:
                    ethnicities.unknown++;
                    index = ethnicityRes.findIndex(
                        (x) => x.cohort === "unknown"
                    );
                    break;
            }

            ethnicityRes[index].total += 1;
            ethnicityRes[index].percentage =
                (ethnicityRes[index].total / users.length) * 100;

            switch (gender) {
                case "male":
                    genders.male++;
                    index = genderRes.findIndex((x) => x.cohort === "male");
                    break;
                case "female":
                    genders.female++;
                    index = genderRes.findIndex((x) => x.cohort === "female");
                    break;
                default:
                    genders.unknown++;
                    index = genderRes.findIndex((x) => x.cohort === "unknown");
                    break;
            }

            genderRes[index].total += 1;
            genderRes[index].percentage =
                (genderRes[index].total / users.length) * 100;
        });

        setAgeData(ages);
        setEthnicityData(ethnicities);
        setGenderData(genders);

        setAgeTotal(ageRes);
        setEthnicityTotal(ethnicityRes);
        setGenderTotal(genderRes);
    }, [dispatch, useEstimated, vacancyState.vacancies]);

    return (
        <>
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">
                    {company?.name} - Applicant Statistics - Totals
                </h1>
                <div className="grid row centered">
                    <div className="grid row centered">
                        <span className="text-xs-normal text-right">
                            {useEstimated ? "Inc. " : "Ex."} estimated
                        </span>
                        <Toggle
                            checked={useEstimated}
                            onChange={(e) =>
                                dispatch(
                                    userSettingsActions.setUseEstimated(
                                        !useEstimated
                                    )
                                )
                            }
                        />
                    </div>
                    <Button
                        label={"Type: " + (dataType ? "Total" : "Percentage")}
                        // icon={faRefresh}
                        color="white"
                        onClick={() => {
                            setDataType(!dataType);
                        }}
                    />
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={async () => await getAllData()}
                        disabled={vacancyState.isLoading}
                    />
                </div>
            </PageHeaderRow>
            <section className="grid gap-lg">
                {vacancyState.isLoading ? (
                    <Loader fillParent={true} />
                ) : (
                    <>
                        {" "}
                        <h2 className="text-lg-medium">
                            Applicant Total:{" "}
                            {vacancyState.isLoading ? (
                                <Loader fillParent={false} isInline={true} />
                            ) : (
                                totalViews
                            )}
                        </h2>
                        <article className="grid gap-lg">
                            <section className="grid">
                                <h3 className="text-md-normal grid row start centered">
                                    Age{" "}
                                    {useEstimated && (
                                        <i className="text-grey-500 text-xs-normal">
                                            (includes estimated)
                                        </i>
                                    )}
                                </h3>
                                <ChartSection size={size} className="grid">
                                    <div className="table-wrapper">
                                        {vacancyState.isLoading && (
                                            <Loader fillParent={true} />
                                        )}
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Total</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>18-24</td>
                                                    <td>{ageData["18-24"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["18-24"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>25-29</td>
                                                    <td>{ageData["25-29"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["25-29"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>30-39</td>
                                                    <td>{ageData["30-39"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["30-39"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>40-49</td>
                                                    <td>{ageData["40-49"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["40-49"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>50-59</td>
                                                    <td>{ageData["50-59"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["50-59"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>60+</td>
                                                    <td>{ageData["60+"]}</td>
                                                    <td>
                                                        {(
                                                            (ageData["60+"] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Unknown</td>
                                                    <td>
                                                        {ageData["unknown"]}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ageData[
                                                                "unknown"
                                                            ] /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="bg-">
                                                    <td>Total</td>
                                                    <td>
                                                        {Object.keys(ageData)
                                                            .map(
                                                                (prop) =>
                                                                    ageData[
                                                                        prop
                                                                    ]
                                                            )
                                                            .reduce(
                                                                (
                                                                    t: number,
                                                                    v: number
                                                                ) => t + v
                                                            )}
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <ChartApplicantCohortTotal
                                        data={ageTotal}
                                        dataType={
                                            dataType ? "total" : "percentage"
                                        }
                                    />
                                </ChartSection>
                            </section>
                            <section className="grid">
                                <h3 className="text-md-normal grid row start centered">
                                    Ethnicity
                                    {useEstimated && (
                                        <i className="text-grey-500 text-xs-normal">
                                            (includes estimated)
                                        </i>
                                    )}
                                </h3>
                                <ChartSection size={size} className="grid">
                                    <div className="table-wrapper">
                                        {vacancyState.isLoading && (
                                            <Loader fillParent={true} />
                                        )}
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Total</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>White</td>
                                                    <td>
                                                        {ethnicityData.white}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.white /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Black</td>
                                                    <td>
                                                        {ethnicityData.black}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.black /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Asian</td>
                                                    <td>
                                                        {ethnicityData.asian}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.asian /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Hispanic</td>
                                                    <td>
                                                        {ethnicityData.hispanic}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.hispanic /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Indian</td>
                                                    <td>
                                                        {ethnicityData.indian}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.indian /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Middle East</td>
                                                    <td>
                                                        {ethnicityData.mideast}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.mideast /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Other</td>
                                                    <td>
                                                        {ethnicityData.other}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.other /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>unknown</td>
                                                    <td>
                                                        {ethnicityData.unknown}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (ethnicityData.unknown /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="bg-">
                                                    <td>Total</td>
                                                    <td>
                                                        {Object.keys(
                                                            ethnicityData
                                                        )
                                                            .map(
                                                                (prop) =>
                                                                    ethnicityData[
                                                                        prop
                                                                    ]
                                                            )
                                                            .reduce(
                                                                (
                                                                    a: number,
                                                                    c: number
                                                                ) => a + c
                                                            )}
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <ChartApplicantCohortTotal
                                        data={ethnicityTotal}
                                        dataType={
                                            dataType ? "total" : "percentage"
                                        }
                                    />
                                </ChartSection>
                            </section>
                            <section className="grid">
                                <h3 className="text-md-normal grid row start centered">
                                    Gender
                                    {useEstimated && (
                                        <i className="text-grey-500 text-xs-normal">
                                            (includes estimated)
                                        </i>
                                    )}
                                </h3>
                                <ChartSection size={size} className="grid">
                                    <div className="table-wrapper">
                                        {vacancyState.isLoading && (
                                            <Loader fillParent={true} />
                                        )}
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Total</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Male</td>
                                                    <td>{genderData.male}</td>
                                                    <td>
                                                        {(
                                                            (genderData.male /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Female</td>
                                                    <td>{genderData.female}</td>
                                                    <td>
                                                        {(
                                                            (genderData.female /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Unknown</td>
                                                    <td>
                                                        {genderData.unknown}
                                                    </td>
                                                    <td>
                                                        {(
                                                            (genderData.unknown /
                                                                totalViews) *
                                                            100
                                                        ).toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="bg-">
                                                    <td>Total</td>
                                                    <td>
                                                        {Object.keys(genderData)
                                                            .map(
                                                                (prop) =>
                                                                    genderData[
                                                                        prop
                                                                    ]
                                                            )
                                                            .reduce(
                                                                (
                                                                    a: number,
                                                                    c: number
                                                                ) => a + c
                                                            )}
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <ChartApplicantCohortTotal
                                        data={genderTotal}
                                        dataType={
                                            dataType ? "total" : "percentage"
                                        }
                                    />
                                </ChartSection>
                            </section>
                        </article>
                    </>
                )}
            </section>
        </>
    );
}

export default ApplicantData;

const ChartSection = styled.div<{ size: Size }>(
    ({ size }) => `
    grid-template-columns: ${size.isMdUp ? "1fr 1fr" : "1fr"};
    gap: calc(var(--base-spacing) * 4) calc(var(--base-spacing) * 2);
    align-items: flex-start;
    `
);
