// Random selection from arrays
export const randomIntFromInterval = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

export const randomSelectionFromArray = (
    array: any[],
    count: number
): any[] => {
    // Shuffle array - use spread to maintain immutability so Redux doesn't freak
    let shuffled = [...array];
    shuffled.sort(() => 0.5 - Math.random());

    // Get sub-array of first n elements after shuffled
    let selected = shuffled.slice(0, count);
    return selected;
};

export const formatTime = (seconds: number) => {
    // Hours, minutes and seconds
    var hrs = ~~(Number(seconds.valueOf()) / 3600);
    var mins = ~~((Number(seconds.valueOf()) % 3600) / 60);
    var secs = ~~seconds % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + String(mins).padStart(1, "0") + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
};

/**
 * Combines an array of objects in to groups based on `groupByCols` and aggregates given property `aggregateCol`.
 * @param arr - the main data set
 * @param groupByCols - a list of columns to group by, i.e. month and ethnicity will combine all from `arr` with the same month and ethnicity props
 * @param aggregateCol - which property to aggregate, i.e. playTime. **The specified prop's value must be a `number` type to correctly aggregate**
 * @returns aggregated groups, will consist of an array of objects containing the `groupByCols` and `aggregateCol` as props (`{...groupByCol, aggregateCol}`)
 */
export const aggregateByPropGroup = (
    arr: any,
    groupByCols: string[],
    aggregateCols: string[]
): { [key: string]: string | number | undefined; count?: number }[] => {
    let grouped: any = {};

    arr.forEach((o: any) => {
        // Gets the values for "groupByCols" saperated by `|` to create a unique key for tracking
        const values = groupByCols.map((k) => o[k]).join("|");

        // If the key does not exist, create it prior to running aggregations
        if (!grouped[values]) {
            grouped[values] = {
                ...Object.fromEntries(groupByCols.map((k: any) => [k, o[k]])),
                ...Object.fromEntries(aggregateCols.map((k: any) => [k, 0])),
                count: 0,
            };
        }

        groupByCols.forEach((col) => {
            if (!!o[col]) {
                grouped[values].count++;
            }
        });

        aggregateCols.forEach((col) => {
            if (!!o[col]) {
                grouped[values][col] += o[col];
            }
        });
    });

    // Return the completed object's values, these are our unique groups
    return Object.values(grouped);
};

/**
 * Gives an array of values between two numbers at a givens step count
 * @param start Start position
 * @param stop End position
 * @param step Steps
 * @returns
 * @example arrayRange(1, 5, 1)) => [1,2,3,4,5]
 */
export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

/**
 * Finds unique items in an array of objects based on a specific prop or function
 * @param arr - Array of data
 * @param predicate - prop name to check for uniquness
 * @returns Filtered array with duplicates taken out
 */
export const uniqueBy = (arr: any[], predicate: string) => {
    return [
        ...arr
            .reduce((a, c) => {
                const key = c === null || c === undefined ? c : c[predicate];

                a.has(key) || a.set(key, c);

                return a;
            }, new Map())
            .values(),
    ];
};

export const ageRange = (age: number) => {
    if (age >= 60) return "60+";
    if (age >= 50 && age <= 59) return "50-59";
    if (age >= 40 && age <= 49) return "40-49";
    if (age >= 30 && age <= 39) return "30-39";
    if (age >= 25 && age <= 29) return "25-29";
    if (age >= 18 && age <= 24) return "18-24";
    return "unknown";
};
