import styled from "styled-components";
import { Size, useWindowSize } from "../hooks/window-size";
import React, { useEffect, useState } from "react";
import { BodyWrapper } from "../components/layout/body-wrapper";
import { Link, useParams, useNavigate } from "react-router-dom";

// Fake data
import { PageHeaderRow } from "../components/layout/page-header-row";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { ICandidate } from "../models/candidate";
import { Loader } from "../components/loader";
import { loadCandidate } from "../stores/candidate/candidate-actions";
import { Chip } from "../components/chip";
import { EducationLevel } from "../models/education-level";
import { Button } from "../components/form/button";
import { faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { ProgressIndicator } from "../components/progress-indicator";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Candidate details page
 * - Shows a candidate's traits and stats
 */
export const CandidateOverview = React.memo<Props>((_) => {
    // Defaults - overridden almost immediately by userSettings
    const DATA_EXPIRY = 15; // How old network data can be before we refresh

    const size = useWindowSize();

    const { candidateId } = useParams();

    const nav = useNavigate();

    // Stores
    const userSettingsState = useAppSelector((state) => state.userSettings);
    const candidateState = useAppSelector((state) => state.candidate);
    const dispatch = useAppDispatch();

    const [candidate, setCandidate] = useState<ICandidate>();

    useEffect(() => {
        if (candidateId) {
            let targetCandidate = candidateState.candidates.find(
                (candidate) => candidate.id === candidateId
            );
            const expiryThreshold =
                new Date().valueOf() -
                (userSettingsState.dataExpiry ?? DATA_EXPIRY) * 60 * 1000;

            const candidateLoad = candidateState.candidateLoads[candidateId];
            if (
                !targetCandidate ||
                !candidateLoad ||
                candidateLoad <= expiryThreshold
            ) {
                console.log(
                    "%cCandidate not found or expired, attempting get",
                    "background-color: yellow;"
                );
                dispatch(loadCandidate(candidateId));
                targetCandidate = candidateState.candidates.find(
                    (candidate) => candidate.id === candidateId
                );
            }
            setCandidate(targetCandidate);
        }
    }, [
        candidateId,
        candidateState.candidateLoads,
        candidateState.candidates,
        dispatch,
        userSettingsState.dataExpiry,
    ]);

    return candidateState.isLoading ? (
        <BodyWrapper
            style={{ alignContent: "center", justifyItems: "center" }}
            size={size}
        >
            <Loader />
        </BodyWrapper>
    ) : !candidate ? (
        <BodyWrapper
            style={{ alignContent: "center", justifyItems: "center" }}
            size={size}
        >
            <h1 className="text-xl-medium grid column self-centered">
                <Link to={`/`}>Candidate not found</Link>
            </h1>
        </BodyWrapper>
    ) : (
        <BodyWrapper size={size}>
            <PageHeaderRow isMdUp={size.isMdUp}>
                <h1 className="text-xl-medium">
                    Diversity &amp; Inclusion Overview - {candidate.name}
                </h1>
                <div className="grid row centered">
                    <Button
                        icon={faArrowLeft}
                        label="Back"
                        color="white"
                        onClick={() => {
                            nav(-1);
                        }}
                    />
                    <Button
                        label="Refresh"
                        icon={faRefresh}
                        color="white"
                        onClick={async () =>
                            dispatch(loadCandidate(candidateId!))
                        }
                        disabled={candidateState.isLoading}
                    />
                </div>
            </PageHeaderRow>
            <SummarySection size={size} className="grid">
                <UserImage>
                    <Image
                        src={
                            candidate.avatar ||
                            "https://seesyfiles.s3.eu-west-2.amazonaws.com/74484575-1b28-42cc-a9d6-e29187495a35-1655727601387..png"
                        }
                    />
                    {candidate.candidate.scanFilename && (
                        <Image src={candidate.candidate.scanFilename} />
                    )}
                </UserImage>
                <Stats className="grid">
                    {candidate.candidate.industries &&
                        candidate.candidate.industries.length > 0 && (
                            <Stat
                                key={`${candidate.id}_industries`}
                                size={size}
                            >
                                <div className="grid">
                                    <p>Industries</p>
                                </div>
                                <div className="grid row wrap">
                                    {(
                                        candidate.candidate
                                            .industries as string[]
                                    ).map((x) => (
                                        <Chip label={x} key={x}></Chip>
                                    ))}
                                </div>{" "}
                            </Stat>
                        )}
                    {candidate.candidate.skills &&
                        candidate.candidate.skills.length > 0 && (
                            <Stat key={`${candidate.id}_skills`} size={size}>
                                <div className="grid">
                                    <p>Skills</p>
                                </div>
                                <div className="grid row wrap">
                                    {(
                                        candidate.candidate.skills as string[]
                                    ).map((x, i) => (
                                        <Chip label={x} key={i}></Chip>
                                    ))}
                                </div>
                            </Stat>
                        )}
                    {candidate.candidate.preferredRoles &&
                        candidate.candidate.preferredRoles.length > 0 && (
                            <Stat
                                key={`${candidate.id}_preferredRoles`}
                                size={size}
                            >
                                <div className="grid">
                                    <p>Preferred Roles</p>
                                </div>
                                <div className="grid row wrap">
                                    {(
                                        candidate.candidate
                                            .preferredRoles as string[]
                                    ).map((x) => (
                                        <Chip label={x} key={x}></Chip>
                                    ))}
                                </div>
                            </Stat>
                        )}

                    <div className="grid row wrap auto">
                        {candidate.candidate.experience &&
                            candidate.candidate.experience.length > 0 && (
                                <Stat
                                    key={`${candidate.id}_experience`}
                                    size={size}
                                >
                                    <div className="grid">
                                        <p>Experience</p>
                                    </div>
                                    <p>{candidate.candidate.experience}</p>
                                </Stat>
                            )}
                        {candidate.candidate.salaryExpectation && (
                            <Stat
                                key={`${candidate.id}_salaryExpectations`}
                                size={size}
                            >
                                <div className="grid">
                                    <p>Salary Expectations</p>
                                </div>
                                <p>{candidate.candidate.salaryExpectation}</p>
                            </Stat>
                        )}
                        {candidate.candidate.educationLevel && (
                            <Stat
                                key={`${candidate.id}_educationLevel`}
                                size={size}
                            >
                                <div className="grid">
                                    <p>Education Level</p>
                                </div>
                                <p>
                                    {
                                        (
                                            candidate.candidate
                                                .educationLevel as EducationLevel
                                        ).name
                                    }{" "}
                                    (Level
                                    {" " +
                                        (
                                            candidate.candidate
                                                .educationLevel as EducationLevel
                                        ).level}
                                    )
                                </p>
                            </Stat>
                        )}

                        {candidate.candidate.age && (
                            <Stat key={`${candidate.id}_age`} size={size}>
                                <div className="grid">
                                    <p>Age (Actual)</p>
                                </div>
                                <p>
                                    {candidate.candidate.age >= 18 &&
                                    candidate.candidate.age <= 24
                                        ? "18-24"
                                        : candidate.candidate.age >= 25 &&
                                          candidate.candidate.age <= 29
                                        ? "25-29"
                                        : candidate.candidate.age >= 30 &&
                                          candidate.candidate.age <= 39
                                        ? "30-39"
                                        : candidate.candidate.age >= 40 &&
                                          candidate.candidate.age <= 49
                                        ? "40-49"
                                        : candidate.candidate.age >= 50 &&
                                          candidate.candidate.age <= 59
                                        ? "50-59"
                                        : candidate.candidate.age >= 60
                                        ? "60+"
                                        : "unknown"}
                                </p>
                            </Stat>
                        )}

                        {candidate.candidate.estimatedAge &&
                            !candidate.candidate.age && (
                                <Stat
                                    key={`${candidate.id}_estimatedAge`}
                                    size={size}
                                >
                                    <div className="grid">
                                        <p>Age (Estimated)</p>
                                        {candidate.candidate.estimatedAge
                                            ?.confidence !== 0 && (
                                            <ProgressIndicator
                                                progress={
                                                    candidate.candidate
                                                        .estimatedAge
                                                        ?.confidence * 100
                                                }
                                                isColored={true}
                                                showNumber={true}
                                            />
                                        )}
                                    </div>
                                    <p>
                                        {candidate.candidate.estimatedAge
                                            ?.val >= 18 &&
                                        candidate.candidate.estimatedAge?.val <=
                                            24
                                            ? "18-24"
                                            : candidate.candidate.estimatedAge
                                                  ?.val >= 25 &&
                                              candidate.candidate.estimatedAge
                                                  ?.val <= 29
                                            ? "25-29"
                                            : candidate.candidate.estimatedAge
                                                  ?.val >= 30 &&
                                              candidate.candidate.estimatedAge
                                                  ?.val <= 39
                                            ? "30-39"
                                            : candidate.candidate.estimatedAge
                                                  ?.val >= 40 &&
                                              candidate.candidate.estimatedAge
                                                  ?.val <= 49
                                            ? "40-49"
                                            : candidate.candidate.estimatedAge
                                                  ?.val >= 50 &&
                                              candidate.candidate.estimatedAge
                                                  ?.val <= 59
                                            ? "50-59"
                                            : candidate.candidate.estimatedAge
                                                  ?.val >= 60
                                            ? "60+"
                                            : "unknown"}
                                    </p>
                                </Stat>
                            )}

                        {candidate.candidate.ethnicity && (
                            <Stat key={`${candidate.id}_ethnicity`} size={size}>
                                <div className="grid">
                                    <p>Ethnicity (Actual)</p>
                                </div>
                                <p>{candidate.candidate.ethnicity}</p>
                            </Stat>
                        )}

                        {candidate.candidate.estimatedEthnicity &&
                            !candidate.candidate.ethnicity && (
                                <Stat
                                    key={`${candidate.id}_estimatedEthnicity`}
                                    size={size}
                                >
                                    <div className="grid">
                                        <p>Ethnicity (Estimated)</p>
                                        {candidate.candidate.estimatedEthnicity
                                            ?.confidence !== 0 && (
                                            <ProgressIndicator
                                                progress={
                                                    candidate.candidate
                                                        .estimatedEthnicity
                                                        ?.confidence * 100
                                                }
                                                isColored={true}
                                                showNumber={true}
                                            />
                                        )}
                                    </div>

                                    <p>
                                        {
                                            candidate.candidate
                                                .estimatedEthnicity?.val
                                        }
                                    </p>
                                </Stat>
                            )}

                        {candidate.candidate.gender && (
                            <Stat key={`${candidate.id}_gender`} size={size}>
                                <div className="grid">
                                    <p>Gender (Actual)</p>
                                </div>
                                <p>{candidate.candidate.gender}</p>
                            </Stat>
                        )}

                        {candidate.candidate.estimatedGender &&
                            !candidate.candidate.gender && (
                                <Stat
                                    key={`${candidate.id}_estimatedGender`}
                                    size={size}
                                >
                                    <div className="grid">
                                        <p>Gender (Estimated)</p>
                                        {candidate.candidate.estimatedGender
                                            ?.confidence !== 0 && (
                                            <ProgressIndicator
                                                progress={
                                                    candidate.candidate
                                                        .estimatedGender
                                                        ?.confidence * 100
                                                }
                                                isColored={true}
                                                showNumber={true}
                                            />
                                        )}
                                    </div>
                                    <p>
                                        {
                                            candidate.candidate.estimatedGender
                                                ?.val
                                        }
                                    </p>
                                </Stat>
                            )}

                        {/* {candidate.candidate.stats &&
              candidate.candidate.stats.map((stat) => (
                <Stat
                  key={`${candidate.id}_${stat.name}`}
                  size={size}
                  title={`${stat.name}: ${stat.value}`}
                >
                  <div className="grid">
                    <p>{stat.name}</p>
                  </div>
                  <p>{stat.value?.toString() as string}</p>
                </Stat>
              ))} */}
                    </div>
                </Stats>
            </SummarySection>
            <hr />
            <div className="grid">
                <h2>Description</h2>
                <p className="preformatted">
                    {candidate.candidate.description}
                </p>
            </div>
        </BodyWrapper>
    );
});

const SummarySection = styled.div<{ size: Size }>(({ size }) =>
    size.isMdUp
        ? `grid-template-columns: 400px 1fr;`
        : `grid-template-columns: 1fr;`
);
const UserImage = styled.div(
    () => `
  max-width: 400px;
  position: relative;  
  overflow: hidden;
  border-radius: var(--border-radius);
  aspect-ratio: 5/6;
  * {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transition: opacity 200ms ease-out;
    }
    &:hover {
      > :first-child {
        opacity: 0.25;
      }
    }
    `
);

const Image = styled.div<{ src: string }>(
    ({ src }) =>
        `width: 100%; height: 100%; background-size: cover; background-position: center center; background-repeat: no-repeat; background-image: url('${src}')`
);
const Stats = styled.div(
    () => `
    align-content: flex-start;
    `
);
const Stat = styled.div<{ size: Size }>(
    ({ size }) => `
  display: grid;
  text-transform: capitalize;
  align-items: center;
  ${
      size.isSmUp
          ? "grid-template-columns: 180px 1fr"
          : "grid-template-columns: 1fr"
  };
  background-color: var(--color-grey-50);
  border-radius: var(--border-radius);
  overflow: hidden;
  > :first-child {
    position: relative;
    font-weight: bold;
    background-color: var(--color-grey-200);
    height: 100%;
    align-items: center;
  }
  >* {
    padding: calc(var(--base-spacing) * 2);
  }
 
  `
);
