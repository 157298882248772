import { BarDatum } from "@nivo/bar";
import { Datum, DatumId } from "@nivo/bullet";
import { ReactNode } from "react";

/**
 * Viewer watch time data point
 */
export class ViewerViewTime implements BarDatum {
    viewerId: string;
    viewerName: string;
    watchTime: number;
    total: number;

    constructor(
        viewerId: string,
        viewerName: string,
        watchTime: number,
        total: number = 0
    ) {
        this.viewerId = viewerId;
        this.viewerName = viewerName;
        this.watchTime = watchTime;
        this.total = total;
    }
    [key: string]: string | number;
}
export class ViewerViewTimeTotal implements Datum {
    id: DatumId;
    title?: ReactNode;
    ranges: number[];
    measures: number[];
    markers?: number[] | undefined;

    constructor(
        id: DatumId,
        title: ReactNode,
        ranges: number[],
        measures: number[],
        markers?: number[] | undefined
    ) {
        this.id = id;
        this.title = title;
        this.ranges = ranges;
        this.measures = measures;
        this.markers = markers;
    }
}

export class StackedChartData implements BarDatum {
    key: string;
    [key: string]: string | number;

    constructor(key: string) {
        this.key = key;
    }
}

export class ApplicantCohortCount implements BarDatum {
    cohort: string;
    total: number;
    percentage: number;

    constructor(cohort: string, total: number, percentage: number) {
        this.cohort = cohort;
        this.total = total;
        this.percentage = percentage;
    }
    [key: string]: string | number;
}

export class DashboardViewer {
    viewerId: string;
    viewerName: string;
    viewerAvatar: string;
    age: ViewerViewTimeTotal[];
    gender: ViewerViewTimeTotal[];
    ethnicity: ViewerViewTimeTotal[];

    constructor(
        viewerId: string,
        viewerName: string,
        viewerAvatar: string,
        age: ViewerViewTimeTotal[],
        gender: ViewerViewTimeTotal[],
        ethnicity: ViewerViewTimeTotal[]
    ) {
        this.viewerId = viewerId;
        this.viewerName = viewerName;
        this.viewerAvatar = viewerAvatar;
        this.age = age;
        this.gender = gender;
        this.ethnicity = ethnicity;
    }
}

export class EthnicityViewTime {
    black: ViewerViewTime[];
    white: ViewerViewTime[];
    asian: ViewerViewTime[];
    hispanic: ViewerViewTime[];
    mideast: ViewerViewTime[];
    indian: ViewerViewTime[];
    other: ViewerViewTime[];
    unknown: ViewerViewTime[];

    constructor(
        black: ViewerViewTime[],
        white: ViewerViewTime[],
        asian: ViewerViewTime[],
        hispanic: ViewerViewTime[],
        mideast: ViewerViewTime[],
        indian: ViewerViewTime[],
        other: ViewerViewTime[],
        unknown: ViewerViewTime[]
    ) {
        this.black = black;
        this.white = white;
        this.asian = asian;
        this.hispanic = hispanic;
        this.mideast = mideast;
        this.indian = indian;
        this.other = other;
        this.unknown = unknown;
    }
}

export class GenderViewTime {
    male: ViewerViewTime[];
    female: ViewerViewTime[];
    unknown: ViewerViewTime[];

    constructor(
        male: ViewerViewTime[],
        female: ViewerViewTime[],
        unknown: ViewerViewTime[]
    ) {
        this.male = male;
        this.female = female;
        this.unknown = unknown;
    }
}

export class AgeViewTime {
    age18: ViewerViewTime[];
    age25: ViewerViewTime[];
    age30: ViewerViewTime[];
    age40: ViewerViewTime[];
    age50: ViewerViewTime[];
    age60: ViewerViewTime[];
    unknown: ViewerViewTime[];

    constructor(
        age18: ViewerViewTime[],
        age25: ViewerViewTime[],
        age30: ViewerViewTime[],
        age40: ViewerViewTime[],
        age50: ViewerViewTime[],
        age60: ViewerViewTime[],
        unknown: ViewerViewTime[]
    ) {
        this.age18 = age18;
        this.age25 = age25;
        this.age30 = age30;
        this.age40 = age40;
        this.age50 = age50;
        this.age60 = age60;
        this.unknown = unknown;
    }
}
