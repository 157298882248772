import { Dispatch } from "@reduxjs/toolkit";
import { IApiError, IApiResponse, ILoginResponse } from "../../models/api";
import { errorActions } from "../error/error-slice";
import { authActions } from "./auth-slice";
import { CLEAR_STORE_ACTION } from "../store";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const logInUser =
    (payload: { email: string; password: string }) =>
    async (dispatch: Dispatch) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        };

        try {
            dispatch(authActions.logInRequest());
            const response = await fetch(
                `${ROOT_URL}/user/login/email`,
                requestOptions
            );
            const data: ILoginResponse = await response.json();
            if (data.user) {
                dispatch(authActions.logInSuccess(data));
                localStorage.setItem("currentUser", JSON.stringify(data));
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when logging in";
            dispatch(
                authActions.logInError(
                    typeof error === "string" ? error : message
                )
            );
            dispatch(
                errorActions.add([
                    {
                        error: error,
                        message: message,
                    },
                ])
            );
        }
    };

export const logOutUser = () => async (dispatch: Dispatch) => {
    dispatch(CLEAR_STORE_ACTION);
    dispatch(authActions.logOut());
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
};

export const resetPassword =
    (payload: { email: string }) => async (dispatch: Dispatch) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };
        // For good measure
        logOutUser();

        try {
            // Perform a reset
            dispatch(authActions.resetPasswordRequest);
            const response = await fetch(
                `${ROOT_URL}/user/password/reset_request?email=${payload.email}`,
                requestOptions
            );
            const data: IApiResponse = await response.json();
            if (response.status === 200) {
                dispatch(authActions.resetPasswordSuccess);
            } else {
                throw new Error((data as IApiError).message);
            }
        } catch (error) {
            console.error(error);
            const message =
                typeof error === "string"
                    ? error
                    : "Something went wrong when resetting password";
            dispatch(authActions.resetPasswordError(message));
            dispatch(
                errorActions.add([
                    {
                        error,
                        message,
                    },
                ])
            );
        }
    };
