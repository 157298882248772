import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRemoteState } from "../remote-state";
import { mergeArrays } from "../utils";
import { IBasicUser } from "../../models/user";

interface ICompanyUserState extends IRemoteState {
    users: IBasicUser[];
    userLoads: { [key: string]: number };
    companyLoads: { [key: string]: number };
}

const initialState: ICompanyUserState = {
    users: [],
    userLoads: {},
    companyLoads: {},
    isLoading: false,
};

/**
 * CompanyUser store
 * - Redux Toolkit allows us to simply mutate state without something like `const newState = {...state, updatedProp: true}; return newState`
 */
export const companyUserSlice = createSlice({
    name: "companyUser",
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        error: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            state.errorTime = new Date().valueOf();
        },
        update: (state, action: PayloadAction<IBasicUser>) => {
            const targetIndex = state.users.findIndex(
                (x) => x.id === action.payload.id
            );
            if (targetIndex > -1) state.users[targetIndex] = action.payload;
            const companyUserLoads = { ...state.userLoads };
            companyUserLoads[action.payload.id] = new Date().valueOf();
            state.userLoads = companyUserLoads;
        },
        add: (state, action: PayloadAction<IBasicUser[]>) => {
            state.users = state.users
                ? [...mergeArrays(state.users, action.payload)]
                : action.payload;
            const companyUserLoads = { ...state.userLoads };
            action.payload.forEach((application) => {
                companyUserLoads[application.id] = new Date().valueOf();
            });
            state.userLoads = companyUserLoads;
        },
        remove: (state, action: PayloadAction<IBasicUser>) => {
            state.users.splice(
                state.users.findIndex((i) => i.id === action.payload.id),
                1
            );
        },
        setCompanyLoaded: (state, action: PayloadAction<string>) => {
            state.companyLoads[action.payload] = new Date().valueOf();
        },
    },
});

export const companyUserActions = companyUserSlice.actions;
export default companyUserSlice.reducer;
