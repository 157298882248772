//TODO remind myself how to do enums in TypeScript

export interface IStatusIndicator {
    label: string;
    cssClass: "green" | "grey" | "red" | "primary";
}

export class StatusIndicator implements IStatusIndicator {
    label: string;
    cssClass: "green" | "grey" | "red" | "primary";

    constructor(label: string, cssClass: "green" | "grey" | "red" | "primary") {
        this.label = label;
        this.cssClass = cssClass;
    }
}
