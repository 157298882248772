/**
 * Interface for MonthlyVideoStatsID from the Database
 * The DB saves date, company, and cohort in the _id key to maintain unique keys
 * We want this to be flattened out in the app for friendlier access
 */
export interface IDBMonthlyVideoStatsId {
    month: number;
    year: number;
    companyId: string;

    // Cohorts
    ethnicity: string;
    gender: string;
    age: string;
}

/** Interface that comes back from the server - we flatted out the ID in to props for local classes */
export interface IDBMonthlyVideoStats {
    _id: IDBMonthlyVideoStatsId;
    views: number;
    activeTime: number;
    playTime: number;
    blurTime: number;
    volumeTime: number;
    skippedSeconds: number;
    recordedDuration: number;
}

export interface IMonthlyVideoStats {
    // ID
    year: number;
    month: number;
    companyId: string;
    gender: string;
    age: string;
    ethnicity: string;

    // Stats
    views: number;
    activeTime: number;
    playTime: number;
    blurTime: number;
    volumeTime: number;
    skippedSeconds: number;
    recordedDuration: number;

    // String index access
    [key: string]: any;
}

export class MonthlyVideoStats implements IMonthlyVideoStats {
    year: number;
    month: number;
    companyId: string;
    gender: string;
    age: string;
    ethnicity: string;
    views: number;
    activeTime: number;
    playTime: number;
    blurTime: number;
    volumeTime: number;
    skippedSeconds: number;
    recordedDuration: number;

    constructor(
        year: number,
        month: number,
        companyId: string,
        age: string,
        gender: string,
        ethnicity: string,
        views: number,
        activeTime: number,
        playTime: number,
        blurTime: number,
        volumeTime: number,
        skippedSeconds: number,
        recordedDuration: number
    ) {
        this.year = year;
        this.month = month;
        this.companyId = companyId;
        this.age = age;
        this.gender = gender;
        this.ethnicity = ethnicity;
        this.views = views;
        this.activeTime = activeTime;
        this.playTime = playTime;
        this.blurTime = blurTime;
        this.volumeTime = volumeTime;
        this.skippedSeconds = skippedSeconds;
        this.recordedDuration = recordedDuration;
    }
}
