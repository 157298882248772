import styled from "styled-components";

/**
 * Blank page component
 * - 100% viewport div with a grey background and grid layout
 * - Designed to be used as a top-level container
 * - Background can be overridden by passing in `backgroundColor: string`
 */
export const BlankPage = styled.div<{ backgroundColor?: string }>(
    ({ backgroundColor }) => `
  ${backgroundColor ? "--color-background: " + backgroundColor + ";" : ""}
  display: grid;
  inset: 0;
  height: 100vh;
  height: 100dvh;
  place-items: center;
  background: var(--color-background);
  gap: calc(var(--base-spacing) * 4);
`
);
