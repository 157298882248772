export const ROOT_URL = process.env.REACT_APP_API_URL;

/**
 * Gets the user's JWT token from localStorage
 * @returns string - token from storage
 */
export const token = (): string => {
    const storage = localStorage.getItem("currentUser");
    let token;
    if (storage) {
        token = JSON.parse(storage).jwt;
    }

    return token;
};

/**
 * Request options for *most* API calls
 * Just helps not rewrite the same object who knows how many times.
 * We can easily extend with common headers across all API calls with this object
 * Doesn't currently support FormData but it doesn't need to right now - every request we're doing is a GET or POST with JSON or Query params
 */
export const requestOptions = ({
    method = "GET",
    withAuth = true,
}: {
    method?: string;
    withAuth?: boolean;
    body?: { [key: string]: string };
} = {}): RequestInit => {
    const headers: Headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (withAuth) {
        headers.append("authorization", `Bearer ${token()}`);
    }
    return {
        method,
        headers,
    };
};

/**
 * Merges `existing` and `mergeValues` arrays. If `mergeValues` exists in `existing`, updates the value, else creates a new entry.
 * @param existing Array - Array to be merged in to
 * @param mergeValues Array - Array to merge in to `existing`
 */
export const mergeArrays = (existing: any[], mergeValues: any[]) => {
    const merged = new Map(existing.map((e) => [e.id, e]));
    mergeValues.forEach((entry) => {
        merged.set(entry.id, entry);
    });
    return [...merged.values()];
};
