import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { StackedChartData } from "../../models/chart";
import { ChartResponsiveWrapper } from "./chart-responsive-wrapper";
import { ChartNoData } from "./chart-no-data";

type Props = {
    data: StackedChartData[];
};

/**
 * Stacked bar chart for count of application status vs cohort (vacancy overview)
 */
export const ChartStatusByCohort = React.memo<Props>(({ data }) => {
    const getColor = (d: any) => {
        switch (d.id) {
            case "incomplete":
                return "var(--color-chart-5)";
            case "applied":
                return "var(--color-primary-600)";
            case "shortlisted":
                return "var(--color-chart-4)";
            case "rejected":
                return "var(--color-chart-7)";
            case "in-review":
                return "var(--color-chart-3)";
            case "withdrawn":
                return "var(--color-grey-300)";
            default:
                return "var(--color-primary-400";
        }
    };

    return (
        <ChartResponsiveWrapper>
            {!data || (Array.isArray(data) && !data.length) ? (
                <ChartNoData />
            ) : (
                <ResponsiveBar
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -40,
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={(d) => getColor(d)}
                    data={data}
                    enableGridX={false}
                    enableLabel={false}
                    indexBy="key"
                    indexScale={{ type: "band", round: true }}
                    keys={[
                        "rejected",
                        "shortlisted",
                        "in-review",
                        "applied",
                        "incomplete",
                        "withdrawn",
                    ]
                        .sort()
                        .reverse()}
                    labelSkipHeight={12}
                    labelSkipWidth={12}
                    labelTextColor="var(--color-white)"
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "top-left",
                            direction: "column",
                            translateX: -120,
                            translateY: -25,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: "#999",
                            symbolSize: 12,
                            symbolShape: "circle",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemTextColor: "#000",
                                    },
                                },
                            ],
                        },
                    ]}
                    margin={{ top: 40, left: 120, bottom: 24 }}
                    tooltip={(bar) => {
                        return (
                            <div
                                style={{
                                    // Identical style to default tooltips
                                    background: "white",
                                    color: "inherit",
                                    fontSize: "inherit",
                                    borderRadius: "2px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                                    padding: "5px 9px",
                                }}
                            >
                                {bar.label}: {bar.value}
                            </div>
                        );
                    }}
                    valueFormat={(value) => value.toString()}
                    valueScale={{ type: "linear" }}
                />
            )}
        </ChartResponsiveWrapper>
    );
});
